// React
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { compose, withContext, withStateHandlers } from 'recompose';
// Child React components.
import { setHTMLTitle } from 'src/components/service-routes';
import {
  ViewerContext,
  ViewerRoleContext,
  ViewerRoleProvider,
  Route,
  Routes,
  Navigate,
  NotFound,
} from '@fjedi/react-router-helpers';
// Main layout and navigation
import AdminLayout from 'src/components/ui-kit/admin-layout';
// Admin routes
import AdminPage from 'src/components/routes/private/admin';
import LocationsPage from 'src/components/routes/private/locations';
// User routes
import ProfilePage from 'src/components/routes/private/profile';
//
import { withTranslation } from 'react-i18next';
import { getRole } from 'src/functions';

const PrivateRoot = () => {
  const viewer = useContext(ViewerContext);
  const viewerRole = useContext(ViewerRoleContext);
  const { role } = getRole({ viewer, viewerRole });

  return (
    <ViewerRoleProvider value={role}>
      <AdminLayout>
        {setHTMLTitle('Главная')}
        <Routes>
          <Route
            areaType="route"
            allowedRoles={['USER', 'ADMIN', 'MODERATOR']}
            path="locations/*"
            element={<LocationsPage />}
            redirectTo="/login"
          />
          <Route
            areaType="route"
            allowedRoles={['USER', 'ADMIN', 'MODERATOR']}
            path="admin/*"
            element={<AdminPage />}
            redirectTo="/login"
          />
          <Route
            areaType="route"
            allowedRoles={['USER', 'ADMIN', 'MODERATOR']}
            path="profile/*"
            element={<ProfilePage />}
            redirectTo="/login"
          />

          <Route path="/" element={<Navigate replace to="locations" />} />
          <Route element={<NotFound />} />
        </Routes>
      </AdminLayout>
    </ViewerRoleProvider>
  );
};

export default compose(
  withTranslation(),
  withStateHandlers(
    () => ({
      currentPageBreadcrumb: null,
    }),
    {
      setCurrentPageBreadcrumb: state => currentPageBreadcrumb => ({
        ...state,
        currentPageBreadcrumb,
      }),
    },
  ),
  withContext(
    {
      breadcrumbNameMap: PropTypes.object.isRequired,
      setCurrentPageBreadcrumb: PropTypes.func.isRequired,
      currentPageBreadcrumb: PropTypes.object,
    },
    ({ t, setCurrentPageBreadcrumb, currentPageBreadcrumb }) => ({
      setCurrentPageBreadcrumb,
      currentPageBreadcrumb,
      breadcrumbNameMap: new Map([
        ['/', t('Main page')],
        ['/profile', t('Profile')],
      ]),
    }),
  ),
  // pure,
)(PrivateRoot);
