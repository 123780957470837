import React, { memo, useCallback, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CompaniesQueryVariables, Company, useCompaniesQuery } from 'src/graphql/generated';
import Select, { SelectOption } from 'src/components/ui-kit/select';

export type CompanySelectorProps = {
  value?: string;
  className?: string;
  style?: { [k: string]: string | number };
  disabled?: boolean;
  queryVariables?: CompaniesQueryVariables;
  onChange?: (_v: string, _d: Company) => void;
  hasRoleInPrimaryCompany?: boolean;
  defaultValue?: string;
};

const StyledSelect = styled(Select)`
  &.ant-select > .ant-select-selector {
    border-radius: 0.625rem;
    //padding-left: 0.375rem;
  }
`;

const CompanySelector: React.FC<CompanySelectorProps> = props => {
  const { t } = useTranslation();
  const {
    // Get value provided by parent component (if any)
    value,
    // Handle default react input props to be able to disable selectbox and/or set custom styles
    style,
    className,
    disabled,
    // Allow to pass custom query-vars for filtering or pagination of the data returned from API
    queryVariables,
    hasRoleInPrimaryCompany,
    onChange: parentOnChange,
    defaultValue,
  } = props;
  const { data: queryResult } = useCompaniesQuery({ variables: queryVariables, fetchPolicy: 'cache-and-network' });
  const companies = useMemo(() => queryResult?.companies?.rows ?? [], [queryResult?.companies?.rows]);

  const [selectedCompany, setCompanyId] = useState(hasRoleInPrimaryCompany ? defaultValue : value);
  // if 'value' provided by parent component has been changed
  // we should update value stored inside inner state of the CompanySelector
  useEffect(() => {
    if (value !== selectedCompany) {
      setCompanyId(value);
    }
  }, [hasRoleInPrimaryCompany, selectedCompany, value]);

  // Handle 'onChange' event and bypass it to 'onChange' handler from props (if any)
  // if not parent 'onChange' handler provided, just save new value to the inner state of the component
  const onChange = useCallback(
    v => {
      if (typeof parentOnChange === 'function') {
        const device = companies.find(d => d.id === v) as Company;
        parentOnChange(v, device);
      } else {
        setCompanyId(v);
      }
    },
    [parentOnChange, companies],
  );

  const filterOption = useCallback(
    (input: string, option) => (option!.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0,
    [],
  );

  return (
    <StyledSelect
      dropdownStyle={{ borderRadius: '0.625rem' }}
      disabled={disabled}
      style={style}
      className={className}
      value={hasRoleInPrimaryCompany ? defaultValue : selectedCompany}
      onChange={onChange}
      placeholder={t('Select a company')}
      showSearch
      filterOption={filterOption}>
      {companies.map(({ id, name }) => (
        <SelectOption key={id} value={id}>
          {name!}
        </SelectOption>
      ))}
    </StyledSelect>
  );
};

CompanySelector.displayName = 'CompanySelector';

export default memo(CompanySelector);
