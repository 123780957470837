/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export const DefaultDateFormat = 'YYYY-MM-DDTHH:mm:ss' as const;

enum TrackingMethod {
  Route = 'ROUTE',
  LivePoint = 'LIVE_POINT',
  LiveRoute = 'LIVE_ROUTE',
}

enum DatePickerFormats {
  Range = 'range',
  Start = 'start',
  None = 'none',
}

enum LocationUncertainty {
  Level1 = 1, // * '0000': 1
  Level2 = 2, // * '0001': 2
  Level4 = 3, // * '0010': 4
  Level10 = 10, // * '0011': 10
  Level20 = 22, // * '0100': 22
  Level50 = 50, // * '0101': 50
  Level100 = 103, // * '0110': 103
  Level220 = 224, // * '0111': 224
  Level490 = 484, // * 1000: 484
  Level1000 = 1049, // * 1001: 1049
  Level2300 = 2272, // * 1010: 2272
  Level5000 = 4921, // * 1011: 4921
  Level11000 = 10658, // * 1100: 10658
  Level23000 = 23085, // * 1101: 23085
  Level50000 = 50000, // * 1110: 50000
}

export { TrackingMethod, DatePickerFormats, LocationUncertainty };
