import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import type { CSSProperties } from 'styled-components';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import capitalize from 'lodash/capitalize';
import { pascalCase } from 'src/functions';

import Select, { SelectOption } from 'src/components/ui-kit/select';
import { UserRoleRole } from 'src/graphql/generated';

export const globalRoles: UserRoleRole[] = [UserRoleRole.User, UserRoleRole.Operator, UserRoleRole.Admin];
export const localRoles: Array<UserRoleRole.Admin | UserRoleRole.User> = [UserRoleRole.User, UserRoleRole.Admin];

export interface RoleSelectorProps {
  value?: UserRoleRole;
  className?: string;
  globalRole?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  onChange?(_v: UserRoleRole): void;
}

const StyledSelect = styled(Select)`
  &.ant-select > .ant-select-selector {
    border-radius: 0.625rem;
    //padding-left: 0.5rem;
    min-width: 160px;
  }
`;

export const RoleSelector: FC<RoleSelectorProps> = props => {
  const { t } = useTranslation();
  const { value, style, className, disabled, globalRole = false, onChange: parentOnChange } = props;
  const [selectedRole, setRole] = useState(capitalize(value));

  useEffect(() => {
    if (value !== selectedRole) {
      setRole(value ?? '');
    }
  }, [selectedRole, value]);

  const onChange = useCallback(
    v => {
      if (typeof parentOnChange === 'function') {
        parentOnChange(v);
      } else {
        setRole(v);
      }
    },
    [parentOnChange],
  );
  const roles = useMemo(() => (globalRole ? globalRoles : localRoles), [globalRole]);

  return (
    <StyledSelect
      dropdownStyle={{ borderRadius: '0.625rem' }}
      disabled={disabled}
      style={style}
      className={className}
      value={selectedRole}
      onChange={onChange}
      placeholder={t('Select a role')}>
      {roles.map(role => {
        return (
          <SelectOption value={role} key={role}>
            {t(pascalCase(role))}
          </SelectOption>
        );
      })}
    </StyledSelect>
  );
};

RoleSelector.displayName = 'RoleSelector';

export default memo(RoleSelector) as typeof RoleSelector;
