/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@fjedi/react-router-helpers';
import getCompaniesQuery from 'src/graphql/queries/get-companies.graphql';
import { formatDate } from 'src/helpers/time';
import { SearchOutlined } from 'src/components/ui-kit/icons';
import Table from 'src/components/ui-kit/table';
import FilterDropdown from 'src/components/ui-kit/table/filter-dropdown';

const OrganizationsTable: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onOrganizationRowClick = useCallback(
    ({ id }: { id: string }) => ({
      onClick: () => {
        navigate(`/admin/organizations/${id}`);
      },
    }),
    [navigate],
  );

  return (
    <Table
      pagination
      pageSize={10}
      query={getCompaniesQuery}
      dataType="Company"
      rowKey="id"
      onRow={onOrganizationRowClick}
      columns={[
        {
          title: t('Name'),
          dataIndex: 'name',
          // eslint-disable-next-line react/no-unstable-nested-components
          filterDropdown: (
            props: JSX.IntrinsicAttributes &
              JSX.IntrinsicClassAttributes<React.Component<any, any, any>> &
              Readonly<any> &
              Readonly<{ children?: React.ReactNode }>,
          ) => <FilterDropdown {...props} />,
          // eslint-disable-next-line react/no-unstable-nested-components
          filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
          sorterKey: 'name',
          sorter: (a: number, b: number) => a - b,
          render(name: string) {
            return name || 'Не указано';
          },
        },
        {
          title: t('Company ID'),
          dataIndex: 'remoteId',
          sorterKey: 'createdAt',
          sorter: (a: number, b: number) => a - b,
          render(companyId: string) {
            return companyId ?? '';
          },
        },
        {
          title: t('Creation Date'),
          dataIndex: 'createdAt',
          sorterKey: 'createdAt',
          sorter: (a: number, b: number) => a - b,
          render(createdAt: Date) {
            return formatDate(createdAt, 'DD.MM.YYYY HH:mm');
          },
        },
      ]}
    />
  );
};

OrganizationsTable.displayName = 'OrganizationsTable';

export default memo(OrganizationsTable);
