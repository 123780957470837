import React, { memo, FC, useMemo, useCallback } from 'react';
import { useNavigate } from '@fjedi/react-router-helpers';
import pick from 'lodash/pick';
import Button from 'src/components/ui-kit/buttons';
import type { ButtonProps } from 'antd/lib/button';

export type LinkButtonProps = ButtonProps & {
  to: string;
};

const LinkButton: FC<LinkButtonProps> = ({ children, to, htmlType, ...props }) => {
  const navigate = useNavigate();
  const onClick = useCallback(() => navigate(to), [navigate, to]);
  const p = useMemo(
    () =>
      pick(props, [
        'type',
        'size',
        'icon',
        'ghost',
        'shape',
        'htmlType',
        'block',
        'className',
        'style',
        'onFocus',
        'onMouseEnter',
        'onMouseLeave',
      ]),
    [props],
  );
  return (
    <Button onClick={onClick} htmlType={htmlType ?? 'button'} {...p} type="primary">
      {children}
    </Button>
  );
};

LinkButton.displayName = 'LinkButton';

export default memo(LinkButton);
