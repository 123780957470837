/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable security/detect-object-injection */
import { AuthModalContext, Link, ViewerContext } from '@fjedi/react-router-helpers';
import { Button, ButtonProps } from 'antd';
import React, { FC, memo, useCallback, useContext } from 'react';
import { colorGrays, colorTheme } from 'src/components/ui-kit/theme';
import { colorContrast, darkenColor, setOpacity } from 'src/components/ui-kit/utilities';
import logger from 'src/helpers/logger';
import styled from 'styled-components';

Object.assign(colorTheme, { text: colorGrays.gray600 });

export const ButtonGroup = styled(Button.Group)``;

type ButtonState = 'hover';

function getBackgroundColor({ type = 'primary' }, state?: ButtonState) {
  const color = type === 'text' ? '' : colorTheme[type];
  if (state) {
    return color ? darkenColor(color) : 'transparent';
  }
  return color || 'transparent';
}

function getBorder({ type = 'primary' }: ButtonProps, state?: ButtonState) {
  const color = type === 'text' ? false : colorTheme[type];
  if (state) {
    return color ? darkenColor(color, 10) : 'transparent';
  }
  return color || 'transparent';
}

function getBoxShadow({ type = 'primary', ghost }: ButtonProps, state?: ButtonState) {
  if (ghost) {
    return 'none';
  }
  const color = type === 'text' ? '' : colorTheme[type];
  const boxShadowSize = '0 2px 6px 0';
  if (state) {
    return `${boxShadowSize} ${darkenColor(color, 10)}`;
  }
  return `${boxShadowSize} ${!color || setOpacity(color)}`;
}

function getTextColor({ type = 'primary', ghost }: ButtonProps, state?: ButtonState) {
  const color = colorTheme[type];
  if (ghost) {
    if (state) {
      return type === 'text' || type === 'link' ? color : colorContrast(darkenColor(color));
    }
    return color || colorTheme.primary;
  }
  if (state) {
    return type === 'text' || type === 'link' ? color : colorContrast(darkenColor(color));
  }
  if (type === 'text') {
    return 'rgba(0, 0, 0, 0.4)';
  }
  return color ? colorContrast(color) : colorTheme.primary;
}

function getGhostColor({ type = 'primary' }: ButtonProps, text?: boolean) {
  const color = colorTheme[type];
  if (text) {
    return colorContrast(color);
  }
  return color;
}

const StyledButton = styled(Button)`
  &,
  &.ant-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    user-select: none;
    /* padding: 0.45rem 0.9rem; */
    font-weight: 600;
    line-height: 1.5;
    /* height: 2.25rem; */
    border-radius: 0.375rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    white-space: nowrap;
    touch-action: manipulation;
    color: ${props => getTextColor(props)};
    border-color: ${props => getBorder(props)};
    box-shadow: ${props => getBoxShadow(props)};
    outline: 0;
    background-color: ${props => getBackgroundColor(props)};
    font-family: ${props => props.theme.baseFontFamily};
    position: relative;

    > .anticon {
      font-size: 1em;
      display: inline-block;

      & + span {
        margin-left: 0.5rem;
      }
    }

    > span {
      display: inline-block;
      pointer-events: none;
      font-smooth: antialiased;
      -webkit-font-smoothing: antialiased;
    }

    > svg {
      font-size: 1.5rem;
      + span {
        margin-left: 0.5rem;
      }
    }

    &.ant-btn-icon-only {
      padding: 0;
    }

    &:hover,
    &:focus {
      color: ${props => getTextColor(props, 'hover')};
      border-color: ${props => getBorder(props, 'hover')};
      background-color: ${props => getBackgroundColor(props, 'hover')};
    }
    &-disabled,
    &.disabled,
    &[disabled],
    &-disabled:hover,
    &.disabled:hover,
    &[disabled]:hover,
    &-disabled:focus,
    &.disabled:focus,
    &[disabled]:focus,
    &-disabled:active,
    &.disabled:active,
    &[disabled]:active,
    &-disabled.active,
    &.disabled.active,
    &[disabled].active {
      color: ${props => getTextColor(props)};
      background-color: ${props => getBackgroundColor(props)};
      border: ${props => getBorder(props)};
      box-shadow: 0 2px 6px 0 ${props => getBoxShadow(props)};
      opacity: 0.65;
    }

    &-background-ghost.ant-btn-${props => props.type} {
      &:hover,
      &:focus {
        color: ${props => getGhostColor(props, true)};
        border-color: ${props => getGhostColor(props)};
        background-color: ${props => getGhostColor(props)} !important;
      }
    }

    &-background-ghost.ant-btn-text {
      &:hover {
        background-color: transparent !important;
      }
    }

    &-sm {
      height: 2rem;
      font-size: 0.9rem;
      font-weight: 400;
    }

    &-lg {
      /* height: 3.125rem; */
      /* font-size: 1.125rem; */
      font-weight: 400;
    }

    &-icon-only {
      width: 38px;
    }
  }

  &.ant-btn-link {
    &:hover,
    &focus {
      opacity: 0.85;
    }
  }

  &.ant-btn-dangerous {
    color: #e64646;
    &:hover,
    &focus {
      color: #e64646;
    }
  }

  &.ant-btn-round {
    border-radius: 38px;
  }

  &.ant-btn-circle {
    border-radius: 50%;
    padding: 0;
  }
`;

const Index: FC<ButtonProps & { authRequired?: boolean }> = ({ authRequired, onClick: parentOnClick, ...props }) => {
  const viewer = useContext(ViewerContext);
  const authModalContext = useContext(AuthModalContext);
  if (!authModalContext) {
    logger('No authModalContext found inside main Button component, it seems that smth went wrong... :(', props);
  }
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      //
      if (authRequired) {
        const role = viewer?.role || 'ANONYMOUS';
        if (role === 'ANONYMOUS' && typeof authModalContext?.setStatus === 'function') {
          authModalContext?.setStatus(true);
          return;
        }
      }
      //
      if (typeof parentOnClick === 'function') {
        parentOnClick(event);
      }
    },
    [parentOnClick, authRequired, authModalContext, viewer?.role],
  );
  const { children, icon, href, target, block, htmlType, ...buttonProps } = props;
  if (href) {
    return (
      <StyledButton as={Link} to={href} onClick={onClick} {...buttonProps}>
        {children}
      </StyledButton>
    );
  }
  //
  return (
    <StyledButton icon={icon} block={block} htmlType={htmlType} onClick={onClick} {...buttonProps}>
      {children}
    </StyledButton>
  );
};

Index.defaultProps = { authRequired: false };

export default memo(Index);
