import React, { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Tooltip from 'src/components/ui-kit/tooltip';
import { HelpIcon } from 'src/components/ui-kit/icons';
import { colorTheme } from 'src/components/ui-kit/theme';

interface HelpIndicatorProps {
  text: string | string[];
}

const TooltipTitle = styled.p.attrs({ className: 'help-indicator-tooltip-title' })`
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
`;

const HelpIndicator: FC<HelpIndicatorProps> = ({ text }) => {
  const { t } = useTranslation();

  const renderTooltipTitle = useCallback(() => {
    let title;
    if (typeof text === 'string') {
      title = text.startsWith('\\') ? text : t(text);
    }
    if (typeof text === 'object') {
      title = text.reduce((paragraphA, paragraphB) => `${paragraphA}\r\n${t(paragraphB)}`);
    }

    return <TooltipTitle>{title ?? text}</TooltipTitle>;
  }, [t, text]);

  return (
    <Tooltip title={renderTooltipTitle} className="help-indicator" align={{ offset: [0, 2.5] }}>
      <HelpIcon color={colorTheme.primary} style={{ cursor: 'help' }} />
    </Tooltip>
  );
};

export default memo(HelpIndicator);
