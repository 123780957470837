import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ViewerContext } from '@fjedi/react-router-helpers';

import { Divider } from 'antd';

import { FormItem } from 'src/components/ui-kit/form';
import { TextArea } from 'src/components/ui-kit/input';

const ProfileDivider = styled(Divider)`
  margin-top: 1.2rem;
  margin-bottom: 0.75rem;
`;

const OrganizationProfileAdditional = () => {
  const { t } = useTranslation();

  const viewer = useContext(ViewerContext);
  const viewerRole = viewer?.role || 'ANONYMOUS';

  const isAdmin = ['ADMIN'].includes(viewerRole);

  return (
    <FormItem label={t('Comments')} name="comments">
      <TextArea disabled={!isAdmin} />
    </FormItem>
  );
};

OrganizationProfileAdditional.propTypes = {};
OrganizationProfileAdditional.defaultProps = {};
OrganizationProfileAdditional.displayName = 'OrganizationProfileAdditional';

export default memo(OrganizationProfileAdditional);
