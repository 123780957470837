import React, { memo, useState, useMemo, useCallback, FC } from 'react';
import { useTranslation } from 'react-i18next';
//
import AuthBox from 'src/components/ui-kit/auth-box';
//
import viewerQuery from 'src/graphql/queries/viewer.graphql';
import type { Viewer } from 'src/graphql/generated';
//
import Spinner from 'src/components/ui-kit/spinner';
import FirstFactor, { OnAuthSuccessProps, LoginData } from './first-factor';
import SecondFactor, { VerifyTotpData } from './second-factor';
//
const LoginPage: FC = () => {
  const { t } = useTranslation();
  const [factor, setFactor] = useState(1);
  const [user, setUser] = useState<Viewer | null>();

  const title = useMemo(() => {
    switch (factor) {
      case 2:
        return t('Two-factor authentication');
      case 1:
      default:
        return t('Login');
    }
  }, [factor, t]);

  const handleBackBtnClick = useCallback(() => {
    setFactor(1);
  }, []);

  const onFirstFactorSuccess = useCallback((opts: OnAuthSuccessProps<LoginData>) => {
    const {
      cache,
      data: {
        login: { user: userData },
      },
    } = opts;

    setUser(userData);

    if (!!userData && userData.gaEnabled) {
      setFactor(2);
    }

    if (!!userData && !userData.gaEnabled) {
      cache.writeQuery({
        query: viewerQuery,
        data: { viewer: userData },
      });
    }
  }, []);

  const onSecondFactorSuccess = useCallback(
    (opts: OnAuthSuccessProps<VerifyTotpData>) => {
      const {
        cache,
        data: { verifyTotp },
      } = opts;

      if (verifyTotp) {
        cache.writeQuery({
          query: viewerQuery,
          data: { viewer: user },
        });
      }
    },
    [user],
  );

  const content = useMemo(() => {
    const userId = user?.id;

    switch (factor) {
      case 2:
        if (!userId) {
          return (
            <Spinner
              style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              spinning
            />
          );
        }

        return <SecondFactor onSuccess={onSecondFactorSuccess} onBackBtnClick={handleBackBtnClick} viewerId={userId} />;
      case 1:
      default:
        return <FirstFactor onSuccess={onFirstFactorSuccess} />;
    }
  }, [factor, handleBackBtnClick, onFirstFactorSuccess, onSecondFactorSuccess, user]);

  return <AuthBox title={title}>{content}</AuthBox>;
};

LoginPage.displayName = 'LoginPage';

export default memo(LoginPage) as typeof LoginPage;
