import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Button, Empty } from 'antd';
import { useRemoveUserMutation, useUserQuery } from 'src/graphql/generated';
import { compareIds, updateAfterMutation, useApolloError } from '@fjedi/graphql-react-components';
import { RestrictedArea, useNavigate, useParams } from '@fjedi/react-router-helpers';
import DetailsDrawer from 'src/components/common/details-drawer';
import Spinner from 'src/components/ui-kit/spinner';
import LinkButton from 'src/components/ui-kit/buttons/link-button';
import UserProfileCard from 'src/components/routes/private/admin/users/profile';
import UsersTable from 'src/components/routes/private/admin/users/users-table';
import Popconfirm from 'src/components/ui-kit/popconfirm';

const UsersWrapper = styled.div``;

const CreateUserButton = styled(LinkButton).attrs({ size: 'large', className: 'create-button' })`
  &.ant-btn {
    margin-bottom: 1.2rem;
    border-radius: 0.5rem;
    font-size: 1rem;
    padding: 0 1rem;
  }
`;

const UsersContainer: FC = () => {
  const { t } = useTranslation();
  const pageTitle = useMemo(() => t('Users List'), [t]);
  const params = useParams<{ userId: string }>();
  const navigate = useNavigate();

  const queryRes = useUserQuery({
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !params.userId || params.userId === 'new',
    variables: {
      id: params.userId!,
    },
  });
  const { data, loading } = queryRes;
  const user = data?.user;
  const userId = user?.id;

  const onProfileCardClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onError = useApolloError();
  const [removeUser, { loading: removing }] = useRemoveUserMutation({
    variables: {
      id: userId!,
    },
    onError,
    update: updateAfterMutation('User', 'users'),
    onCompleted() {
      navigate(-1);
    },
  });

  return (
    <UsersWrapper>
      <Helmet>
        <title>{t(pageTitle)}</title>
      </Helmet>
      <RestrictedArea areaType="block" allowedRoles={['ADMIN']}>
        <CreateUserButton type="primary" to="/admin/users/new">
          {t('Create Users')}
        </CreateUserButton>
      </RestrictedArea>
      <UsersTable />
      <DetailsDrawer
        title="Регистрация нового пользователя"
        width={570}
        onClose={onProfileCardClose}
        open={params.userId === 'new'}>
        <UserProfileCard />
      </DetailsDrawer>
      <DetailsDrawer
        title={user?.fullName ? `Профиль пользователя: ${user?.fullName}` : null}
        width={570}
        onClose={onProfileCardClose}
        open={!!(userId && compareIds(userId, params.userId))}
        extra={
          <RestrictedArea areaType="block" allowedRoles={['ADMIN']}>
            <Popconfirm placement="bottomLeft" title={`${t('Remove')}?`} onConfirm={removeUser}>
              <Button loading={removing} danger style={{ borderRadius: '0.5rem' }}>
                {t('Remove')}
              </Button>
            </Popconfirm>
          </RestrictedArea>
        }>
        <Spinner spinning={loading}>
          {!loading && !userId && <Empty description="Не удалось получить данные о клиенте" />}
          {!loading && userId && <UserProfileCard userId={userId} data={user} />}
        </Spinner>
      </DetailsDrawer>
    </UsersWrapper>
  );
};

UsersContainer.displayName = 'UsersContainer';

export default memo(UsersContainer);
