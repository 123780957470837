import React, { FC, memo, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Route, Routes, Navigate, NotFound } from '@fjedi/react-router-helpers';

import ContentCard from 'src/components/ui-kit/card/main-content-card';

import MapTab from './map';
import AliasesTab from './aliases';

const Container = styled(ContentCard as FC<Parameters<typeof ContentCard>[0]>).attrs({ className: 'locations-card' })`
  &.ant-card {
    max-height: unset;
  }
`;

export const LocationsPage: FC = () => {
  const { t } = useTranslation();
  const tabList = useMemo(
    () => [
      {
        key: 'map',
        tab: t('Map'),
      },
      {
        key: 'aliases',
        tab: t('Aliases'),
      },
    ],
    [t],
  );

  return (
    <Container tabList={tabList} tabURLNavigation>
      <Routes>
        <Route path="map" element={<MapTab />} />
        <Route path="aliases" element={<AliasesTab />} />
        <Route path="/" element={<Navigate replace to="map" />} />
        <Route element={<NotFound />} />
      </Routes>
    </Container>
  );
};

export default memo(LocationsPage) as typeof LocationsPage;
