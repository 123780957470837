import React from 'react';
import PropTypes from 'prop-types';
// CSS
import styled from 'styled-components';
//
import { Row as AntRow, RowProps, Col as AntCol, ColProps } from 'antd';

export const Row = styled(AntRow)``;

Row.displayName = 'Row';

export const Col = styled(AntCol)``;

Col.displayName = 'Col';
