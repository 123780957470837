import React from 'react';
import { pure, compose, withProps, withHandlers } from 'recompose';
import styled from 'styled-components';
import get from 'lodash/get';
import time from 'src/helpers/time';
import { Button, Form as AntForm } from 'antd';
import TableFileUploader from 'src/components/ui-kit/file-uploader/extended';
import ImageModalPopup from 'src/components/ui-kit/modal-popup/image';
import { withTranslation } from 'react-i18next';

export const parseFormData = (form, formData, onlyChanged = false) => {
  const input = {};
  //
  Object.keys(formData).forEach(field => {
    if (onlyChanged && !form.isFieldTouched(field)) {
      return;
    }
    // eslint-disable-next-line security/detect-object-injection
    const value = formData[field];
    if (field === 'location') {
      const { latitude, longitude } = get(value, 'value', {});
      input.coordinates = [latitude, longitude];
    } else if (time.isDayjs(value)) {
      input.startTime = value.toISOString();
    } else {
      // eslint-disable-next-line security/detect-object-injection
      input[field] = value;
    }
  });

  return input;
};

export const Form = styled(AntForm)`
  &.ant-form-vertical .ant-form-item-label {
    padding-bottom: 0;
  }

  &.ant-form-vertical .ant-form-item {
    margin-bottom: 12px;
  }
`;
export const FormItem = styled(AntForm.Item)`
  &.ant-form-item {
    //margin-bottom: 0.5rem;

    // &:first-of-type {
    //   margin-top: 1rem;
    // }

    // &:last-of-type {
    //   margin-top: 1.5rem;
    // }

    .ant-form-item-label {
      //line-height: 1.5rem;
    }
  }
`;

const MediaFileContainer = styled.div`
  position: relative;

  > div {
    &:first-of-type {
      text-align: left;
      flex-grow: 1;
      max-height: 200px;

      > img {
        display: inline-block;
        max-height: 200px;
        object-fit: contain;
      }
    }
  }

  > .ant-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.25rem;
  }
`;

export const ImageFormItem = compose(
  withTranslation(),
  withHandlers({
    onChange:
      ({ onChange }) =>
      value => {
        if (typeof onChange === 'function') {
          onChange(value);
        }
      },
  }),
  withHandlers({
    resetValue:
      ({ onChange }) =>
      () => {
        if (typeof onChange === 'function') {
          onChange(null);
        }
      },
  }),
  withProps(({ value }) => ({
    url: value && value.name ? URL.createObjectURL(value) : value,
  })),
  pure,
)(({ t, url, value, onChange, resetValue, style, className }) => (
  <MediaFileContainer {...{ style, className }}>
    {!!value && <Button icon="close" shape="circle" type="danger" onClick={resetValue} />}
    {!value && (
      <TableFileUploader onChange={onChange}>
        <Button type="primary">{t('Select')}</Button>
      </TableFileUploader>
    )}
    <ImageModalPopup src={url} />
  </MediaFileContainer>
));
