// React
import React from 'react';
import Helmet from 'react-helmet';
import { pure } from 'recompose';

const HelmetHeadComponent = ({ children, ...props }) => (
  <Helmet {...props}>
    <link
      href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap"
      rel="stylesheet"
    />
    {children}
  </Helmet>
);

export default pure(HelmetHeadComponent);
