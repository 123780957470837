import { theme as themeBuilder } from 'antd';

const { defaultAlgorithm, defaultSeed, useToken } = themeBuilder;
export const theme = {
  token: {
    colorPrimary: '#1d3893',
    colorLink: '#1d3893',
    colorInfo: '#3498DB',
    colorSuccess: '#66bbff',
    colorProcessing: '#3498DB',
    colorError: '#E74C3C',
    colorHighlight: '#E74C3C',
    colorNormal: '#d9d9d9',
    colorText: '#555',
    borderRadius: 0,
    boxShadow: 'none',
    boxShadowSecondary: 'none',
    boxShadowTertiary: 'none',
    // borderRadius: 0,
    fontFamily: 'Arial, sans-serif',
  },
  // authBoxBackground: 'linear-gradient(to bottom, #606c88 0%, #3f4c6b 100%)',
  authBoxBackground: '#f8f8f8',
  authBoxHeaderHeight: '80px',
  maxContentWidth: '1270px',
  headerHeight: '10rem',
  baseFontFamily: 'Nunito, sans-serif',
  //
  responsiveBreakpoints: {
    mobile: '420px',
    tablet: '980px',
    desktop: '1400px',
  },
};

export const mapToken = defaultAlgorithm(defaultSeed);

export function useTheme() {
  const { token } = useToken();
  return token;
}
