import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
//
import { useApolloError } from '@fjedi/graphql-react-components';
import { useVerifyTotpMutation, Viewer } from 'src/graphql/generated';
//
import { Form, FormItem } from 'src/components/ui-kit/form';
import { Input, InputPrefixLabel } from 'src/components/ui-kit/input';
import { LockOutlined } from 'src/components/ui-kit/icons';
import Button from 'src/components/ui-kit/buttons';
import { colorTheme } from 'src/components/ui-kit/theme';
import type { AuthComponentProps } from 'src/components/routes/auth/login/first-factor';
//
import logger from 'src/helpers/logger';
//
const BackButton = styled(Button)`
  &.ant-btn.ant-btn-lg.ant-btn-ghost.ant-btn-block {
    font-weight: 300;
    width: 100%;
    border: 0.5px solid ${colorTheme.secondary};
    color: ${colorTheme.primary};
    padding: 0.4rem 0;

    &:hover {
      border-color: ${colorTheme.primary};
    }
  }
`;
//
export type VerifyTotpData = {
  verifyTotp: Viewer;
};
interface SecondFactorProps extends AuthComponentProps<VerifyTotpData> {
  onBackBtnClick(_event: Event): void;
  viewerId: string;
}
type SecondFactorFormFields = {
  code: string;
  readonly userId: string;
};
//
export const SecondFactor: FC<SecondFactorProps> = props => {
  const { onSuccess, onBackBtnClick, viewerId } = props;
  const { t } = useTranslation();

  const [secondFactorForm] = Form.useForm<SecondFactorFormFields>();

  const secondFactorFormInitialValues: SecondFactorFormFields = useMemo(
    () => ({
      code: '',
      userId: viewerId,
    }),
    [viewerId],
  );

  const update = useCallback(
    (cache, { data }) => {
      onSuccess({ cache, data });
    },
    [onSuccess],
  );

  const onError = useApolloError();
  const [verifyTotp, { loading: verifyingTotp }] = useVerifyTotpMutation({ update, onError });

  const onSecondFactorFormSubmit = useCallback(
    values => {
      const { code, userId } = values as SecondFactorFormFields;

      logger('Second factor form submitted with', values);

      verifyTotp({ variables: { input: { code, userId } } }).then(res => {
        if (res.data) {
          const { data } = res;
          logger('verifyTotp response', { data });
          secondFactorForm.resetFields();
        }
      }, logger);
    },
    [secondFactorForm, verifyTotp],
  );

  const addonBeforeCodeInput = useMemo(
    () => (
      <InputPrefixLabel>
        <LockOutlined />
      </InputPrefixLabel>
    ),
    [],
  );

  const handleBackBtnClick = useCallback(
    event => {
      if (typeof onBackBtnClick === 'function') {
        onBackBtnClick(event);
      }
    },
    [onBackBtnClick],
  );

  return (
    <>
      <p style={{ margin: '0 0 2rem' }}>{t('With Google Authenticator')}</p>
      <Form
        layout="vertical"
        form={secondFactorForm}
        initialValues={secondFactorFormInitialValues}
        onFinish={onSecondFactorFormSubmit}>
        <FormItem name="userId" hidden rules={[{ required: true }]} />
        <FormItem name="code" rules={[{ required: true, message: t('Please fill this field') }]}>
          <Input name="code" placeholder={t('Enter code')} addonBefore={addonBeforeCodeInput} />
        </FormItem>
        <FormItem>
          <Button block type="primary" htmlType="submit" size="large" loading={verifyingTotp}>
            {t('Confirm')}
          </Button>
        </FormItem>
        <FormItem>
          <BackButton block type="ghost" htmlType="button" size="large" onClick={handleBackBtnClick}>
            {t('Back to login')}
          </BackButton>
        </FormItem>
      </Form>
    </>
  );
};

export default memo(SecondFactor) as typeof SecondFactor;
