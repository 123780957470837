import React, { FC, memo, useContext, useCallback } from 'react';
import { ViewerContext } from '@fjedi/react-router-helpers';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
import { Divider, Tag } from 'antd';
import { Row, Col } from 'src/components/ui-kit/grid';
import { Form, FormItem, parseFormData } from 'src/components/ui-kit/form';
import { DEFAULT_PHONE_NUMBER_PREFIX, Input, PhoneNumberMaskedInput } from 'src/components/ui-kit/input';
import Button from 'src/components/ui-kit/buttons';
import { UserInput, useUpdateUserMutation, Viewer, ViewerRole } from 'src/graphql/generated';
import { logger } from '@fjedi/graphql-react-components';

const UserProfileMainPage: FC = () => {
  const viewer = useContext(ViewerContext) as Viewer;
  const isAdmin = viewer?.role === ViewerRole.Admin;
  //
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [updateProfile, { loading }] = useUpdateUserMutation();
  const onSubmit = useCallback(
    (formData: unknown) => {
      const input = parseFormData(form, formData, true) as Partial<UserInput>;
      logger('UserProfile.onSubmit', { input });
      if (input.phoneNumber) {
        const r = /\D+/gi;
        const phoneNumber = input.phoneNumber.replace(r, '');
        input.phoneNumber = !phoneNumber || phoneNumber === DEFAULT_PHONE_NUMBER_PREFIX ? null : `+${phoneNumber}`;
      }
      //
      updateProfile({
        variables: { id: viewer.id, input },
      }).catch(logger);
    },
    [updateProfile, viewer?.id],
  );
  if (!viewer) {
    return null;
  }
  const { firstName, lastName, middleName, phoneNumber, email, role } = viewer;

  return (
    <Row>
      <Col md={{ span: 8, offset: 8 }}>
        <Divider style={{ margin: '0.5rem 0 0' }}>
          <Tag color="red">{t(capitalize(role))}</Tag>
        </Divider>
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          initialValues={{
            email,
            firstName,
            lastName,
            middleName,
            phoneNumber,
          }}>
          <FormItem
            label={t('Email')}
            name="email"
            rules={[
              {
                required: true,
                message: 'Введите телефон',
              },
            ]}>
            <Input disabled={!isAdmin} />
          </FormItem>
          <FormItem
            label={t('First name')}
            name="firstName"
            rules={[
              {
                required: true,
                message: 'Введите имя',
              },
            ]}>
            <Input disabled={!isAdmin} />
          </FormItem>
          <FormItem label={t('Last name')} name="lastName">
            <Input disabled={!isAdmin} />
          </FormItem>
          <FormItem label={t('Middle name')}>
            <Input disabled={!isAdmin} />
          </FormItem>
          <FormItem label={t('Phone')} name="phoneNumber">
            <PhoneNumberMaskedInput disabled={!isAdmin} />
          </FormItem>
          <FormItem>
            <Button block loading={loading} type="primary" size="large" htmlType="submit">
              {t('Save')}
            </Button>
          </FormItem>
        </Form>
      </Col>
    </Row>
  );
};

export default memo(UserProfileMainPage);
