import React, { memo, FC, useMemo, useEffect, useState } from 'react';
import groupBy from 'lodash/groupBy';
// @ts-ignore
import { HeatmapLayer } from 'react-leaflet-heatmap-layer-v3';
import type { LatLngLiteral } from 'leaflet';
import { useMap } from 'react-leaflet';
import { getSignalStrengthGradient } from './helpers';

import type { SignalStrength } from './helpers';
// import { RSSI_COLORS } from 'src/components/ui-kit/leaflet-map/constants';

export interface RssiRouteData {
  location: LatLngLiteral;
  dBm: number;
  weight: number;
  signalStrength: SignalStrength;
}

interface RssiProps {
  data: RssiRouteData[];
}

type HeatmapLayerData = any;

const Rssi: FC<RssiProps> = ({ data: rssiData }) => {
  const map = useMap();
  const [zoom, setZoom] = useState(map.getZoom());
  const heatmapOptions = useMemo(
    () => ({
      dissipating: false,
      opacity: 0.75,
      radius: 0.0125,
    }),
    [],
  );

  const heatmapLayers = useMemo(() => {
    const layers = groupBy(rssiData, 'signalStrength');

    return Object.keys(layers).map(signalStrength => {
      const data = layers[signalStrength as SignalStrength].map(({ weight, location, dBm }) => ({
        weight,
        location,
        dBm,
      }));

      const gradient = getSignalStrengthGradient(signalStrength as SignalStrength);

      return {
        data,
        gradient,
        ...heatmapOptions,
      } as HeatmapLayerData;
    });
  }, [rssiData, heatmapOptions]);

  useEffect(() => {
    if (!map) {
      return;
    }
    map.on('zoom', event => {
      setZoom(event.target.getZoom());
    });
  }, [map, setZoom]);

  return (
    <>
      {heatmapLayers.map(({ data, gradient }) => {
        const start = data[0].location;
        const end = data?.at(-1)?.location;
        const key = `hm-${start.lat}_${end!.lat}_${start.lng}_${end!.lng}`;

        return (
          <HeatmapLayer
            key={key}
            gradient={gradient}
            fitBoundsOnLoad={false}
            fitBoundsOnUpdate={false}
            points={data}
            longitudeExtractor={m => m.location.lng}
            latitudeExtractor={m => m.location.lat}
            intensityExtractor={m => parseFloat(m.dBm)}
          />
        );
      })}
    </>
  );
};

export default memo(Rssi) as typeof Rssi;
