import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import styled from 'styled-components';
import { Card as AntCard } from 'antd';
//
const Card = styled(AntCard)`
  background-color: #fff;
  //padding: 1rem 2rem;

  & + & {
    border-top: 0;
  }
`;

const CardComponent = ({ children, ...props }) => <Card {...props}>{children}</Card>;

CardComponent.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
};
CardComponent.defaultProps = {
  className: '',
};

export default pure(CardComponent);
