/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-array-index-key */
import React, { memo, useMemo, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import last from 'lodash/last';
import compact from 'lodash/compact';
import { ViewerRoleContext, useNavigate, useMatch, useLocation } from '@fjedi/react-router-helpers';
import { useTranslation } from 'react-i18next';
import Tooltip from 'src/components/ui-kit/tooltip';
import Button from 'src/components/ui-kit/buttons';
import Card from 'src/components/ui-kit/card';
import { Search } from 'src/components/ui-kit/input';
import { Title as AntTitle, Text as AntText } from 'src/components/ui-kit/typography';
import HeaderBreadcrumb from 'src/components/ui-kit/breadcrumb';
import { stopEventPropagation } from 'src/functions';
import Popconfirm from '../popconfirm';
import { colorTheme } from '../theme';

const Header = styled.div`
  display: flex;
  align-items: center;
  //margin: -1.5rem -1.5rem 1.25rem;
  //padding: 1rem 1.5rem 0.75rem;
  //box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.08);
  padding: 0 0.15rem 1rem;
  white-space: normal;
`;

const HeaderAction = styled(Button)`
  &.ant-btn-icon-only {
    height: 2.25rem;
    max-width: 2.25rem;
    flex: 1 0 2.25rem;
    margin-left: 0.5rem;
    color: ${colorTheme.dark};
    border-color: transparent;
    box-shadow: none;
    background-color: transparent;

    svg {
      font-size: 1.3rem;
    }

    &:hover {
      color: #6473c4;
      border-color: transparent;
      background-color: transparent;
    }
  }
`;

const HeaderBody = styled.div`
  flex-grow: 1;
`;

const Title = styled(AntTitle)`
  &.ant-typography.ant-typography {
    font-weight: 600;
    font-size: 1.125rem;
    margin: 0;
    line-height: 1.2;
  }
`;

const SubTitle = styled(AntText)`
  &.ant-typography {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.2);
    line-height: 1.2;
    margin-top: 0.5rem;
    display: inline-block;
  }
`;

const Container = styled(Card)`
  &.ant-card {
    height: 100%;
    max-width: calc(100vw - 400px - 220px - 4rem);
    min-width: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;

    &,
    &.ant-card-contain-tabs {
      > .ant-card-head,
      > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
        min-height: ${props => (props.title ? '3.75rem' : 'calc(3.75rem - 17px)')};
      }

      .ant-tabs > .ant-tabs-nav,
      .ant-tabs > div > .ant-tabs-nav {
        min-height: 3.75rem;
      }

      .ant-card-body {
        .ant-card-head,
        .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
          min-height: initial;
          border: 0;
          padding: 0 0.05rem 0.25rem;
          box-shadow: none;
        }
      }
    }

    & > .ant-card-head {
      //min-height: 3.5rem;
      box-shadow: rgba(0, 0, 0, 0.08) 0 0.125rem 0.25rem;

      > .ant-card-head-wrapper {
        > .ant-card-head-title .ant-breadcrumb > span:last-of-type .ant-typography,
        > .ant-card-extra > .ant-btn {
          color: ${colorTheme.dark};
        }
      }

      .ant-card-head-title,
      .ant-card-extra {
        padding-top: 0.85rem;
        padding-bottom: 0.5rem;
        display: flex;
        align-items: center;
      }
    }

    .ant-card-body {
      height: 100%;
      // remove this to prevent cut of from the bottom on device screen
      // min-height: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      > div {
        flex-grow: 1;
      }

      > ${Header} {
        flex-grow: 0;
      }
    }

    &.ant-card-contain-tabs {
      //> .ant-card-head .ant-card-head-title {
      //  min-height: 3.75rem;
      //}

      .ant-card {
        box-shadow: none;
      }

      > .ant-card-head > .ant-tabs .ant-tabs-nav-list > .ant-tabs-tab {
        padding: 0.75rem 1.25rem 0.75rem 1rem;
      }

      > .ant-card-body {
        height: ${props => (props.title ? 'calc(100% - 8rem)' : 'calc(100% - 4rem)')};
        min-height: ${props => (props.title ? 'calc(100% - 8rem)' : 'calc(100% - 4rem)')};
      }
      //
      // ${Header} {
      //   padding: 1rem 1.5rem 1rem 1.5rem;
      // }
    }
  }
`;

export const TabExtraActionsContainer = styled.div`
  display: inline-flex;
  align-items: center;

  .device-picker {
    margin-right: 1rem;
  }

  .datepicker {
    //margin-right: 1rem;
  }

  > .ant-btn {
    margin-left: 0.25rem;
    height: 2rem;
  }

  > .ant-btn:last-child {
    margin-left: 0.75rem;
  }

  > ${Search} {
    height: auto;
  }
`;

export const ContentCard = cardProps => {
  const {
    className,
    children,
    header,
    tabList,
    extra,
    tabBarExtraContent,
    loading,
    tabURLNavigation,
    showBreadcrumbs,
    showBackButton,
    onTabChange: onTabClick,
    ...props
  } = cardProps;
  const role = useContext(ViewerRoleContext);
  const { t } = useTranslation();
  const location = useLocation();
  const match = useMatch(location.pathname);
  const navigate = useNavigate();
  const defaultActiveTabKey = tabURLNavigation ? last(location.pathname.split('/')) : props.activeTabKey;
  const initialActiveTabKey = tabList?.some(tab => tab.key === defaultActiveTabKey)
    ? defaultActiveTabKey
    : tabList?.[0]?.key;
  const [activeTabKey, setActiveTabKey] = useState(initialActiveTabKey);
  //
  const { actions, title, subtitle } = header || {};
  //
  const onTabChange = useCallback(
    tabKey => {
      setActiveTabKey(tabKey);
      //
      if (!tabURLNavigation && typeof onTabClick === 'function') {
        onTabClick(tabKey);
      }
      if (tabURLNavigation) {
        const url = compact(
          `${match.pathname}/${tabKey}`.split('/').filter(u => u === tabKey || !tabList.some(tab => tab.key === u)),
        ).join('/');
        navigate(`/${url}`, { replace: true });
      }
    },
    [navigate, match.pathname, onTabClick, tabList, tabURLNavigation],
  );
  //
  const tabs = useMemo(() => {
    //
    const filteredByAccessPolicyRules = tabList?.filter(tab => {
      const { allowedRoles } = tab;
      // if no allowed roles specified
      if (!allowedRoles) {
        return true;
      }
      if (allowedRoles.includes(role)) {
        return true;
      }
      return false;
    });

    return filteredByAccessPolicyRules?.map(tab => ({ ...tab, tab: t(tab.tab) })) || [];
  }, [t, role, tabList]);
  //
  return (
    <Container
      className={className}
      loading={loading}
      activeTabKey={activeTabKey}
      tabList={tabs}
      onTabChange={onTabChange}
      tabBarExtraContent={tabBarExtraContent}
      title={
        showBreadcrumbs ? (
          <HeaderBreadcrumb title={title} showBackButton={showBackButton} />
        ) : (
          !!title && (
            <Header>
              <HeaderBody>
                <Title level={3}>{title}</Title>
                {subtitle && <SubTitle>{subtitle}</SubTitle>}
              </HeaderBody>
            </Header>
          )
        )
      }
      extra={
        !header
          ? extra
          : Array.isArray(actions) &&
            actions.map((action, actionIndex) => {
              if (typeof action?.render === 'function') {
                return action?.render(actionIndex);
              }
              if (!action?.icon) {
                return null;
              }
              if (typeof action?.onClick === 'function') {
                return action?.tooltipText ? (
                  <Tooltip title={action.tooltipText} align={{ offset: [0, 5] }} key={actionIndex}>
                    <HeaderAction
                      type={action.type || 'light'}
                      onClick={action.onClick}
                      shape={action.shape || 'circle'}
                      icon={action.icon}
                      loading={action.loading}
                      disabled={action.disabled}
                    />
                  </Tooltip>
                ) : (
                  <HeaderAction
                    key={actionIndex}
                    type={action.type || 'light'}
                    onClick={action.onClick}
                    shape={action.shape || 'circle'}
                    icon={action.icon}
                    loading={action.loading}
                  />
                );
              }
              if (typeof action?.onConfirm === 'function') {
                return (
                  <Popconfirm
                    key={actionIndex}
                    placement="bottomRight"
                    title={action?.confirmationText || `${t('Are you sure')}?`}
                    onClick={stopEventPropagation}
                    onConfirm={action?.onConfirm}
                    okText={t('Yes')}
                    cancelText={t('No')}>
                    {action?.tooltipText ? (
                      <Tooltip title={action.tooltipText} align={{ offset: [0, 5] }}>
                        <HeaderAction
                          type={action.type || 'light'}
                          onClick={action.onClick}
                          shape={action.shape || 'circle'}
                          icon={action.icon}
                          loading={action.loading}
                        />
                      </Tooltip>
                    ) : (
                      <HeaderAction
                        type={action.type || 'light'}
                        onClick={action.onClick}
                        shape={action.shape || 'circle'}
                        icon={action.icon}
                        loading={action.loading}
                      />
                    )}
                  </Popconfirm>
                );
              }
              return null;
            })
      }>
      {children}
    </Container>
  );
};

const ContentCardHeaderActionType = PropTypes.shape({
  icon: PropTypes.node,
  shape: PropTypes.oneOf(['circle']),
  onClick: PropTypes.func,
  tooltipText: PropTypes.string,
});
const ContentCardHeaderType = PropTypes.shape({
  title: PropTypes.string,
  subtitle: PropTypes.string,
  actions: PropTypes.arrayOf(ContentCardHeaderActionType),
});
ContentCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  header: ContentCardHeaderType,
  extra: PropTypes.node,
  showBreadcrumbs: PropTypes.bool,
  showBackButton: PropTypes.bool,
  tabBarExtraContent: PropTypes.node,
  tabURLNavigation: PropTypes.bool,
  loading: PropTypes.bool,
  activeTabKey: PropTypes.string,
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      tab: PropTypes.node,
      allowedRoles: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  onTabChange: PropTypes.func,
};
ContentCard.defaultProps = {
  className: '',
  loading: false,
  tabURLNavigation: false,
  showBreadcrumbs: false,
  showBackButton: false,
  header: null,
  extra: undefined,
  tabBarExtraContent: undefined,
  tabList: undefined,
  activeTabKey: undefined,
  onTabChange: undefined,
};
ContentCard.displayName = 'ContentCard';

export default memo(ContentCard);
