import React, { FC, memo, useCallback } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMutation, updateAfterMutation, useApolloError, logger } from '@fjedi/graphql-react-components';
import { RestrictedArea } from '@fjedi/react-router-helpers';
import createUserRoleMutation from 'src/graphql/mutations/user-role/create.graphql';
import { UserRole } from 'src/graphql/generated';
import { Divider } from 'antd';
import { Form, FormItem } from 'src/components/ui-kit/form';
import SubmitButton from 'src/components/ui-kit/buttons/submit';
import { Row, Col } from 'src/components/ui-kit/grid';
import UserRolesTable from 'src/components/common/user-roles-table';
import UserSelector from 'src/components/common/user-selector';
import RoleSelector from 'src/components/common/role-selector';

const Container = styled.div`
  max-width: 100%;

  > .ant-form > .ant-row {
    &:last-of-type {
      margin: 0;
    }

    .ant-form-item .ant-form-item-control .ant-select > .ant-select-selector {
      border-radius: 0.5rem;
    }
  }
`;

const OrganizationProfileUserRoles: FC<{ organizationId: string }> = ({ organizationId }) => {
  const { t } = useTranslation();
  const onError = useApolloError();
  const [form] = Form.useForm();
  const { resetFields } = form;
  const onCompleted = useCallback(() => {
    resetFields();
  }, [resetFields]);

  const [createUserRole, { loading: addingUser }] = useMutation(createUserRoleMutation, {
    onCompleted,
    onError,
    update: updateAfterMutation('UserRole', 'userRoles'),
  });

  const onSubmit = useCallback(
    input => {
      logger('UserRoles.createUserRole', {
        input,
      });

      createUserRole({
        variables: {
          input: { ...input, companyId: organizationId },
        },
      }).catch(logger);
    },
    [createUserRole, organizationId],
  );

  return (
    <>
      <Container>
        <Divider>{t('Roles')}</Divider>
        <RestrictedArea areaType="block" allowedRoles={['ADMIN']}>
          <Form form={form} layout="vertical" onFinish={onSubmit}>
            <Row gutter={8} align="top">
              <Col span={10}>
                <FormItem
                  name="userId"
                  label={t('User')}
                  rules={[
                    {
                      required: true,
                      message: t('Please fill this field') as string,
                    },
                  ]}>
                  <UserSelector />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name="role"
                  label={t('Role')}
                  rules={[
                    {
                      required: true,
                      message: t('Please fill this field') as string,
                    },
                  ]}>
                  <RoleSelector />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem style={{ marginTop: '1.35rem' }}>
                  <SubmitButton
                    style={{
                      verticalAlign: 'middle',
                    }}
                    type="primary"
                    htmlType="submit"
                    loading={addingUser}>
                    {t('Add')}
                  </SubmitButton>
                </FormItem>
              </Col>
            </Row>
          </Form>
        </RestrictedArea>
      </Container>
      <UserRolesTable
        companyId={organizationId}
        columns={[
          {
            title: t('Name'),
            dataIndex: 'profile',
            width: 180,
            render(profile: UserRole['profile']) {
              return profile?.fullName || '';
            },
          },
          {
            title: t('Email'),
            dataIndex: 'profile',
            width: 180,
            render(profile: UserRole['profile']) {
              return profile?.email || '';
            },
          },
        ]}
      />
    </>
  );
};

OrganizationProfileUserRoles.displayName = 'OrganizationProfileUserRoles';

export default memo(OrganizationProfileUserRoles);
