/* eslint-disable no-confusing-arrow */
// React
import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { pure, compose, withStateHandlers } from 'recompose';
//
import { Empty } from 'antd';
import ModalPopup from 'src/components/ui-kit/modal-popup';

const ImagePreviewContainer = styled.div`
  max-height: 100vh;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
`;

const FullImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const ImageModalComponent = ({ children, width, alt, src, isVisible, toggleModal }) => (
  <ImagePreviewContainer>
    <ModalPopup width={width} isVisible={isVisible} onCancel={toggleModal}>
      <FullImage src={src} alt={alt} />
    </ModalPopup>
    {src && children
      ? cloneElement(children, {
          onClick: toggleModal,
        })
      : false}
    {!children && src && <ImagePreview onClick={toggleModal} src={src} alt={alt} />}
    {!src && <Empty />}
  </ImagePreviewContainer>
);

ImageModalComponent.propTypes = {
  width: PropTypes.number,
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};
ImageModalComponent.defaultProps = {
  width: 900,
};

export default compose(
  withStateHandlers(
    () => ({
      isVisible: false,
    }),
    {
      toggleModal:
        ({ isVisible }) =>
        () => ({
          isVisible: !isVisible,
        }),
    },
  ),
  pure,
)(ImageModalComponent);
