/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, memo, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'antd/lib/input';
import type { FilterDropdownProps } from 'antd/lib/table/interface';
import type { ColumnsType } from 'antd/lib/table';
import { ViewerRoleContext } from '@fjedi/react-router-helpers';
import { useApolloError, logger, ApolloCache } from '@fjedi/graphql-react-components';

import { Subscriber, UserRoleRole, useUpdateAliasMutation, SubscriberFragmentDoc } from 'src/graphql/generated';
import getSubscribersQuery from 'src/graphql/queries/get-subscribers.graphql';

import { SearchOutlined } from 'src/components/ui-kit/icons';
import FilterDropdown from 'src/components/ui-kit/table/filter-dropdown';
import { colorTheme } from 'src/components/ui-kit/theme';
import Table from 'src/components/ui-kit/table';

function updateSubscriberAfterAliasUpdate(cache: ApolloCache<unknown>, result: any): void {
  const { data } = result;
  const alias = data.createAlias ?? data.updateAlias ?? data.removeAlias;
  if (!alias) {
    return;
  }
  cache.writeFragment({
    id: `Subscriber:${alias.subscriberId}`, // The value of the to-do item's cache ID
    fragment: SubscriberFragmentDoc,
    data: {
      alias: alias.subscriberAlias || '',
    },
  });
}

const AliasesTab: FC = () => {
  const { t } = useTranslation();
  const onError = useApolloError();
  const viewerRole = useContext(ViewerRoleContext);

  const [updateAlias] = useUpdateAliasMutation({
    onError,
    update: updateSubscriberAfterAliasUpdate,
  });

  const onChangeField = useCallback(
    async (subscriberId: Subscriber['id'], formData: Partial<Subscriber>) => {
      try {
        const subscriberAlias = formData.alias ?? '';
        //
        logger('Aliases.onChangeField', {
          subscriberAlias,
          subscriberId,
        });
        if (!Object.keys(formData as {}).length) {
          return;
        }
        await updateAlias({
          variables: { input: { subscriberId, subscriberAlias } },
          optimisticResponse: {
            updateAlias: {
              __typename: 'Alias',
              subscriberId,
              subscriberAlias,
            },
          },
        });
      } catch (error: unknown) {
        logger('Failed to create/update/remove alias', error as Error);
      }
    },
    [updateAlias],
  );

  const columns: ColumnsType<Subscriber> = useMemo(
    () =>
      [
        {
          title: t('Subscriber ID'),
          dataIndex: 'id',
          width: '20%',
          // eslint-disable-next-line react/no-unstable-nested-components
          filterDropdown: (props: FilterDropdownProps) => <FilterDropdown {...props} />,
          // eslint-disable-next-line react/no-unstable-nested-components
          filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? colorTheme.primary : undefined }} />
          ),
          render(_, { id }) {
            return `${id}`;
          },
        },
        {
          title: t('Company ID'),
          dataIndex: 'remoteCompanyId',
          width: '20%',
          // eslint-disable-next-line react/no-unstable-nested-components
          filterDropdown: (props: FilterDropdownProps) => <FilterDropdown {...props} />,
          // eslint-disable-next-line react/no-unstable-nested-components
          filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? colorTheme.primary : undefined }} />
          ),
          render(_, subscriber) {
            return `${subscriber.companyId}`;
          },
        },
        {
          title: t('Company name'),
          dataIndex: 'companyName',
          width: '20%',
          // eslint-disable-next-line react/no-unstable-nested-components
          filterDropdown: (props: FilterDropdownProps) => <FilterDropdown {...props} />,
          // eslint-disable-next-line react/no-unstable-nested-components
          filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? colorTheme.primary : undefined }} />
          ),
          render(_, subscriber) {
            return `${subscriber.companyName}`;
          },
        },
        {
          title: t('Alias'),
          dataIndex: 'alias',
          width: '40%',
          // eslint-disable-next-line react/no-unstable-nested-components
          filterDropdown: (props: FilterDropdownProps) => <FilterDropdown {...props} />,
          // eslint-disable-next-line react/no-unstable-nested-components
          filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? colorTheme.primary : undefined }} />
          ),
          onChangeField,
          inputAlwaysVisible: true,
          input: Input,
          editable() {
            return viewerRole === UserRoleRole.Admin;
          },
          inputProps(subscriber: Subscriber) {
            return {
              initialValue: subscriber?.alias ?? '',
            };
          },
          render(_, subscriber) {
            return subscriber.alias;
          },
        },
      ] as ColumnsType<Subscriber>,
    [t, onChangeField, viewerRole],
  );

  return (
    <Table columns={columns} pagination pageSize={15} query={getSubscribersQuery} dataType="Subscriber" rowKey="id" />
  );
};

export default memo(AliasesTab);
