import React, { useCallback, memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import pick from 'lodash/pick';
import { updateAfterMutation, logger, useApolloError } from '@fjedi/graphql-react-components';
import { RestrictedArea, useNavigate } from '@fjedi/react-router-helpers';
import { CreateCompanyInput, useCreateCompanyMutation, useUpdateCompanyMutation } from 'src/graphql/generated';
import { Row, Col } from 'src/components/ui-kit/grid';
import { Form, FormItem, parseFormData } from 'src/components/ui-kit/form';
import SubmitButton from 'src/components/ui-kit/buttons/submit';
import MainInfo from 'src/components/routes/private/admin/organizations/profile/form-main';
import AdditionalInfo from 'src/components/routes/private/admin/organizations/profile/form-additional';
import UsersInfo from 'src/components/routes/private/admin/organizations/profile/form-users';

const Container = styled.div`
  max-width: 100%;
  margin: auto;

  .ant-form-item .ant-form-item-control .ant-input {
    &#comments {
      border-radius: 0.5rem;
    }
  }

  > form > div {
    &:first-child {
      flex-grow: 1;
      margin-right: 1rem;
    }
  }

  > form > section {
    &:not(:first-of-type) {
      margin-top: 2rem;
    }
  }
`;

type OrganizationProfileCardProps = {
  data?: {
    name: string;
  };
  organizationId?: string;
};

const OrganizationProfileCard: FC<OrganizationProfileCardProps> = props => {
  const { data, organizationId } = props;
  const [companyForm] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onCompleted = useCallback(() => {
    companyForm.resetFields();
    navigate(-1);
  }, [companyForm, navigate]);
  const onError = useApolloError();
  const [createCompany, { loading: creating }] = useCreateCompanyMutation({
    onCompleted,
    onError,
    update: updateAfterMutation('Company', 'companies'),
  });
  const [updateCompany, { loading: updating }] = useUpdateCompanyMutation({
    onCompleted,
    onError,
  });
  const loading = creating || updating;

  const onSubmit = useCallback(
    async (formData: unknown) => {
      try {
        logger('Profile.onSubmit', { formData, form: companyForm });

        const onlyChangedFields = true;
        const inputOrganizationData = parseFormData(companyForm, formData, onlyChangedFields) as Record<
          string,
          unknown
        >;
        const { phoneNumber, repeatPassword, ...rest } = inputOrganizationData as CreateCompanyInput & {
          repeatPassword: string;
          phoneNumber?: string;
        };
        const r = /-/g;
        const input = phoneNumber
          ? {
              ...rest,
              phoneNumber: `+${(phoneNumber as string).replace(r, '').trim()}`,
            }
          : rest;

        logger('Create/Update organization submit', {
          formData,
          input,
        });
        if (!organizationId) {
          await createCompany({
            variables: {
              input,
            },
          });
        } else {
          await updateCompany({
            variables: {
              id: organizationId,
              input,
            },
          });
        }
      } catch (error: unknown) {
        logger('Failed to create/update company', error as Error);
      }
    },
    [companyForm, organizationId, createCompany, updateCompany],
  );

  return (
    <Container>
      <Form
        form={companyForm}
        layout="vertical"
        scrollToFirstError
        onFinish={onSubmit}
        initialValues={pick(data, ['name', 'remoteId', 'comments'])}>
        <Row>
          <Col md={11}>
            <MainInfo />
          </Col>
          <Col md={{ span: 11, offset: 2 }}>
            <AdditionalInfo />
          </Col>
        </Row>
        <RestrictedArea areaType="block" allowedRoles={['ADMIN']}>
          <FormItem shouldUpdate>
            {() => (
              <SubmitButton
                style={{ margin: '0.75rem auto 1rem' }}
                loading={loading}
                disabled={companyForm.getFieldsError().filter(({ errors }) => errors.length).length > 0}>
                {data ? t('Save') : t('Create')}
              </SubmitButton>
            )}
          </FormItem>
        </RestrictedArea>
      </Form>

      {organizationId && <UsersInfo organizationId={organizationId} />}
    </Container>
  );
};

OrganizationProfileCard.displayName = 'OrganizationProfileCard';

export default memo(OrganizationProfileCard);
