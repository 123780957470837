import React, { FC, memo, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import logger from 'src/helpers/logger';
import { useTranslation } from 'react-i18next';
import { updateAfterMutation, useApolloError } from '@fjedi/graphql-react-components';
import { RestrictedArea } from '@fjedi/react-router-helpers';
import { useCreateUserRoleMutation, UserRole, useUserQuery } from 'src/graphql/generated';
import { Divider } from 'antd';
import { Form, FormItem } from 'src/components/ui-kit/form';
import SubmitButton from 'src/components/ui-kit/buttons/submit';
import { Row, Col } from 'src/components/ui-kit/grid';
import UserRolesTable from 'src/components/common/user-roles-table';

import CompanySelector from 'src/components/common/company-selector';
import RoleSelector from 'src/components/common/role-selector';

const Container = styled.div`
  max-width: 100%;

  > .ant-form > .ant-row {
    &:last-of-type {
      margin: 0;
    }

    .ant-form-item .ant-form-item-control .ant-select > .ant-select-selector {
      border-radius: 0.5rem;
      //padding-left: 0.5rem;
    }
  }
`;

const UserProfileUserRoles: FC<{ userId: string }> = ({ userId }) => {
  const { t } = useTranslation();
  const onError = useApolloError();
  const [form] = Form.useForm();
  const { resetFields } = form;
  const onCompleted = useCallback(() => {
    resetFields();
  }, [resetFields]);
  const [createUserRole, { loading: addingUser }] = useCreateUserRoleMutation({
    onCompleted,
    onError,
    update: updateAfterMutation('UserRole', 'userRoles'),
  });

  const { data: userRes, loading: userLoading } = useUserQuery({ variables: { id: userId } });
  const user = useMemo(() => userRes?.user, [userRes?.user]);
  const hasRoleInPrimaryCompany = useMemo(() => !!user?.primaryCompanyId && !!user?.roleInPrimaryCompany, [user]);
  const defaultValue = useMemo(() => user?.primaryCompanyId, [user?.primaryCompanyId]);

  const onSubmit = useCallback(
    input => {
      logger('UserRoles.createUserRole', {
        input,
      });
      createUserRole({
        variables: {
          input: { ...input, userId },
        },
      }).catch(logger);
    },
    [createUserRole, userId],
  );

  return (
    <>
      <Container>
        <Divider>{t('Roles')}</Divider>
        <RestrictedArea areaType="block" allowedRoles={['ADMIN']}>
          <Form form={form} layout="vertical" onFinish={onSubmit}>
            <Row gutter={8} align="top">
              <Col span={10}>
                <FormItem
                  name="companyId"
                  label={t('Company')}
                  rules={[
                    {
                      required: true,
                      message: t('Please fill this field') as string,
                    },
                  ]}>
                  <CompanySelector
                    hasRoleInPrimaryCompany={hasRoleInPrimaryCompany}
                    defaultValue={defaultValue as string}
                  />
                </FormItem>
              </Col>
              <Col span={8}>
                <FormItem
                  name="role"
                  label={t('Role')}
                  rules={[
                    {
                      required: true,
                      message: t('Please fill this field') as string,
                    },
                  ]}>
                  <RoleSelector />
                </FormItem>
              </Col>
              <Col span={6}>
                <FormItem style={{ marginTop: '1.35rem' }}>
                  <SubmitButton
                    style={{
                      verticalAlign: 'middle',
                    }}
                    type="primary"
                    htmlType="submit"
                    loading={addingUser}>
                    {hasRoleInPrimaryCompany ? t('Change') : t('Add')}
                  </SubmitButton>
                </FormItem>
              </Col>
            </Row>
          </Form>
        </RestrictedArea>
      </Container>
      {!userLoading && (
        <UserRolesTable
          userId={userId}
          columns={[
            {
              title: t('Company'),
              dataIndex: 'company',
              width: 180,
              render(company: UserRole['company']) {
                return company?.name || '';
              },
            },
          ]}
        />
      )}
    </>
  );
};

UserProfileUserRoles.displayName = 'UserProfileUserRoles';

export default memo(UserProfileUserRoles);
