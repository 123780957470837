import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// <Helmet> component for setting the page title/meta tags
import Helmet from 'react-helmet';

const Container = styled.div`
  color: #474a54;
  background: ${props => props.theme.authBoxBackground};
  height: auto;
  width: 100%;
  position: relative;

  @media screen and (orientation: portrait) {
    padding-top: ${props => props.theme.headerHeight * 2};
    padding-bottom: ${props => props.theme.headerHeight};
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.2);
  width: 640px;
  min-height: 340px;
  max-width: calc(100vw - 2rem);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  margin: auto;

  @media screen and (orientation: portrait) {
    position: relative;
    left: 0;
    top: 0;
    transform: initial;
  }

  h2 {
    font-weight: 400;
    font-size: 26px;
  }

  > div,
  > form {
    width: 340px;
    max-width: 100%;
    padding-bottom: 1rem;

    //input {
    //  @include transition(border);
    //  border-radius: 2px;
    //  display: inline-block;
    //  padding: 9px;
    //  border: 1px solid #ddd;
    //  margin-bottom: 10px;
    //  font-size: 14px;
    //  line-height: 20px;
    //  vertical-align: middle;
    //}

    p {
      margin: 1rem 0 1.2rem;
    }

    a {
      color: ${({ theme }) => theme.token.colorPrimary};
    }

    > div:not(:last-child) {
      margin-bottom: 1rem;
    }

    .ant-row.ant-form-item {
      &:last-child {
        margin-bottom: 0;
      }
    }
    //input[type='text'],
    //input[type='email'],
    //input[type='password'],
    //input[type='number'] {
    //  transition: border 0.4s;
    //  border-radius: 2px;
    //  display: inline-block;
    //  padding: 9px;
    //  border: 1px solid #ddd;
    //  margin-bottom: 10px;
    //  font-size: 14px;
    //  line-height: 20px;
    //  height: 42px;
    //  vertical-align: middle;
    //}
  }
`;
const Header = styled.header`
  display: flex;
  justify-content: center;
  //position: absolute;
  //bottom: 100%;
  //left: 0;
  width: 100%;
  text-align: center;
  // height: ${props => props.theme.authBoxHeaderHeight};

  h1 {
    color: ${props => props.theme.token.colorPrimary};
    font-family: ${props => props.theme.baseFontFamily};
    //text-transform: uppercase;
    //margin: 10px 0 40px;
    margin: 1rem auto;
    padding: 0;
    //height: 34px;
    font-size: 30px;
    //font-weight: 400;
    line-height: 1.13;
    text-align: center;
  }
`;
const Footer = styled.footer`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  //position: absolute;
  //top: 100%;
  //left: 0;
  //width: 100%;
  text-align: center;
  padding: 30px 0 0;
  font-size: 13px;

  button,
  a {
    @include push--auto();
    text-decoration: none;
    color: #fff;
    font-size: 15px;
    display: inline-block;
    line-height: 2;

    &:focus,
    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
`;
const AuthBox = props => {
  const { title, children, footerChildren } = props;

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Container>
        <Content>
          <Header>
            <h1>{title}</h1>
          </Header>
          {children}
          {footerChildren && <Footer>{footerChildren}</Footer>}
        </Content>
      </Container>
    </Fragment>
  );
};

AuthBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func, PropTypes.array]).isRequired,
  footerChildren: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func, PropTypes.array]),
  title: PropTypes.string.isRequired,
};
AuthBox.defaultProps = {
  footerChildren: undefined,
};

export default AuthBox;
