import { colorBase } from 'src/components/ui-kit/theme';

export const elementColors = [
  '#641220',
  '#FF4800',
  '#FDB833',
  '#036666',
  '#0D47A1',
  '#6247AA',
  '#774936',
  '#FF0A54',
  '#A71E34',
  '#FF7900',
  '#FFD53E',
  '#469D89',
  '#2196F3',
  '#A06CD5',
  '#B07D62',
  '#FF85A1',
  '#DA1E37',
  '#FFAA00',
  '#FFF056',
  '#88D4AB',
  '#BBDEFB',
  '#DAC3E8',
  '#E6B8A2',
  '#F7CAD0',
  '#85182A',
  '#FF6000',
  '#FDC43F',
  '#248277',
  '#1976D2',
  '#815AC0',
  '#9D6B53',
  '#FF5C8A',
  '#BD1F36',
  '#FF9100',
  '#FFE246',
  '#67B99A',
  '#64B5F6',
  '#C19EE0',
  '#D69F7E',
  '#FBB1BD',
] as const;

export const pinPath = `M2.2 2.31a5.21 5.21 0 0 1 7.6 0c1.9 1.99 2.13 5.73.5 8.02L6
16.84l-4.3-6.52c-1.63-2.28-1.4-6.02.5-8zm3.86 1.8A2 2 0 0 0 4.1 6.16a2 2 0 0 0 1.96 2.06A2 2 0 0 0 8 6.16a2
2 0 0 0-1.95-2.05z` as const;
export const circlePath = `M 15 9 A 6 6 0 0 1 9 15 A 6 6 0 0 1 3 9 A 6 6 0 0 1 9 3 A 6 6 0 0 1 15 9 Z` as const;

export const defaultLat = 59.93601 as const;
export const defaultLng = 30.325716 as const;
export const defaultCenter = { lat: defaultLat, lng: defaultLng } as const;

export const SIGNAL_STRENGTH = {
  WEAK: 'WEAK',
  OK: 'OK',
  STRONG: 'STRONG',
  NO_SIGNAL: 'NO_SIGNAL',
} as const;

export const SIGNAL_STRENGTH_LIMITS = {
  [SIGNAL_STRENGTH.WEAK]: [174, 105],
  [SIGNAL_STRENGTH.OK]: [105, 80],
  [SIGNAL_STRENGTH.STRONG]: [80, 60],
  [SIGNAL_STRENGTH.NO_SIGNAL]: 0,
} as const;

export const RSSI_COLORS = {
  [SIGNAL_STRENGTH.WEAK]: colorBase.red,
  [SIGNAL_STRENGTH.OK]: colorBase.yellow,
  [SIGNAL_STRENGTH.STRONG]: colorBase.green,
} as const;

export const MARKER_POSITIONS = {
  START: 'start',
  END: 'end',
} as const;
