import React, { FC, memo, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Empty } from 'antd';
import { compareIds, logger } from '@fjedi/graphql-react-components';
import { RestrictedArea, ViewerContext, useNavigate, useParams } from '@fjedi/react-router-helpers';
import DetailsDrawer from 'src/components/common/details-drawer';
import Spinner from 'src/components/ui-kit/spinner';
import LinkButton from 'src/components/ui-kit/buttons/link-button';
import OrganizationProfileCard from 'src/components/routes/private/admin/organizations/profile';
import OrganizationTable from 'src/components/routes/private/admin/organizations/organizations-table';
import { CompanyQueryVariables, useCompanyQuery, Viewer } from 'src/graphql/generated';
import DeleteButton from 'src/components/ui-kit/buttons/delete';

const OrganizationsWrapper = styled.div``;
const CreateOrganizationButton = styled(LinkButton).attrs({ size: 'large', className: 'create-button' })`
  &.ant-btn.create-button {
    margin-bottom: 1.2rem;
    border-radius: 0.5rem;
    font-size: 1rem;
    padding: 0 1rem;
  }
`;

const OrganizationsContainer: FC = () => {
  const { t } = useTranslation();
  const viewer = useContext(ViewerContext) as Viewer;
  const pageTitle = useMemo(() => t('Organizations List'), [t]);
  const params = useParams<{ organizationId: string }>();
  const navigate = useNavigate();

  const queryVariables: CompanyQueryVariables = { id: params.organizationId! };
  const queryRes = useCompanyQuery({
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !params.organizationId || params.organizationId === 'new',
    variables: queryVariables,
  });
  const { data, loading } = queryRes;
  const organization = data?.company;
  const organizationId = organization?.id;

  const onProfileCardClose = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  logger('params', { params });

  return (
    <OrganizationsWrapper>
      <Helmet>
        <title>{t(pageTitle)}</title>
      </Helmet>
      <RestrictedArea areaType="block" currentRole={viewer.role} allowedRoles={['ADMIN']}>
        <CreateOrganizationButton type="primary" to="/admin/organizations/new">
          {t('Create Organizations')}
        </CreateOrganizationButton>
      </RestrictedArea>
      <OrganizationTable />
      <DetailsDrawer
        title="Регистрация новой компании"
        width={570}
        onClose={onProfileCardClose}
        open={params.organizationId === 'new'}>
        <OrganizationProfileCard />
      </DetailsDrawer>
      <DetailsDrawer
        title={organization?.name ? `Профиль компании: ${organization?.name}` : null}
        width={570}
        onClose={onProfileCardClose}
        open={!!organizationId && compareIds(organizationId, params.organizationId)}>
        <Spinner spinning={loading}>
          {!loading && !organizationId && <Empty description="Не удалось получить данные об организации" />}
          {!loading && organizationId && (
            <OrganizationProfileCard organizationId={organizationId} data={organization} />
          )}
        </Spinner>
      </DetailsDrawer>
    </OrganizationsWrapper>
  );
};

OrganizationsContainer.displayName = 'OrganizationsContainer';

export default memo(OrganizationsContainer);
