import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStateHandlers, withHandlers, compose, pure } from 'recompose';
import { withTranslation } from 'react-i18next';
import { InboxOutlined } from '@ant-design/icons';
//
import { Button, Upload, Modal } from 'antd';
import Dragger from './dragger';
//
const FileUploader = ({
  t,
  loading,
  disabled,
  uploadFileModalStatus,
  closeUploadModal,
  openUploadModal,
  showPopup,
  beforeUpload,
  onRemove,
  fileList,
  children,
  multiple,
  renderSubmitButton,
}) => (
  <Fragment>
    {showPopup && (
      <Fragment>
        {(typeof children === 'function' && children({ disabled, loading, onClick: openUploadModal })) || (
          <Button disabled={disabled} loading={loading} onClick={openUploadModal} icon="upload" />
        )}
        <Modal
          visible={uploadFileModalStatus}
          footer={null}
          onCancel={closeUploadModal}
          width={485}
          bodyStyle={{
            padding: '42px',
          }}
          centered
          title={null}>
          <Dragger
            multiple={multiple}
            supportServerRender
            fileList={fileList}
            beforeUpload={beforeUpload}
            onRemove={onRemove}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('Click on this area or drag file(s) to it')}</p>
          </Dragger>
          {typeof renderSubmitButton === 'function' && renderSubmitButton({ closeModal: closeUploadModal })}
        </Modal>
      </Fragment>
    )}
    {!showPopup && (
      <Upload
        supportServerRender
        multiple={multiple}
        fileList={fileList}
        beforeUpload={beforeUpload}
        onRemove={onRemove}>
        {(typeof children === 'function' && children()) || <Button icon="upload" />}
      </Upload>
    )}
  </Fragment>
);
FileUploader.propTypes = {
  t: PropTypes.func.isRequired,
  closeUploadModal: PropTypes.func.isRequired,
  openUploadModal: PropTypes.func.isRequired,
  uploadFileModalStatus: PropTypes.bool.isRequired,
  beforeUpload: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  children: PropTypes.func,
  renderSubmitButton: PropTypes.func,
  showPopup: PropTypes.bool,
  multiple: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  fileList: PropTypes.array.isRequired,
};
FileUploader.defaultProps = {
  multiple: false,
  loading: false,
  disabled: false,
  showPopup: false,
  children: null,
  renderSubmitButton: null,
};

export default compose(
  withTranslation(),
  withStateHandlers(
    () => ({
      fileList: [],
      uploadFileModalStatus: false,
    }),
    {
      setState: () => state => state,
      closeUploadModal: () => () => ({
        uploadFileModalStatus: false,
        fileList: [],
      }),
      openUploadModal: () => () => ({
        uploadFileModalStatus: true,
      }),
    },
  ),
  withHandlers({
    beforeUpload:
      ({ fileList, setState, beforeUpload }) =>
      (file, list) => {
        const newFileList = [];
        if (Array.isArray(list) && list.length > 0) {
          list.forEach(item => {
            //
            if (!fileList.some(f => f.uid === item.uid)) {
              newFileList.push(item);
            }
          });
        }
        if (typeof beforeUpload === 'function') {
          beforeUpload(newFileList);
        }
        setState({
          fileList: newFileList,
        });
        return false;
      },
    onRemove:
      ({ fileList, setState, onRemove }) =>
      file => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        if (typeof onRemove === 'function') {
          onRemove(newFileList);
        }
        setState({
          fileList: newFileList,
        });
      },
  }),
  pure,
)(FileUploader);
