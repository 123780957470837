/* eslint-disable security/detect-object-injection */
import get from 'lodash/get';

export default function logger(message, props = {}) {
  const level = get(props, 'level', 'info');
  if (process.env.RUNTIME_ENV !== 'production') {
    if (!message) {
      console.error('Logger has received event without message', props);
      return;
    }
    if (message instanceof Error) {
      console.error(message, props);
      return;
    }
    console[level](message, props);
  }
}
