import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Date: any;
  DateTime: any;
  EmailAddress: any;
  IP: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  JSON: any;
  JSONObject: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  SanitizedString: any;
  SemVer: any;
  Time: any;
  URL: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  Upload: any;
};

export type Alias = {
  __typename?: 'Alias';
  subscriberAlias: Scalars['SanitizedString'];
  subscriberId: Scalars['ID'];
};

export type AliasInput = {
  subscriberAlias?: InputMaybe<Scalars['SanitizedString']>;
  subscriberId: Scalars['ID'];
};

export type AliasList = {
  __typename?: 'AliasList';
  count: Scalars['Int'];
  rows: Array<Alias>;
};

export type AliasListFilter = {
  subscriberId?: InputMaybe<Scalars['ID']>;
};

export type AuthSession = {
  __typename?: 'AuthSession';
  createdAt?: Maybe<Scalars['Date']>;
  expiredAt?: Maybe<Scalars['Date']>;
  ip?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
};

export type AuthSessionFilter = {
  ip?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userAgent?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type AuthSessionList = {
  __typename?: 'AuthSessionList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<AuthSession>>>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type Company = {
  __typename?: 'Company';
  comments?: Maybe<Scalars['SanitizedString']>;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  remoteId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type CompanyFilter = {
  createdAt?: InputMaybe<DateRange>;
  id?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Array<Scalars['SanitizedString']>>;
  remoteId?: InputMaybe<Array<Scalars['SanitizedString']>>;
};

export type CompanyList = {
  __typename?: 'CompanyList';
  count: Scalars['Int'];
  rows: Array<Company>;
};

export type ConfirmActionInput = {
  action: ConfirmActionType;
  token: Scalars['String'];
};

export enum ConfirmActionType {
  InvitationToCompany = 'INVITATION_TO_COMPANY'
}

export type Coords = {
  __typename?: 'Coords';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type CreateCompanyInput = {
  comments?: InputMaybe<Scalars['SanitizedString']>;
  name: Scalars['SanitizedString'];
  remoteId: Scalars['ID'];
};

export type CreateUserRoleInput = {
  companyId: Scalars['ID'];
  email?: InputMaybe<Scalars['EmailAddress']>;
  role: UserRoleRole;
  userId?: InputMaybe<Scalars['ID']>;
};

export type DateRange = {
  from?: InputMaybe<Scalars['Date']>;
  to?: InputMaybe<Scalars['Date']>;
};

export type DefaultResponse = {
  __typename?: 'DefaultResponse';
  code?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
  status?: Maybe<ResponseStatus>;
};

export type DisableGoogleAuthenticatorInput = {
  password: Scalars['String'];
};

export type EnableGoogleAuthenticatorInput = {
  code: Scalars['String'];
  secret: Scalars['String'];
};

export type EncodedLocationsData = {
  __typename?: 'EncodedLocationsData';
  dBm?: Maybe<Array<Scalars['Int']>>;
  location: Scalars['String'];
  signalStrength?: Maybe<Array<SignalStrength>>;
  weight?: Maybe<Array<Scalars['Float']>>;
};

export type File = {
  __typename?: 'File';
  filename?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mime?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  url: Scalars['URL'];
  userId?: Maybe<Scalars['ID']>;
};

export type GoogleAuthenticatorData = {
  __typename?: 'GoogleAuthenticatorData';
  qr: Scalars['String'];
  secret: Scalars['String'];
};

export type Location = {
  __typename?: 'Location';
  callingSsiId: Scalars['ID'];
  companyId: Scalars['ID'];
  dBm?: Maybe<Array<Scalars['Int']>>;
  id: Scalars['ID'];
  location: Scalars['String'];
  signalStrength?: Maybe<Array<SignalStrength>>;
  timestamp: LocationTimeRange;
  weight?: Maybe<Array<Scalars['Float']>>;
};

export type LocationFilter = {
  callingSsiId: Array<Scalars['ID']>;
  createdAt?: InputMaybe<DateRange>;
  locationUncertainty?: InputMaybe<Scalars['PositiveInt']>;
  remoteCompanyId: Array<Scalars['ID']>;
  roadsBinding?: InputMaybe<Scalars['Boolean']>;
  rssi?: InputMaybe<Scalars['Boolean']>;
  trackingMethod?: InputMaybe<TrackingMethod>;
};

export type LocationTime = {
  __typename?: 'LocationTime';
  minute?: Maybe<Scalars['Int']>;
  second?: Maybe<Scalars['Int']>;
};

export type LocationTimeRange = {
  __typename?: 'LocationTimeRange';
  end?: Maybe<Scalars['Date']>;
  start: Scalars['Date'];
};

export type LogServer = {
  __typename?: 'LogServer';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  nodeNumber?: Maybe<Scalars['Int']>;
  serverVersion?: Maybe<Scalars['String']>;
  updateTime?: Maybe<Scalars['Date']>;
};

export type LogServerList = {
  __typename?: 'LogServerList';
  count: Scalars['Int'];
  rows: Array<LogServer>;
};

export type LogServerListFilter = {
  description?: InputMaybe<Scalars['String']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  user?: Maybe<Viewer>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changePassword?: Maybe<DefaultResponse>;
  createCompany: Company;
  createNotification: Notification;
  createUser: User;
  createUserRole: UserRole;
  disableGoogleAuthenticator: Scalars['Boolean'];
  enableGoogleAuthenticator: Scalars['Boolean'];
  logIn: Viewer;
  logOut: RemovedEntry;
  login?: Maybe<LoginResponse>;
  removeAuthSession: AuthSession;
  removeCompany: RemovedEntry;
  removeUser: RemovedEntry;
  removeUserRole: RemovedEntry;
  requestPasswordReset?: Maybe<DefaultResponse>;
  resetPassword?: Maybe<User>;
  signUp?: Maybe<User>;
  updateAlias: Alias;
  updateCompany: Company;
  updateProfile: User;
  updateUser: User;
  updateUserRole: UserRole;
  verifyTotp?: Maybe<Viewer>;
  viewNotifications?: Maybe<Array<Scalars['ID']>>;
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationCreateNotificationArgs = {
  input: NotificationInput;
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationCreateUserRoleArgs = {
  input: CreateUserRoleInput;
};


export type MutationDisableGoogleAuthenticatorArgs = {
  input: DisableGoogleAuthenticatorInput;
};


export type MutationEnableGoogleAuthenticatorArgs = {
  input: EnableGoogleAuthenticatorInput;
};


export type MutationLogInArgs = {
  credentials: ViewerCredentialsInput;
};


export type MutationLoginArgs = {
  credentials: ViewerCredentialsInput;
  role?: InputMaybe<ViewerRole>;
};


export type MutationRemoveAuthSessionArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveUserArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveUserRoleArgs = {
  id: Scalars['ID'];
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['EmailAddress'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSignUpArgs = {
  credentials: ViewerCredentialsInput;
};


export type MutationUpdateAliasArgs = {
  input: AliasInput;
};


export type MutationUpdateCompanyArgs = {
  id: Scalars['ID'];
  input: UpdateCompanyInput;
};


export type MutationUpdateProfileArgs = {
  id: Scalars['ID'];
  input: UserInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  input: UserInput;
};


export type MutationUpdateUserRoleArgs = {
  id: Scalars['ID'];
  input: UpdateUserRoleInput;
};


export type MutationVerifyTotpArgs = {
  input: VerifyTotpInput;
};


export type MutationViewNotificationsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
};

export type Node = {
  __typename?: 'Node';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type NodeList = {
  __typename?: 'NodeList';
  count: Scalars['Int'];
  rows: Array<Node>;
};

export type NodeListFilter = {
  status?: InputMaybe<Scalars['Boolean']>;
};

export type NodeStatus = {
  __typename?: 'NodeStatus';
  alarmState?: Maybe<Scalars['Int']>;
  apiGwAlarmState?: Maybe<Scalars['Int']>;
  apiGwProtVer?: Maybe<Scalars['Int']>;
  apiGwRegisterAct?: Maybe<Scalars['Int']>;
  apiGwRegisterMax?: Maybe<Scalars['Int']>;
  apiGwStreamAct?: Maybe<Scalars['Int']>;
  apiGwStreamMax?: Maybe<Scalars['Int']>;
  bsc?: Maybe<Scalars['Int']>;
  commonAlarmState?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  hwType?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  ipAddrCount?: Maybe<Scalars['Int']>;
  nodeCount?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  organizationCount?: Maybe<Scalars['Int']>;
  pdGwAlarmState?: Maybe<Scalars['Int']>;
  profileCount?: Maybe<Scalars['Int']>;
  radioAlarmState?: Maybe<Scalars['Int']>;
  radioMainCarrier?: Maybe<Scalars['String']>;
  radioRegMsCount?: Maybe<Scalars['Int']>;
  radioTsCountBlock?: Maybe<Scalars['Int']>;
  radioTsCountGtch?: Maybe<Scalars['Int']>;
  radioTsCountIdle?: Maybe<Scalars['Int']>;
  radioTsCountItch?: Maybe<Scalars['Int']>;
  radioTsCountMcch?: Maybe<Scalars['Int']>;
  radioTsCountPdch?: Maybe<Scalars['Int']>;
  radioTsCountScch?: Maybe<Scalars['Int']>;
  radioTsCountSpare?: Maybe<Scalars['Int']>;
  radioTsCountTotal?: Maybe<Scalars['Int']>;
  redBscSubStat?: Maybe<Scalars['Int']>;
  softwareDate?: Maybe<Scalars['String']>;
  softwareVersion?: Maybe<Scalars['String']>;
  ssiCount?: Maybe<Scalars['Int']>;
  subCount?: Maybe<Scalars['Int']>;
  subRegAlarmState?: Maybe<Scalars['Int']>;
  subRegMode?: Maybe<Scalars['Int']>;
  termGwAlarmState?: Maybe<Scalars['Int']>;
  termGwRegisterAct?: Maybe<Scalars['Int']>;
  termGwRegisterMax?: Maybe<Scalars['Int']>;
  termGwStreamAct?: Maybe<Scalars['Int']>;
  termGwStreamMax?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['Date']>;
  userNoCount?: Maybe<Scalars['Int']>;
  voiceGwActCallCount?: Maybe<Scalars['Int']>;
  voiceGwAlarmState?: Maybe<Scalars['Int']>;
  voiceGwIdleCallCount?: Maybe<Scalars['Int']>;
};

export type NodeStatusList = {
  __typename?: 'NodeStatusList';
  count: Scalars['Int'];
  rows: Array<NodeStatus>;
};

export type NodeStatusListFilter = {
  tab?: InputMaybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  createdAt?: Maybe<Scalars['Date']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  props?: Maybe<Scalars['JSON']>;
  status?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  userId?: Maybe<Scalars['ID']>;
  viewed?: Maybe<Scalars['Boolean']>;
};

export type NotificationInput = {
  event?: InputMaybe<Scalars['String']>;
  props?: InputMaybe<Scalars['JSON']>;
  subject?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type NotificationList = {
  __typename?: 'NotificationList';
  count: Scalars['Int'];
  rows: Array<Notification>;
};

export type NotificationListFilter = {
  event?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  current: Scalars['NonNegativeInt'];
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  total: Scalars['NonNegativeInt'];
};

export type Pagination = {
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
};

export type Query = {
  __typename?: 'Query';
  aliases: AliasList;
  authSessions: AuthSessionList;
  companies: CompanyList;
  company: Company;
  locations: Array<Location>;
  logServers: LogServerList;
  nodeStatus: NodeStatus;
  nodeStatuses: NodeStatusList;
  nodes: NodeList;
  notifications: NotificationList;
  queryLogs: QueryLogList;
  setupGoogleAuthenticator: GoogleAuthenticatorData;
  subscribers: SubscriberList;
  user: User;
  userRoles: UserRoleList;
  users: UserList;
  viewer: Viewer;
};


export type QueryAliasesArgs = {
  filter?: InputMaybe<AliasListFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};


export type QueryAuthSessionsArgs = {
  filter?: InputMaybe<AuthSessionFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
  userId: Scalars['ID'];
};


export type QueryCompaniesArgs = {
  filter?: InputMaybe<CompanyFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryLocationsArgs = {
  filter: LocationFilter;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};


export type QueryLogServersArgs = {
  filter?: InputMaybe<LogServerListFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};


export type QueryNodeStatusArgs = {
  nodeNumber: Scalars['ID'];
};


export type QueryNodeStatusesArgs = {
  filter: NodeStatusListFilter;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};


export type QueryNodesArgs = {
  filter: NodeListFilter;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};


export type QueryNotificationsArgs = {
  filter: NotificationListFilter;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};


export type QueryQueryLogsArgs = {
  filter?: InputMaybe<QueryLogFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};


export type QuerySubscribersArgs = {
  filter?: InputMaybe<SubscriberListFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserRolesArgs = {
  filter?: InputMaybe<UserRoleFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};

export type QueryLog = {
  __typename?: 'QueryLog';
  createdAt?: Maybe<Scalars['Date']>;
  data?: Maybe<Scalars['JSON']>;
  headers?: Maybe<Scalars['JSON']>;
  id: Scalars['ID'];
  ip?: Maybe<Scalars['IP']>;
  operationName?: Maybe<Scalars['String']>;
  user?: Maybe<UserProfile>;
};

export type QueryLogFilter = {
  createdAt?: InputMaybe<DateRange>;
  operationName?: InputMaybe<Array<Scalars['SanitizedString']>>;
  userId?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryLogList = {
  __typename?: 'QueryLogList';
  count: Scalars['Int'];
  pageInfo: PageInfo;
  rows: Array<QueryLog>;
};

export type RemovedAlias = {
  __typename?: 'RemovedAlias';
  subscriberId: Scalars['ID'];
};

export type RemovedEntry = {
  __typename?: 'RemovedEntry';
  id: Scalars['ID'];
};

export enum ResponseStatus {
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export enum SignalStrength {
  NoSignal = 'NO_SIGNAL',
  Ok = 'OK',
  Strong = 'STRONG',
  Weak = 'WEAK'
}

export type Sort = {
  direction?: InputMaybe<SortDirection>;
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Subscriber = {
  __typename?: 'Subscriber';
  alias?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['ID']>;
  companyName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type SubscriberList = {
  __typename?: 'SubscriberList';
  count: Scalars['Int'];
  rows: Array<Subscriber>;
};

export type SubscriberListFilter = {
  alias?: InputMaybe<Array<Scalars['SanitizedString']>>;
  companyName?: InputMaybe<Array<Scalars['SanitizedString']>>;
  id?: InputMaybe<Array<Scalars['ID']>>;
  remoteCompanyId?: InputMaybe<Array<Scalars['ID']>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  locationCreated: Location;
  logServerChanged: LogServer;
  logServerCreated: LogServer;
  nodeStatusChanged: NodeStatus;
  nodeStatusCreated: NodeStatus;
  notificationChanged: Notification;
  notificationCreated: Notification;
  userProfileChanged: UserProfile;
  userRoleChanged: UserRole;
  userRoleCreated: UserRole;
  userRoleRemoved: RemovedEntry;
};


export type SubscriptionLocationCreatedArgs = {
  filter?: InputMaybe<LocationFilter>;
};


export type SubscriptionNotificationChangedArgs = {
  userId?: InputMaybe<Scalars['ID']>;
};


export type SubscriptionNotificationCreatedArgs = {
  userId?: InputMaybe<Scalars['ID']>;
};


export type SubscriptionUserRoleChangedArgs = {
  filter: UserRoleFilter;
};


export type SubscriptionUserRoleCreatedArgs = {
  filter: UserRoleFilter;
};


export type SubscriptionUserRoleRemovedArgs = {
  filter: UserRoleFilter;
};

export enum TrackingMethod {
  LivePoint = 'LIVE_POINT',
  LiveRoute = 'LIVE_ROUTE',
  Route = 'ROUTE'
}

export type UpdateCompanyInput = {
  comments?: InputMaybe<Scalars['SanitizedString']>;
  name?: InputMaybe<Scalars['SanitizedString']>;
  remoteId?: InputMaybe<Scalars['ID']>;
};

export type UpdateUserRoleInput = {
  role: UserRoleRole;
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<File>;
  beenOnlineAt?: Maybe<Scalars['Date']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['EmailAddress']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  gaEnabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isOnline?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  primaryCompany?: Maybe<Company>;
  primaryCompanyId?: Maybe<Scalars['ID']>;
  props?: Maybe<Scalars['JSON']>;
  role?: Maybe<ViewerRole>;
  roleInPrimaryCompany?: Maybe<UserRoleRole>;
};

export type UserFilter = {
  createdAt?: InputMaybe<DateRange>;
  email?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  firstName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lastName?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  role?: InputMaybe<ViewerRole>;
};

export type UserInput = {
  avatar?: InputMaybe<Scalars['Upload']>;
  comments?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['EmailAddress']>;
  firstName?: InputMaybe<Scalars['SanitizedString']>;
  lastName?: InputMaybe<Scalars['SanitizedString']>;
  middleName?: InputMaybe<Scalars['SanitizedString']>;
  password?: InputMaybe<Scalars['SanitizedString']>;
  phoneNumber?: InputMaybe<Scalars['PhoneNumber']>;
  primaryCompanyId?: InputMaybe<Scalars['ID']>;
  role?: InputMaybe<ViewerRole>;
  roleInPrimaryCompany?: InputMaybe<UserRoleRole>;
};

export type UserList = {
  __typename?: 'UserList';
  count: Scalars['Int'];
  rows: Array<User>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  avatar?: Maybe<File>;
  createdAt?: Maybe<Scalars['Date']>;
  email?: Maybe<Scalars['EmailAddress']>;
  firstName?: Maybe<Scalars['SanitizedString']>;
  fullName?: Maybe<Scalars['SanitizedString']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['SanitizedString']>;
  primaryCompany?: Maybe<Company>;
  primaryCompanyId?: Maybe<Scalars['ID']>;
  role?: Maybe<ViewerRole>;
  roleInPrimaryCompany?: Maybe<UserRoleRole>;
};

export type UserRole = {
  __typename?: 'UserRole';
  company: Company;
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  profile?: Maybe<UserProfile>;
  role: UserRoleRole;
  status: UserRoleStatus;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type UserRoleFilter = {
  companyId?: InputMaybe<Scalars['ID']>;
  role?: InputMaybe<UserRoleRole>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type UserRoleList = {
  __typename?: 'UserRoleList';
  count: Scalars['Int'];
  rows: Array<UserRole>;
};

export enum UserRoleRole {
  Admin = 'ADMIN',
  Operator = 'OPERATOR',
  User = 'USER'
}

export enum UserRoleStatus {
  Confirmed = 'CONFIRMED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type VerifyTotpInput = {
  code: Scalars['String'];
  userId: Scalars['ID'];
};

export type Viewer = {
  __typename?: 'Viewer';
  avatar?: Maybe<File>;
  beenOnlineAt?: Maybe<Scalars['Date']>;
  comments?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailAddress']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  gaEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  isOnline?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  primaryCompany?: Maybe<Company>;
  primaryCompanyId?: Maybe<Scalars['ID']>;
  props?: Maybe<Scalars['JSON']>;
  role: ViewerRole;
  roleInPrimaryCompany?: Maybe<UserRoleRole>;
  roles: Array<UserRole>;
};

export type ViewerCredentialsInput = {
  email: Scalars['EmailAddress'];
  password: Scalars['String'];
};

export enum ViewerRole {
  Admin = 'ADMIN',
  Operator = 'OPERATOR',
  User = 'USER'
}

export type AliasFragment = { __typename: 'Alias', subscriberId: string, subscriberAlias: any };

export type CompanyFragment = { __typename: 'Company', id: string, name: string, remoteId: string, comments?: any | null, createdAt?: any | null, updatedAt?: any | null };

export type FileFragment = { __typename: 'File', id: string, url: any, path: string, filename?: string | null, userId?: string | null };

export type GoogleAuthenticatorDataFragment = { __typename?: 'GoogleAuthenticatorData', qr: string, secret: string };

export type LocationFragment = { __typename: 'Location', id: string, callingSsiId: string, companyId: string, location: string, dBm?: Array<number> | null, weight?: Array<number> | null, signalStrength?: Array<SignalStrength> | null, timestamp: { __typename?: 'LocationTimeRange', start: any, end?: any | null } };

export type LogServerFragment = { __typename: 'LogServer', id?: string | null, nodeNumber?: number | null, description?: string | null, serverVersion?: string | null, updateTime?: any | null };

export type LoginResponseFragment = { __typename?: 'LoginResponse', user?: { __typename: 'Viewer', id: string, firstName?: string | null, lastName?: string | null, middleName?: string | null, fullName?: string | null, beenOnlineAt?: any | null, isOnline?: boolean | null, email?: any | null, phoneNumber?: string | null, role: ViewerRole, roleInPrimaryCompany?: UserRoleRole | null, gaEnabled: boolean, primaryCompany?: { __typename?: 'Company', id: string, name: string, remoteId: string } | null, roles: Array<{ __typename: 'UserRole', id: string, status: UserRoleStatus, role: UserRoleRole, createdAt?: any | null, updatedAt?: any | null, profile?: { __typename: 'UserProfile', id: string, email?: any | null, role?: ViewerRole | null, firstName?: any | null, lastName?: any | null, fullName?: any | null, avatar?: { __typename: 'File', id: string, url: any, path: string, filename?: string | null, userId?: string | null } | null } | null, company: { __typename: 'Company', id: string, name: string, remoteId: string, comments?: any | null, createdAt?: any | null, updatedAt?: any | null } }> } | null };

export type NodeStatusFragment = { __typename: 'NodeStatus', id: number, number?: number | null, bsc?: number | null, description?: string | null, hwType?: string | null, softwareVersion?: string | null, softwareDate?: string | null, nodeCount?: number | null, timestamp?: any | null, alarmState?: number | null, commonAlarmState?: number | null, subRegAlarmState?: number | null, radioAlarmState?: number | null, voiceGwAlarmState?: number | null, pdGwAlarmState?: number | null, apiGwAlarmState?: number | null, termGwAlarmState?: number | null, subRegMode?: number | null, redBscSubStat?: number | null, organizationCount?: number | null, profileCount?: number | null, subCount?: number | null, ssiCount?: number | null, userNoCount?: number | null, ipAddrCount?: number | null, radioRegMsCount?: number | null, radioMainCarrier?: string | null, radioTsCountIdle?: number | null, radioTsCountMcch?: number | null, radioTsCountScch?: number | null, radioTsCountItch?: number | null, radioTsCountGtch?: number | null, radioTsCountPdch?: number | null, radioTsCountBlock?: number | null, radioTsCountSpare?: number | null, radioTsCountTotal?: number | null, voiceGwActCallCount?: number | null, voiceGwIdleCallCount?: number | null, apiGwRegisterAct?: number | null, apiGwStreamAct?: number | null, apiGwRegisterMax?: number | null, apiGwStreamMax?: number | null, apiGwProtVer?: number | null, termGwRegisterAct?: number | null, termGwStreamAct?: number | null, termGwRegisterMax?: number | null, termGwStreamMax?: number | null };

export type NodeFragment = { __typename: 'Node', id?: string | null, number?: number | null, status?: boolean | null, description?: string | null };

export type NotificationFragment = { __typename: 'Notification', id?: string | null, subject?: string | null, text?: string | null, event?: string | null, status?: string | null, props?: any | null, viewed?: boolean | null, userId?: string | null, createdAt?: any | null, updatedAt?: any | null };

export type QueryLogFragment = { __typename: 'QueryLog', id: string, operationName?: string | null, data?: any | null, ip?: any | null, createdAt?: any | null, user?: { __typename: 'UserProfile', id: string, email?: any | null, role?: ViewerRole | null, firstName?: any | null, lastName?: any | null, fullName?: any | null, avatar?: { __typename: 'File', id: string, url: any, path: string, filename?: string | null, userId?: string | null } | null } | null };

export type SubscriberFragment = { __typename: 'Subscriber', id: string, companyId?: string | null, companyName?: string | null, alias?: string | null };

export type UserProfileFragment = { __typename: 'UserProfile', id: string, email?: any | null, role?: ViewerRole | null, firstName?: any | null, lastName?: any | null, fullName?: any | null, avatar?: { __typename: 'File', id: string, url: any, path: string, filename?: string | null, userId?: string | null } | null };

export type UserRoleFragment = { __typename: 'UserRole', id: string, status: UserRoleStatus, role: UserRoleRole, createdAt?: any | null, updatedAt?: any | null, profile?: { __typename: 'UserProfile', id: string, email?: any | null, role?: ViewerRole | null, firstName?: any | null, lastName?: any | null, fullName?: any | null, avatar?: { __typename: 'File', id: string, url: any, path: string, filename?: string | null, userId?: string | null } | null } | null, company: { __typename: 'Company', id: string, name: string, remoteId: string, comments?: any | null, createdAt?: any | null, updatedAt?: any | null } };

export type UserFragment = { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, middleName?: string | null, fullName?: string | null, role?: ViewerRole | null, primaryCompanyId?: string | null, roleInPrimaryCompany?: UserRoleRole | null, props?: any | null, email?: any | null, phoneNumber?: string | null, comments?: string | null, createdAt?: any | null, gaEnabled?: boolean | null };

export type ViewerFragment = { __typename: 'Viewer', id: string, firstName?: string | null, lastName?: string | null, middleName?: string | null, fullName?: string | null, beenOnlineAt?: any | null, isOnline?: boolean | null, email?: any | null, phoneNumber?: string | null, role: ViewerRole, roleInPrimaryCompany?: UserRoleRole | null, gaEnabled: boolean, primaryCompany?: { __typename?: 'Company', id: string, name: string, remoteId: string } | null, roles: Array<{ __typename: 'UserRole', id: string, status: UserRoleStatus, role: UserRoleRole, createdAt?: any | null, updatedAt?: any | null, profile?: { __typename: 'UserProfile', id: string, email?: any | null, role?: ViewerRole | null, firstName?: any | null, lastName?: any | null, fullName?: any | null, avatar?: { __typename: 'File', id: string, url: any, path: string, filename?: string | null, userId?: string | null } | null } | null, company: { __typename: 'Company', id: string, name: string, remoteId: string, comments?: any | null, createdAt?: any | null, updatedAt?: any | null } }> };

export type UpdateAliasMutationVariables = Exact<{
  input: AliasInput;
}>;


export type UpdateAliasMutation = { __typename?: 'Mutation', updateAlias: { __typename: 'Alias', subscriberId: string, subscriberAlias: any } };

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
  passwordConfirmation: Scalars['String'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword?: { __typename?: 'DefaultResponse', code?: number | null } | null };

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCompanyInput;
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', createCompany: { __typename: 'Company', id: string, name: string, remoteId: string, comments?: any | null, createdAt?: any | null, updatedAt?: any | null } };

export type RemoveCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveCompanyMutation = { __typename?: 'Mutation', removeCompany: { __typename: 'RemovedEntry', id: string } };

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateCompanyInput;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany: { __typename: 'Company', id: string, name: string, remoteId: string, comments?: any | null, createdAt?: any | null, updatedAt?: any | null } };

export type CreateNotificationMutationVariables = Exact<{
  input: NotificationInput;
}>;


export type CreateNotificationMutation = { __typename?: 'Mutation', createNotification: { __typename: 'Notification', id?: string | null, subject?: string | null, text?: string | null, event?: string | null, status?: string | null, props?: any | null, viewed?: boolean | null, userId?: string | null, createdAt?: any | null, updatedAt?: any | null } };

export type LogInMutationVariables = Exact<{
  credentials: ViewerCredentialsInput;
}>;


export type LogInMutation = { __typename?: 'Mutation', logIn: { __typename: 'Viewer', id: string, firstName?: string | null, lastName?: string | null, middleName?: string | null, fullName?: string | null, beenOnlineAt?: any | null, isOnline?: boolean | null, email?: any | null, phoneNumber?: string | null, role: ViewerRole, roleInPrimaryCompany?: UserRoleRole | null, gaEnabled: boolean, primaryCompany?: { __typename?: 'Company', id: string, name: string, remoteId: string } | null, roles: Array<{ __typename: 'UserRole', id: string, status: UserRoleStatus, role: UserRoleRole, createdAt?: any | null, updatedAt?: any | null, profile?: { __typename: 'UserProfile', id: string, email?: any | null, role?: ViewerRole | null, firstName?: any | null, lastName?: any | null, fullName?: any | null, avatar?: { __typename: 'File', id: string, url: any, path: string, filename?: string | null, userId?: string | null } | null } | null, company: { __typename: 'Company', id: string, name: string, remoteId: string, comments?: any | null, createdAt?: any | null, updatedAt?: any | null } }> } };

export type LogOutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogOutMutation = { __typename?: 'Mutation', logOut: { __typename: 'RemovedEntry', id: string } };

export type LoginMutationVariables = Exact<{
  credentials: ViewerCredentialsInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: { __typename?: 'LoginResponse', user?: { __typename: 'Viewer', id: string, firstName?: string | null, lastName?: string | null, middleName?: string | null, fullName?: string | null, beenOnlineAt?: any | null, isOnline?: boolean | null, email?: any | null, phoneNumber?: string | null, role: ViewerRole, roleInPrimaryCompany?: UserRoleRole | null, gaEnabled: boolean, primaryCompany?: { __typename?: 'Company', id: string, name: string, remoteId: string } | null, roles: Array<{ __typename: 'UserRole', id: string, status: UserRoleStatus, role: UserRoleRole, createdAt?: any | null, updatedAt?: any | null, profile?: { __typename: 'UserProfile', id: string, email?: any | null, role?: ViewerRole | null, firstName?: any | null, lastName?: any | null, fullName?: any | null, avatar?: { __typename: 'File', id: string, url: any, path: string, filename?: string | null, userId?: string | null } | null } | null, company: { __typename: 'Company', id: string, name: string, remoteId: string, comments?: any | null, createdAt?: any | null, updatedAt?: any | null } }> } | null } | null };

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars['EmailAddress'];
}>;


export type RequestPasswordResetMutation = { __typename?: 'Mutation', requestPasswordReset?: { __typename?: 'DefaultResponse', code?: number | null, status?: ResponseStatus | null } | null };

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, middleName?: string | null, fullName?: string | null, role?: ViewerRole | null, primaryCompanyId?: string | null, roleInPrimaryCompany?: UserRoleRole | null, props?: any | null, email?: any | null, phoneNumber?: string | null, comments?: string | null, createdAt?: any | null, gaEnabled?: boolean | null } | null };

export type SignUpMutationVariables = Exact<{
  credentials: ViewerCredentialsInput;
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUp?: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, middleName?: string | null, fullName?: string | null, role?: ViewerRole | null, primaryCompanyId?: string | null, roleInPrimaryCompany?: UserRoleRole | null, props?: any | null, email?: any | null, phoneNumber?: string | null, comments?: string | null, createdAt?: any | null, gaEnabled?: boolean | null } | null };

export type DisableGoogleAuthenticatorMutationVariables = Exact<{
  input: DisableGoogleAuthenticatorInput;
}>;


export type DisableGoogleAuthenticatorMutation = { __typename?: 'Mutation', disableGoogleAuthenticator: boolean };

export type EnableGoogleAuthenticatorMutationVariables = Exact<{
  input: EnableGoogleAuthenticatorInput;
}>;


export type EnableGoogleAuthenticatorMutation = { __typename?: 'Mutation', enableGoogleAuthenticator: boolean };

export type VerifyTotpMutationVariables = Exact<{
  input: VerifyTotpInput;
}>;


export type VerifyTotpMutation = { __typename?: 'Mutation', verifyTotp?: { __typename: 'Viewer', id: string, firstName?: string | null, lastName?: string | null, middleName?: string | null, fullName?: string | null, beenOnlineAt?: any | null, isOnline?: boolean | null, email?: any | null, phoneNumber?: string | null, role: ViewerRole, roleInPrimaryCompany?: UserRoleRole | null, gaEnabled: boolean, primaryCompany?: { __typename?: 'Company', id: string, name: string, remoteId: string } | null, roles: Array<{ __typename: 'UserRole', id: string, status: UserRoleStatus, role: UserRoleRole, createdAt?: any | null, updatedAt?: any | null, profile?: { __typename: 'UserProfile', id: string, email?: any | null, role?: ViewerRole | null, firstName?: any | null, lastName?: any | null, fullName?: any | null, avatar?: { __typename: 'File', id: string, url: any, path: string, filename?: string | null, userId?: string | null } | null } | null, company: { __typename: 'Company', id: string, name: string, remoteId: string, comments?: any | null, createdAt?: any | null, updatedAt?: any | null } }> } | null };

export type CreateUserRoleMutationVariables = Exact<{
  input: CreateUserRoleInput;
}>;


export type CreateUserRoleMutation = { __typename?: 'Mutation', createUserRole: { __typename: 'UserRole', id: string, status: UserRoleStatus, role: UserRoleRole, createdAt?: any | null, updatedAt?: any | null, profile?: { __typename: 'UserProfile', id: string, email?: any | null, role?: ViewerRole | null, firstName?: any | null, lastName?: any | null, fullName?: any | null, avatar?: { __typename: 'File', id: string, url: any, path: string, filename?: string | null, userId?: string | null } | null } | null, company: { __typename: 'Company', id: string, name: string, remoteId: string, comments?: any | null, createdAt?: any | null, updatedAt?: any | null } } };

export type RemoveUserRoleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveUserRoleMutation = { __typename?: 'Mutation', removeUserRole: { __typename: 'RemovedEntry', id: string } };

export type UpdateUserRoleMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateUserRoleInput;
}>;


export type UpdateUserRoleMutation = { __typename?: 'Mutation', updateUserRole: { __typename: 'UserRole', id: string, status: UserRoleStatus, role: UserRoleRole, createdAt?: any | null, updatedAt?: any | null, profile?: { __typename: 'UserProfile', id: string, email?: any | null, role?: ViewerRole | null, firstName?: any | null, lastName?: any | null, fullName?: any | null, avatar?: { __typename: 'File', id: string, url: any, path: string, filename?: string | null, userId?: string | null } | null } | null, company: { __typename: 'Company', id: string, name: string, remoteId: string, comments?: any | null, createdAt?: any | null, updatedAt?: any | null } } };

export type RemoveAuthSessionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveAuthSessionMutation = { __typename?: 'Mutation', removeAuthSession: { __typename: 'AuthSession', token?: string | null } };

export type CreateUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, middleName?: string | null, fullName?: string | null, role?: ViewerRole | null, primaryCompanyId?: string | null, roleInPrimaryCompany?: UserRoleRole | null, props?: any | null, email?: any | null, phoneNumber?: string | null, comments?: string | null, createdAt?: any | null, gaEnabled?: boolean | null } };

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveUserMutation = { __typename?: 'Mutation', removeUser: { __typename: 'RemovedEntry', id: string } };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, middleName?: string | null, fullName?: string | null, role?: ViewerRole | null, primaryCompanyId?: string | null, roleInPrimaryCompany?: UserRoleRole | null, props?: any | null, email?: any | null, phoneNumber?: string | null, comments?: string | null, createdAt?: any | null, gaEnabled?: boolean | null } };

export type ViewNotificationsMutationVariables = Exact<{
  ids: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>;
}>;


export type ViewNotificationsMutation = { __typename?: 'Mutation', viewNotifications?: Array<string> | null };

export type AliasesQueryVariables = Exact<{
  filter?: InputMaybe<AliasListFilter>;
  sort?: InputMaybe<Sort>;
  pagination?: InputMaybe<Pagination>;
}>;


export type AliasesQuery = { __typename?: 'Query', aliases: { __typename: 'AliasList', count: number, rows: Array<{ __typename: 'Alias', subscriberId: string, subscriberAlias: any }> } };

export type AuthSessionsQueryVariables = Exact<{
  userId: Scalars['ID'];
  filter?: InputMaybe<AuthSessionFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
}>;


export type AuthSessionsQuery = { __typename?: 'Query', authSessions: { __typename: 'AuthSessionList', count?: number | null, rows?: Array<{ __typename?: 'AuthSession', token?: string | null, ip?: string | null, userAgent?: string | null, createdAt?: any | null, expiredAt?: any | null } | null> | null } };

export type CompaniesQueryVariables = Exact<{
  filter?: InputMaybe<CompanyFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
}>;


export type CompaniesQuery = { __typename?: 'Query', companies: { __typename: 'CompanyList', count: number, rows: Array<{ __typename: 'Company', id: string, name: string, remoteId: string, comments?: any | null, createdAt?: any | null, updatedAt?: any | null }> } };

export type CompanyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompanyQuery = { __typename?: 'Query', company: { __typename: 'Company', id: string, name: string, remoteId: string, comments?: any | null, createdAt?: any | null, updatedAt?: any | null } };

export type LocationsQueryVariables = Exact<{
  filter: LocationFilter;
  sort?: InputMaybe<Sort>;
  pagination?: InputMaybe<Pagination>;
}>;


export type LocationsQuery = { __typename?: 'Query', locations: Array<{ __typename: 'Location', id: string, callingSsiId: string, companyId: string, location: string, dBm?: Array<number> | null, weight?: Array<number> | null, signalStrength?: Array<SignalStrength> | null, timestamp: { __typename?: 'LocationTimeRange', start: any, end?: any | null } }> };

export type LogServersQueryVariables = Exact<{
  filter?: InputMaybe<LogServerListFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
}>;


export type LogServersQuery = { __typename?: 'Query', logServers: { __typename: 'LogServerList', count: number, rows: Array<{ __typename: 'LogServer', id?: string | null, nodeNumber?: number | null, description?: string | null, serverVersion?: string | null, updateTime?: any | null }> } };

export type NodeStatusesQueryVariables = Exact<{
  filter: NodeStatusListFilter;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
}>;


export type NodeStatusesQuery = { __typename?: 'Query', nodeStatuses: { __typename: 'NodeStatusList', count: number, rows: Array<{ __typename: 'NodeStatus', id: number, number?: number | null, bsc?: number | null, description?: string | null, hwType?: string | null, softwareVersion?: string | null, softwareDate?: string | null, nodeCount?: number | null, timestamp?: any | null, alarmState?: number | null, commonAlarmState?: number | null, subRegAlarmState?: number | null, radioAlarmState?: number | null, voiceGwAlarmState?: number | null, pdGwAlarmState?: number | null, apiGwAlarmState?: number | null, termGwAlarmState?: number | null, subRegMode?: number | null, redBscSubStat?: number | null, organizationCount?: number | null, profileCount?: number | null, subCount?: number | null, ssiCount?: number | null, userNoCount?: number | null, ipAddrCount?: number | null, radioRegMsCount?: number | null, radioMainCarrier?: string | null, radioTsCountIdle?: number | null, radioTsCountMcch?: number | null, radioTsCountScch?: number | null, radioTsCountItch?: number | null, radioTsCountGtch?: number | null, radioTsCountPdch?: number | null, radioTsCountBlock?: number | null, radioTsCountSpare?: number | null, radioTsCountTotal?: number | null, voiceGwActCallCount?: number | null, voiceGwIdleCallCount?: number | null, apiGwRegisterAct?: number | null, apiGwStreamAct?: number | null, apiGwRegisterMax?: number | null, apiGwStreamMax?: number | null, apiGwProtVer?: number | null, termGwRegisterAct?: number | null, termGwStreamAct?: number | null, termGwRegisterMax?: number | null, termGwStreamMax?: number | null }> } };

export type NodesQueryVariables = Exact<{
  filter: NodeListFilter;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
}>;


export type NodesQuery = { __typename?: 'Query', nodes: { __typename: 'NodeList', count: number, rows: Array<{ __typename: 'Node', id?: string | null, number?: number | null, status?: boolean | null, description?: string | null }> } };

export type NotificationsQueryVariables = Exact<{
  filter: NotificationListFilter;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
}>;


export type NotificationsQuery = { __typename?: 'Query', notifications: { __typename: 'NotificationList', count: number, rows: Array<{ __typename: 'Notification', id?: string | null, subject?: string | null, text?: string | null, event?: string | null, status?: string | null, props?: any | null, viewed?: boolean | null, userId?: string | null, createdAt?: any | null, updatedAt?: any | null }> } };

export type QueryLogsQueryVariables = Exact<{
  filter?: InputMaybe<QueryLogFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
}>;


export type QueryLogsQuery = { __typename?: 'Query', queryLogs: { __typename: 'QueryLogList', count: number, rows: Array<{ __typename: 'QueryLog', id: string, operationName?: string | null, data?: any | null, ip?: any | null, createdAt?: any | null, user?: { __typename: 'UserProfile', id: string, email?: any | null, role?: ViewerRole | null, firstName?: any | null, lastName?: any | null, fullName?: any | null, avatar?: { __typename: 'File', id: string, url: any, path: string, filename?: string | null, userId?: string | null } | null } | null }>, pageInfo: { __typename?: 'PageInfo', current: any, total: any } } };

export type SubscribersQueryVariables = Exact<{
  filter?: InputMaybe<SubscriberListFilter>;
  sort?: InputMaybe<Sort>;
  pagination?: InputMaybe<Pagination>;
}>;


export type SubscribersQuery = { __typename?: 'Query', subscribers: { __typename: 'SubscriberList', count: number, rows: Array<{ __typename: 'Subscriber', id: string, companyId?: string | null, companyName?: string | null, alias?: string | null }> } };

export type UserRolesQueryVariables = Exact<{
  filter?: InputMaybe<UserRoleFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
}>;


export type UserRolesQuery = { __typename?: 'Query', userRoles: { __typename: 'UserRoleList', count: number, rows: Array<{ __typename: 'UserRole', id: string, status: UserRoleStatus, role: UserRoleRole, createdAt?: any | null, updatedAt?: any | null, profile?: { __typename: 'UserProfile', id: string, email?: any | null, role?: ViewerRole | null, firstName?: any | null, lastName?: any | null, fullName?: any | null, avatar?: { __typename: 'File', id: string, url: any, path: string, filename?: string | null, userId?: string | null } | null } | null, company: { __typename: 'Company', id: string, name: string, remoteId: string, comments?: any | null, createdAt?: any | null, updatedAt?: any | null } }> } };

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = { __typename?: 'Query', user: { __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, middleName?: string | null, fullName?: string | null, role?: ViewerRole | null, primaryCompanyId?: string | null, roleInPrimaryCompany?: UserRoleRole | null, props?: any | null, email?: any | null, phoneNumber?: string | null, comments?: string | null, createdAt?: any | null, gaEnabled?: boolean | null } };

export type UsersQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename: 'UserList', count: number, rows: Array<{ __typename: 'User', id: string, firstName?: string | null, lastName?: string | null, middleName?: string | null, fullName?: string | null, role?: ViewerRole | null, primaryCompanyId?: string | null, roleInPrimaryCompany?: UserRoleRole | null, props?: any | null, email?: any | null, phoneNumber?: string | null, comments?: string | null, createdAt?: any | null, gaEnabled?: boolean | null }> } };

export type SetupGoogleAuthenticatorQueryVariables = Exact<{ [key: string]: never; }>;


export type SetupGoogleAuthenticatorQuery = { __typename?: 'Query', setupGoogleAuthenticator: { __typename?: 'GoogleAuthenticatorData', qr: string, secret: string } };

export type ViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerQuery = { __typename?: 'Query', viewer: { __typename: 'Viewer', id: string, firstName?: string | null, lastName?: string | null, middleName?: string | null, fullName?: string | null, beenOnlineAt?: any | null, isOnline?: boolean | null, email?: any | null, phoneNumber?: string | null, role: ViewerRole, roleInPrimaryCompany?: UserRoleRole | null, gaEnabled: boolean, primaryCompany?: { __typename?: 'Company', id: string, name: string, remoteId: string } | null, roles: Array<{ __typename: 'UserRole', id: string, status: UserRoleStatus, role: UserRoleRole, createdAt?: any | null, updatedAt?: any | null, profile?: { __typename: 'UserProfile', id: string, email?: any | null, role?: ViewerRole | null, firstName?: any | null, lastName?: any | null, fullName?: any | null, avatar?: { __typename: 'File', id: string, url: any, path: string, filename?: string | null, userId?: string | null } | null } | null, company: { __typename: 'Company', id: string, name: string, remoteId: string, comments?: any | null, createdAt?: any | null, updatedAt?: any | null } }> } };

export type LocationCreatedSubscriptionVariables = Exact<{
  filter: LocationFilter;
}>;


export type LocationCreatedSubscription = { __typename?: 'Subscription', locationCreated: { __typename: 'Location', id: string, callingSsiId: string, companyId: string, location: string, dBm?: Array<number> | null, weight?: Array<number> | null, signalStrength?: Array<SignalStrength> | null, timestamp: { __typename?: 'LocationTimeRange', start: any, end?: any | null } } };

export type LogServerChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type LogServerChangedSubscription = { __typename?: 'Subscription', logServerChanged: { __typename: 'LogServer', id?: string | null, nodeNumber?: number | null, description?: string | null, serverVersion?: string | null, updateTime?: any | null } };

export type LogServerCreatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type LogServerCreatedSubscription = { __typename?: 'Subscription', logServerCreated: { __typename: 'LogServer', id?: string | null, nodeNumber?: number | null, description?: string | null, serverVersion?: string | null, updateTime?: any | null } };

export type NodeStatusChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NodeStatusChangedSubscription = { __typename?: 'Subscription', nodeStatusChanged: { __typename: 'NodeStatus', id: number, number?: number | null, bsc?: number | null, description?: string | null, hwType?: string | null, softwareVersion?: string | null, softwareDate?: string | null, nodeCount?: number | null, timestamp?: any | null, alarmState?: number | null, commonAlarmState?: number | null, subRegAlarmState?: number | null, radioAlarmState?: number | null, voiceGwAlarmState?: number | null, pdGwAlarmState?: number | null, apiGwAlarmState?: number | null, termGwAlarmState?: number | null, subRegMode?: number | null, redBscSubStat?: number | null, organizationCount?: number | null, profileCount?: number | null, subCount?: number | null, ssiCount?: number | null, userNoCount?: number | null, ipAddrCount?: number | null, radioRegMsCount?: number | null, radioMainCarrier?: string | null, radioTsCountIdle?: number | null, radioTsCountMcch?: number | null, radioTsCountScch?: number | null, radioTsCountItch?: number | null, radioTsCountGtch?: number | null, radioTsCountPdch?: number | null, radioTsCountBlock?: number | null, radioTsCountSpare?: number | null, radioTsCountTotal?: number | null, voiceGwActCallCount?: number | null, voiceGwIdleCallCount?: number | null, apiGwRegisterAct?: number | null, apiGwStreamAct?: number | null, apiGwRegisterMax?: number | null, apiGwStreamMax?: number | null, apiGwProtVer?: number | null, termGwRegisterAct?: number | null, termGwStreamAct?: number | null, termGwRegisterMax?: number | null, termGwStreamMax?: number | null } };

export type NodeStatusCreatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NodeStatusCreatedSubscription = { __typename?: 'Subscription', nodeStatusCreated: { __typename: 'NodeStatus', id: number, number?: number | null, bsc?: number | null, description?: string | null, hwType?: string | null, softwareVersion?: string | null, softwareDate?: string | null, nodeCount?: number | null, timestamp?: any | null, alarmState?: number | null, commonAlarmState?: number | null, subRegAlarmState?: number | null, radioAlarmState?: number | null, voiceGwAlarmState?: number | null, pdGwAlarmState?: number | null, apiGwAlarmState?: number | null, termGwAlarmState?: number | null, subRegMode?: number | null, redBscSubStat?: number | null, organizationCount?: number | null, profileCount?: number | null, subCount?: number | null, ssiCount?: number | null, userNoCount?: number | null, ipAddrCount?: number | null, radioRegMsCount?: number | null, radioMainCarrier?: string | null, radioTsCountIdle?: number | null, radioTsCountMcch?: number | null, radioTsCountScch?: number | null, radioTsCountItch?: number | null, radioTsCountGtch?: number | null, radioTsCountPdch?: number | null, radioTsCountBlock?: number | null, radioTsCountSpare?: number | null, radioTsCountTotal?: number | null, voiceGwActCallCount?: number | null, voiceGwIdleCallCount?: number | null, apiGwRegisterAct?: number | null, apiGwStreamAct?: number | null, apiGwRegisterMax?: number | null, apiGwStreamMax?: number | null, apiGwProtVer?: number | null, termGwRegisterAct?: number | null, termGwStreamAct?: number | null, termGwRegisterMax?: number | null, termGwStreamMax?: number | null } };

export type NotificationChangedSubscriptionVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']>;
}>;


export type NotificationChangedSubscription = { __typename?: 'Subscription', notificationChanged: { __typename: 'Notification', id?: string | null, subject?: string | null, text?: string | null, event?: string | null, status?: string | null, props?: any | null, viewed?: boolean | null, userId?: string | null, createdAt?: any | null, updatedAt?: any | null } };

export type NotificationCreatedSubscriptionVariables = Exact<{
  userId?: InputMaybe<Scalars['ID']>;
}>;


export type NotificationCreatedSubscription = { __typename?: 'Subscription', notificationCreated: { __typename: 'Notification', id?: string | null, subject?: string | null, text?: string | null, event?: string | null, status?: string | null, props?: any | null, viewed?: boolean | null, userId?: string | null, createdAt?: any | null, updatedAt?: any | null } };

export type UserProfileChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserProfileChangedSubscription = { __typename?: 'Subscription', userProfileChanged: { __typename: 'UserProfile', id: string, email?: any | null, role?: ViewerRole | null, firstName?: any | null, lastName?: any | null, fullName?: any | null, avatar?: { __typename: 'File', id: string, url: any, path: string, filename?: string | null, userId?: string | null } | null } };

export const AliasFragmentDoc = gql`
    fragment Alias on Alias {
  __typename
  subscriberId
  subscriberAlias
}
    `;
export const GoogleAuthenticatorDataFragmentDoc = gql`
    fragment GoogleAuthenticatorData on GoogleAuthenticatorData {
  qr
  secret
}
    `;
export const LocationFragmentDoc = gql`
    fragment Location on Location {
  __typename
  id
  callingSsiId
  companyId
  timestamp {
    start
    end
  }
  location
  dBm
  weight
  signalStrength
}
    `;
export const LogServerFragmentDoc = gql`
    fragment LogServer on LogServer {
  __typename
  id
  nodeNumber
  description
  serverVersion
  updateTime
}
    `;
export const FileFragmentDoc = gql`
    fragment File on File {
  __typename
  id
  url
  path
  filename
  userId
}
    `;
export const UserProfileFragmentDoc = gql`
    fragment UserProfile on UserProfile {
  __typename
  id
  email
  role
  firstName
  lastName
  fullName
  avatar {
    ...File
  }
}
    ${FileFragmentDoc}`;
export const CompanyFragmentDoc = gql`
    fragment Company on Company {
  __typename
  id
  name
  remoteId
  comments
  createdAt
  updatedAt
}
    `;
export const UserRoleFragmentDoc = gql`
    fragment UserRole on UserRole {
  __typename
  id
  status
  role
  profile {
    ...UserProfile
  }
  company {
    ...Company
  }
  createdAt
  updatedAt
}
    ${UserProfileFragmentDoc}
${CompanyFragmentDoc}`;
export const ViewerFragmentDoc = gql`
    fragment Viewer on Viewer {
  __typename
  id
  firstName
  lastName
  middleName
  fullName
  beenOnlineAt
  isOnline
  email
  phoneNumber
  role
  roleInPrimaryCompany
  gaEnabled
  primaryCompany {
    id
    name
    remoteId
  }
  roles {
    ...UserRole
  }
}
    ${UserRoleFragmentDoc}`;
export const LoginResponseFragmentDoc = gql`
    fragment LoginResponse on LoginResponse {
  user {
    ...Viewer
  }
}
    ${ViewerFragmentDoc}`;
export const NodeStatusFragmentDoc = gql`
    fragment NodeStatus on NodeStatus {
  __typename
  id
  number
  bsc
  description
  hwType
  softwareVersion
  softwareDate
  nodeCount
  timestamp
  alarmState
  commonAlarmState
  subRegAlarmState
  radioAlarmState
  voiceGwAlarmState
  pdGwAlarmState
  apiGwAlarmState
  termGwAlarmState
  subRegMode
  subRegAlarmState
  redBscSubStat
  organizationCount
  profileCount
  subCount
  ssiCount
  userNoCount
  ipAddrCount
  radioRegMsCount
  radioMainCarrier
  radioTsCountIdle
  radioTsCountMcch
  radioTsCountScch
  radioTsCountItch
  radioTsCountGtch
  radioTsCountPdch
  radioTsCountBlock
  radioTsCountSpare
  radioTsCountTotal
  voiceGwActCallCount
  voiceGwIdleCallCount
  apiGwRegisterAct
  apiGwStreamAct
  apiGwRegisterMax
  apiGwStreamMax
  apiGwProtVer
  termGwRegisterAct
  termGwStreamAct
  termGwRegisterMax
  termGwStreamMax
}
    `;
export const NodeFragmentDoc = gql`
    fragment Node on Node {
  __typename
  id
  number
  status
  description
}
    `;
export const NotificationFragmentDoc = gql`
    fragment Notification on Notification {
  __typename
  id
  subject
  text
  event
  status
  props
  viewed
  userId
  createdAt
  updatedAt
}
    `;
export const QueryLogFragmentDoc = gql`
    fragment QueryLog on QueryLog {
  __typename
  id
  operationName
  data
  ip
  createdAt
  user {
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}`;
export const SubscriberFragmentDoc = gql`
    fragment Subscriber on Subscriber {
  __typename
  id
  companyId
  companyName
  alias
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  __typename
  id
  firstName
  lastName
  middleName
  fullName
  role
  primaryCompanyId
  roleInPrimaryCompany
  props
  email
  phoneNumber
  comments
  createdAt
  gaEnabled
}
    `;
export const UpdateAliasDocument = gql`
    mutation updateAlias($input: AliasInput!) {
  updateAlias(input: $input) {
    ...Alias
  }
}
    ${AliasFragmentDoc}`;
export type UpdateAliasMutationFn = Apollo.MutationFunction<UpdateAliasMutation, UpdateAliasMutationVariables>;

/**
 * __useUpdateAliasMutation__
 *
 * To run a mutation, you first call `useUpdateAliasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAliasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAliasMutation, { data, loading, error }] = useUpdateAliasMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAliasMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAliasMutation, UpdateAliasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAliasMutation, UpdateAliasMutationVariables>(UpdateAliasDocument, options);
      }
export type UpdateAliasMutationHookResult = ReturnType<typeof useUpdateAliasMutation>;
export type UpdateAliasMutationResult = Apollo.MutationResult<UpdateAliasMutation>;
export type UpdateAliasMutationOptions = Apollo.BaseMutationOptions<UpdateAliasMutation, UpdateAliasMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($oldPassword: String!, $newPassword: String!, $passwordConfirmation: String!) {
  changePassword(
    oldPassword: $oldPassword
    newPassword: $newPassword
    passwordConfirmation: $passwordConfirmation
  ) {
    code
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const RemoveCompanyDocument = gql`
    mutation removeCompany($id: ID!) {
  removeCompany(id: $id) {
    __typename
    id
  }
}
    `;
export type RemoveCompanyMutationFn = Apollo.MutationFunction<RemoveCompanyMutation, RemoveCompanyMutationVariables>;

/**
 * __useRemoveCompanyMutation__
 *
 * To run a mutation, you first call `useRemoveCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompanyMutation, { data, loading, error }] = useRemoveCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCompanyMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCompanyMutation, RemoveCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCompanyMutation, RemoveCompanyMutationVariables>(RemoveCompanyDocument, options);
      }
export type RemoveCompanyMutationHookResult = ReturnType<typeof useRemoveCompanyMutation>;
export type RemoveCompanyMutationResult = Apollo.MutationResult<RemoveCompanyMutation>;
export type RemoveCompanyMutationOptions = Apollo.BaseMutationOptions<RemoveCompanyMutation, RemoveCompanyMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($id: ID!, $input: UpdateCompanyInput!) {
  updateCompany(id: $id, input: $input) {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const CreateNotificationDocument = gql`
    mutation createNotification($input: NotificationInput!) {
  createNotification(input: $input) {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;
export type CreateNotificationMutationFn = Apollo.MutationFunction<CreateNotificationMutation, CreateNotificationMutationVariables>;

/**
 * __useCreateNotificationMutation__
 *
 * To run a mutation, you first call `useCreateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationMutation, { data, loading, error }] = useCreateNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<CreateNotificationMutation, CreateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNotificationMutation, CreateNotificationMutationVariables>(CreateNotificationDocument, options);
      }
export type CreateNotificationMutationHookResult = ReturnType<typeof useCreateNotificationMutation>;
export type CreateNotificationMutationResult = Apollo.MutationResult<CreateNotificationMutation>;
export type CreateNotificationMutationOptions = Apollo.BaseMutationOptions<CreateNotificationMutation, CreateNotificationMutationVariables>;
export const LogInDocument = gql`
    mutation logIn($credentials: ViewerCredentialsInput!) {
  logIn(credentials: $credentials) {
    ...Viewer
  }
}
    ${ViewerFragmentDoc}`;
export type LogInMutationFn = Apollo.MutationFunction<LogInMutation, LogInMutationVariables>;

/**
 * __useLogInMutation__
 *
 * To run a mutation, you first call `useLogInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logInMutation, { data, loading, error }] = useLogInMutation({
 *   variables: {
 *      credentials: // value for 'credentials'
 *   },
 * });
 */
export function useLogInMutation(baseOptions?: Apollo.MutationHookOptions<LogInMutation, LogInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogInMutation, LogInMutationVariables>(LogInDocument, options);
      }
export type LogInMutationHookResult = ReturnType<typeof useLogInMutation>;
export type LogInMutationResult = Apollo.MutationResult<LogInMutation>;
export type LogInMutationOptions = Apollo.BaseMutationOptions<LogInMutation, LogInMutationVariables>;
export const LogOutDocument = gql`
    mutation logOut {
  logOut {
    __typename
    id
  }
}
    `;
export type LogOutMutationFn = Apollo.MutationFunction<LogOutMutation, LogOutMutationVariables>;

/**
 * __useLogOutMutation__
 *
 * To run a mutation, you first call `useLogOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logOutMutation, { data, loading, error }] = useLogOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogOutMutation(baseOptions?: Apollo.MutationHookOptions<LogOutMutation, LogOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogOutMutation, LogOutMutationVariables>(LogOutDocument, options);
      }
export type LogOutMutationHookResult = ReturnType<typeof useLogOutMutation>;
export type LogOutMutationResult = Apollo.MutationResult<LogOutMutation>;
export type LogOutMutationOptions = Apollo.BaseMutationOptions<LogOutMutation, LogOutMutationVariables>;
export const LoginDocument = gql`
    mutation login($credentials: ViewerCredentialsInput!) {
  login(credentials: $credentials) {
    ...LoginResponse
  }
}
    ${LoginResponseFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      credentials: // value for 'credentials'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RequestPasswordResetDocument = gql`
    mutation requestPasswordReset($email: EmailAddress!) {
  requestPasswordReset(email: $email) {
    code
    status
  }
}
    `;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, options);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($token: String!, $password: String!, $passwordConfirmation: String!) {
  resetPassword(
    token: $token
    password: $password
    passwordConfirmation: $passwordConfirmation
  ) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SignUpDocument = gql`
    mutation signUp($credentials: ViewerCredentialsInput!) {
  signUp(credentials: $credentials) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      credentials: // value for 'credentials'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const DisableGoogleAuthenticatorDocument = gql`
    mutation disableGoogleAuthenticator($input: DisableGoogleAuthenticatorInput!) {
  disableGoogleAuthenticator(input: $input)
}
    `;
export type DisableGoogleAuthenticatorMutationFn = Apollo.MutationFunction<DisableGoogleAuthenticatorMutation, DisableGoogleAuthenticatorMutationVariables>;

/**
 * __useDisableGoogleAuthenticatorMutation__
 *
 * To run a mutation, you first call `useDisableGoogleAuthenticatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableGoogleAuthenticatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableGoogleAuthenticatorMutation, { data, loading, error }] = useDisableGoogleAuthenticatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisableGoogleAuthenticatorMutation(baseOptions?: Apollo.MutationHookOptions<DisableGoogleAuthenticatorMutation, DisableGoogleAuthenticatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableGoogleAuthenticatorMutation, DisableGoogleAuthenticatorMutationVariables>(DisableGoogleAuthenticatorDocument, options);
      }
export type DisableGoogleAuthenticatorMutationHookResult = ReturnType<typeof useDisableGoogleAuthenticatorMutation>;
export type DisableGoogleAuthenticatorMutationResult = Apollo.MutationResult<DisableGoogleAuthenticatorMutation>;
export type DisableGoogleAuthenticatorMutationOptions = Apollo.BaseMutationOptions<DisableGoogleAuthenticatorMutation, DisableGoogleAuthenticatorMutationVariables>;
export const EnableGoogleAuthenticatorDocument = gql`
    mutation enableGoogleAuthenticator($input: EnableGoogleAuthenticatorInput!) {
  enableGoogleAuthenticator(input: $input)
}
    `;
export type EnableGoogleAuthenticatorMutationFn = Apollo.MutationFunction<EnableGoogleAuthenticatorMutation, EnableGoogleAuthenticatorMutationVariables>;

/**
 * __useEnableGoogleAuthenticatorMutation__
 *
 * To run a mutation, you first call `useEnableGoogleAuthenticatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableGoogleAuthenticatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableGoogleAuthenticatorMutation, { data, loading, error }] = useEnableGoogleAuthenticatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEnableGoogleAuthenticatorMutation(baseOptions?: Apollo.MutationHookOptions<EnableGoogleAuthenticatorMutation, EnableGoogleAuthenticatorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableGoogleAuthenticatorMutation, EnableGoogleAuthenticatorMutationVariables>(EnableGoogleAuthenticatorDocument, options);
      }
export type EnableGoogleAuthenticatorMutationHookResult = ReturnType<typeof useEnableGoogleAuthenticatorMutation>;
export type EnableGoogleAuthenticatorMutationResult = Apollo.MutationResult<EnableGoogleAuthenticatorMutation>;
export type EnableGoogleAuthenticatorMutationOptions = Apollo.BaseMutationOptions<EnableGoogleAuthenticatorMutation, EnableGoogleAuthenticatorMutationVariables>;
export const VerifyTotpDocument = gql`
    mutation verifyTotp($input: VerifyTotpInput!) {
  verifyTotp(input: $input) {
    ...Viewer
  }
}
    ${ViewerFragmentDoc}`;
export type VerifyTotpMutationFn = Apollo.MutationFunction<VerifyTotpMutation, VerifyTotpMutationVariables>;

/**
 * __useVerifyTotpMutation__
 *
 * To run a mutation, you first call `useVerifyTotpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyTotpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyTotpMutation, { data, loading, error }] = useVerifyTotpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyTotpMutation(baseOptions?: Apollo.MutationHookOptions<VerifyTotpMutation, VerifyTotpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyTotpMutation, VerifyTotpMutationVariables>(VerifyTotpDocument, options);
      }
export type VerifyTotpMutationHookResult = ReturnType<typeof useVerifyTotpMutation>;
export type VerifyTotpMutationResult = Apollo.MutationResult<VerifyTotpMutation>;
export type VerifyTotpMutationOptions = Apollo.BaseMutationOptions<VerifyTotpMutation, VerifyTotpMutationVariables>;
export const CreateUserRoleDocument = gql`
    mutation createUserRole($input: CreateUserRoleInput!) {
  createUserRole(input: $input) {
    ...UserRole
  }
}
    ${UserRoleFragmentDoc}`;
export type CreateUserRoleMutationFn = Apollo.MutationFunction<CreateUserRoleMutation, CreateUserRoleMutationVariables>;

/**
 * __useCreateUserRoleMutation__
 *
 * To run a mutation, you first call `useCreateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserRoleMutation, { data, loading, error }] = useCreateUserRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserRoleMutation, CreateUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserRoleMutation, CreateUserRoleMutationVariables>(CreateUserRoleDocument, options);
      }
export type CreateUserRoleMutationHookResult = ReturnType<typeof useCreateUserRoleMutation>;
export type CreateUserRoleMutationResult = Apollo.MutationResult<CreateUserRoleMutation>;
export type CreateUserRoleMutationOptions = Apollo.BaseMutationOptions<CreateUserRoleMutation, CreateUserRoleMutationVariables>;
export const RemoveUserRoleDocument = gql`
    mutation removeUserRole($id: ID!) {
  removeUserRole(id: $id) {
    __typename
    id
  }
}
    `;
export type RemoveUserRoleMutationFn = Apollo.MutationFunction<RemoveUserRoleMutation, RemoveUserRoleMutationVariables>;

/**
 * __useRemoveUserRoleMutation__
 *
 * To run a mutation, you first call `useRemoveUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserRoleMutation, { data, loading, error }] = useRemoveUserRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserRoleMutation, RemoveUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserRoleMutation, RemoveUserRoleMutationVariables>(RemoveUserRoleDocument, options);
      }
export type RemoveUserRoleMutationHookResult = ReturnType<typeof useRemoveUserRoleMutation>;
export type RemoveUserRoleMutationResult = Apollo.MutationResult<RemoveUserRoleMutation>;
export type RemoveUserRoleMutationOptions = Apollo.BaseMutationOptions<RemoveUserRoleMutation, RemoveUserRoleMutationVariables>;
export const UpdateUserRoleDocument = gql`
    mutation updateUserRole($id: ID!, $input: UpdateUserRoleInput!) {
  updateUserRole(id: $id, input: $input) {
    ...UserRole
  }
}
    ${UserRoleFragmentDoc}`;
export type UpdateUserRoleMutationFn = Apollo.MutationFunction<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;

/**
 * __useUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleMutation, { data, loading, error }] = useUpdateUserRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(UpdateUserRoleDocument, options);
      }
export type UpdateUserRoleMutationHookResult = ReturnType<typeof useUpdateUserRoleMutation>;
export type UpdateUserRoleMutationResult = Apollo.MutationResult<UpdateUserRoleMutation>;
export type UpdateUserRoleMutationOptions = Apollo.BaseMutationOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;
export const RemoveAuthSessionDocument = gql`
    mutation removeAuthSession($id: ID!) {
  removeAuthSession(id: $id) {
    __typename
    token
  }
}
    `;
export type RemoveAuthSessionMutationFn = Apollo.MutationFunction<RemoveAuthSessionMutation, RemoveAuthSessionMutationVariables>;

/**
 * __useRemoveAuthSessionMutation__
 *
 * To run a mutation, you first call `useRemoveAuthSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAuthSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAuthSessionMutation, { data, loading, error }] = useRemoveAuthSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveAuthSessionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAuthSessionMutation, RemoveAuthSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAuthSessionMutation, RemoveAuthSessionMutationVariables>(RemoveAuthSessionDocument, options);
      }
export type RemoveAuthSessionMutationHookResult = ReturnType<typeof useRemoveAuthSessionMutation>;
export type RemoveAuthSessionMutationResult = Apollo.MutationResult<RemoveAuthSessionMutation>;
export type RemoveAuthSessionMutationOptions = Apollo.BaseMutationOptions<RemoveAuthSessionMutation, RemoveAuthSessionMutationVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: UserInput!) {
  createUser(input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const RemoveUserDocument = gql`
    mutation removeUser($id: ID!) {
  removeUser(id: $id) {
    __typename
    id
  }
}
    `;
export type RemoveUserMutationFn = Apollo.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, options);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: ID!, $input: UserInput!) {
  updateUser(id: $id, input: $input) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const ViewNotificationsDocument = gql`
    mutation viewNotifications($ids: [ID]!) {
  viewNotifications(ids: $ids)
}
    `;
export type ViewNotificationsMutationFn = Apollo.MutationFunction<ViewNotificationsMutation, ViewNotificationsMutationVariables>;

/**
 * __useViewNotificationsMutation__
 *
 * To run a mutation, you first call `useViewNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewNotificationsMutation, { data, loading, error }] = useViewNotificationsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useViewNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<ViewNotificationsMutation, ViewNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ViewNotificationsMutation, ViewNotificationsMutationVariables>(ViewNotificationsDocument, options);
      }
export type ViewNotificationsMutationHookResult = ReturnType<typeof useViewNotificationsMutation>;
export type ViewNotificationsMutationResult = Apollo.MutationResult<ViewNotificationsMutation>;
export type ViewNotificationsMutationOptions = Apollo.BaseMutationOptions<ViewNotificationsMutation, ViewNotificationsMutationVariables>;
export const AliasesDocument = gql`
    query aliases($filter: AliasListFilter, $sort: Sort, $pagination: Pagination) {
  aliases(filter: $filter, sort: $sort, pagination: $pagination) {
    __typename
    count
    rows {
      ...Alias
    }
  }
}
    ${AliasFragmentDoc}`;

/**
 * __useAliasesQuery__
 *
 * To run a query within a React component, call `useAliasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAliasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAliasesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAliasesQuery(baseOptions?: Apollo.QueryHookOptions<AliasesQuery, AliasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AliasesQuery, AliasesQueryVariables>(AliasesDocument, options);
      }
export function useAliasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AliasesQuery, AliasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AliasesQuery, AliasesQueryVariables>(AliasesDocument, options);
        }
export type AliasesQueryHookResult = ReturnType<typeof useAliasesQuery>;
export type AliasesLazyQueryHookResult = ReturnType<typeof useAliasesLazyQuery>;
export type AliasesQueryResult = Apollo.QueryResult<AliasesQuery, AliasesQueryVariables>;
export const AuthSessionsDocument = gql`
    query authSessions($userId: ID!, $filter: AuthSessionFilter, $pagination: Pagination, $sort: Sort) {
  authSessions(
    userId: $userId
    filter: $filter
    pagination: $pagination
    sort: $sort
  ) {
    __typename
    count
    rows {
      token
      ip
      userAgent
      createdAt
      expiredAt
    }
  }
}
    `;

/**
 * __useAuthSessionsQuery__
 *
 * To run a query within a React component, call `useAuthSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthSessionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useAuthSessionsQuery(baseOptions: Apollo.QueryHookOptions<AuthSessionsQuery, AuthSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthSessionsQuery, AuthSessionsQueryVariables>(AuthSessionsDocument, options);
      }
export function useAuthSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthSessionsQuery, AuthSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthSessionsQuery, AuthSessionsQueryVariables>(AuthSessionsDocument, options);
        }
export type AuthSessionsQueryHookResult = ReturnType<typeof useAuthSessionsQuery>;
export type AuthSessionsLazyQueryHookResult = ReturnType<typeof useAuthSessionsLazyQuery>;
export type AuthSessionsQueryResult = Apollo.QueryResult<AuthSessionsQuery, AuthSessionsQueryVariables>;
export const CompaniesDocument = gql`
    query companies($filter: CompanyFilter, $pagination: Pagination, $sort: Sort) {
  companies(filter: $filter, pagination: $pagination, sort: $sort) {
    __typename
    count
    rows {
      ...Company
    }
  }
}
    ${CompanyFragmentDoc}`;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
      }
export function useCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const CompanyDocument = gql`
    query company($id: ID!) {
  company(id: $id) {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
      }
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const LocationsDocument = gql`
    query locations($filter: LocationFilter!, $sort: Sort, $pagination: Pagination) {
  locations(filter: $filter, sort: $sort, pagination: $pagination) {
    ...Location
  }
}
    ${LocationFragmentDoc}`;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useLocationsQuery(baseOptions: Apollo.QueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
      }
export function useLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
        }
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<typeof useLocationsLazyQuery>;
export type LocationsQueryResult = Apollo.QueryResult<LocationsQuery, LocationsQueryVariables>;
export const LogServersDocument = gql`
    query logServers($filter: LogServerListFilter, $pagination: Pagination, $sort: Sort) {
  logServers(filter: $filter, pagination: $pagination, sort: $sort) {
    __typename
    count
    rows {
      ...LogServer
    }
  }
}
    ${LogServerFragmentDoc}`;

/**
 * __useLogServersQuery__
 *
 * To run a query within a React component, call `useLogServersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogServersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogServersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useLogServersQuery(baseOptions?: Apollo.QueryHookOptions<LogServersQuery, LogServersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LogServersQuery, LogServersQueryVariables>(LogServersDocument, options);
      }
export function useLogServersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LogServersQuery, LogServersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LogServersQuery, LogServersQueryVariables>(LogServersDocument, options);
        }
export type LogServersQueryHookResult = ReturnType<typeof useLogServersQuery>;
export type LogServersLazyQueryHookResult = ReturnType<typeof useLogServersLazyQuery>;
export type LogServersQueryResult = Apollo.QueryResult<LogServersQuery, LogServersQueryVariables>;
export const NodeStatusesDocument = gql`
    query nodeStatuses($filter: NodeStatusListFilter!, $pagination: Pagination, $sort: Sort) {
  nodeStatuses(filter: $filter, pagination: $pagination, sort: $sort) {
    __typename
    count
    rows {
      ...NodeStatus
    }
  }
}
    ${NodeStatusFragmentDoc}`;

/**
 * __useNodeStatusesQuery__
 *
 * To run a query within a React component, call `useNodeStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeStatusesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useNodeStatusesQuery(baseOptions: Apollo.QueryHookOptions<NodeStatusesQuery, NodeStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodeStatusesQuery, NodeStatusesQueryVariables>(NodeStatusesDocument, options);
      }
export function useNodeStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodeStatusesQuery, NodeStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodeStatusesQuery, NodeStatusesQueryVariables>(NodeStatusesDocument, options);
        }
export type NodeStatusesQueryHookResult = ReturnType<typeof useNodeStatusesQuery>;
export type NodeStatusesLazyQueryHookResult = ReturnType<typeof useNodeStatusesLazyQuery>;
export type NodeStatusesQueryResult = Apollo.QueryResult<NodeStatusesQuery, NodeStatusesQueryVariables>;
export const NodesDocument = gql`
    query nodes($filter: NodeListFilter!, $pagination: Pagination, $sort: Sort) {
  nodes(filter: $filter, pagination: $pagination, sort: $sort) {
    __typename
    count
    rows {
      ...Node
    }
  }
}
    ${NodeFragmentDoc}`;

/**
 * __useNodesQuery__
 *
 * To run a query within a React component, call `useNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useNodesQuery(baseOptions: Apollo.QueryHookOptions<NodesQuery, NodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodesQuery, NodesQueryVariables>(NodesDocument, options);
      }
export function useNodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodesQuery, NodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodesQuery, NodesQueryVariables>(NodesDocument, options);
        }
export type NodesQueryHookResult = ReturnType<typeof useNodesQuery>;
export type NodesLazyQueryHookResult = ReturnType<typeof useNodesLazyQuery>;
export type NodesQueryResult = Apollo.QueryResult<NodesQuery, NodesQueryVariables>;
export const NotificationsDocument = gql`
    query notifications($filter: NotificationListFilter!, $pagination: Pagination, $sort: Sort) {
  notifications(filter: $filter, pagination: $pagination, sort: $sort) {
    __typename
    count
    rows {
      ...Notification
    }
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const QueryLogsDocument = gql`
    query queryLogs($filter: QueryLogFilter, $pagination: Pagination, $sort: Sort) {
  queryLogs(filter: $filter, pagination: $pagination, sort: $sort) {
    __typename
    count
    rows {
      ...QueryLog
    }
    pageInfo {
      current
      total
    }
  }
}
    ${QueryLogFragmentDoc}`;

/**
 * __useQueryLogsQuery__
 *
 * To run a query within a React component, call `useQueryLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryLogsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useQueryLogsQuery(baseOptions?: Apollo.QueryHookOptions<QueryLogsQuery, QueryLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryLogsQuery, QueryLogsQueryVariables>(QueryLogsDocument, options);
      }
export function useQueryLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryLogsQuery, QueryLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryLogsQuery, QueryLogsQueryVariables>(QueryLogsDocument, options);
        }
export type QueryLogsQueryHookResult = ReturnType<typeof useQueryLogsQuery>;
export type QueryLogsLazyQueryHookResult = ReturnType<typeof useQueryLogsLazyQuery>;
export type QueryLogsQueryResult = Apollo.QueryResult<QueryLogsQuery, QueryLogsQueryVariables>;
export const SubscribersDocument = gql`
    query subscribers($filter: SubscriberListFilter, $sort: Sort, $pagination: Pagination) {
  subscribers(filter: $filter, sort: $sort, pagination: $pagination) {
    __typename
    count
    rows {
      ...Subscriber
    }
  }
}
    ${SubscriberFragmentDoc}`;

/**
 * __useSubscribersQuery__
 *
 * To run a query within a React component, call `useSubscribersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscribersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sort: // value for 'sort'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSubscribersQuery(baseOptions?: Apollo.QueryHookOptions<SubscribersQuery, SubscribersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscribersQuery, SubscribersQueryVariables>(SubscribersDocument, options);
      }
export function useSubscribersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscribersQuery, SubscribersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscribersQuery, SubscribersQueryVariables>(SubscribersDocument, options);
        }
export type SubscribersQueryHookResult = ReturnType<typeof useSubscribersQuery>;
export type SubscribersLazyQueryHookResult = ReturnType<typeof useSubscribersLazyQuery>;
export type SubscribersQueryResult = Apollo.QueryResult<SubscribersQuery, SubscribersQueryVariables>;
export const UserRolesDocument = gql`
    query userRoles($filter: UserRoleFilter, $pagination: Pagination, $sort: Sort) {
  userRoles(filter: $filter, pagination: $pagination, sort: $sort) {
    __typename
    count
    rows {
      ...UserRole
    }
  }
}
    ${UserRoleFragmentDoc}`;

/**
 * __useUserRolesQuery__
 *
 * To run a query within a React component, call `useUserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRolesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useUserRolesQuery(baseOptions?: Apollo.QueryHookOptions<UserRolesQuery, UserRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRolesQuery, UserRolesQueryVariables>(UserRolesDocument, options);
      }
export function useUserRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRolesQuery, UserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRolesQuery, UserRolesQueryVariables>(UserRolesDocument, options);
        }
export type UserRolesQueryHookResult = ReturnType<typeof useUserRolesQuery>;
export type UserRolesLazyQueryHookResult = ReturnType<typeof useUserRolesLazyQuery>;
export type UserRolesQueryResult = Apollo.QueryResult<UserRolesQuery, UserRolesQueryVariables>;
export const UserDocument = gql`
    query user($id: ID!) {
  user(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query users($filter: UserFilter, $pagination: Pagination, $sort: Sort) {
  users(filter: $filter, pagination: $pagination, sort: $sort) {
    __typename
    count
    rows {
      ...User
    }
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const SetupGoogleAuthenticatorDocument = gql`
    query setupGoogleAuthenticator {
  setupGoogleAuthenticator {
    ...GoogleAuthenticatorData
  }
}
    ${GoogleAuthenticatorDataFragmentDoc}`;

/**
 * __useSetupGoogleAuthenticatorQuery__
 *
 * To run a query within a React component, call `useSetupGoogleAuthenticatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSetupGoogleAuthenticatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSetupGoogleAuthenticatorQuery({
 *   variables: {
 *   },
 * });
 */
export function useSetupGoogleAuthenticatorQuery(baseOptions?: Apollo.QueryHookOptions<SetupGoogleAuthenticatorQuery, SetupGoogleAuthenticatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SetupGoogleAuthenticatorQuery, SetupGoogleAuthenticatorQueryVariables>(SetupGoogleAuthenticatorDocument, options);
      }
export function useSetupGoogleAuthenticatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SetupGoogleAuthenticatorQuery, SetupGoogleAuthenticatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SetupGoogleAuthenticatorQuery, SetupGoogleAuthenticatorQueryVariables>(SetupGoogleAuthenticatorDocument, options);
        }
export type SetupGoogleAuthenticatorQueryHookResult = ReturnType<typeof useSetupGoogleAuthenticatorQuery>;
export type SetupGoogleAuthenticatorLazyQueryHookResult = ReturnType<typeof useSetupGoogleAuthenticatorLazyQuery>;
export type SetupGoogleAuthenticatorQueryResult = Apollo.QueryResult<SetupGoogleAuthenticatorQuery, SetupGoogleAuthenticatorQueryVariables>;
export const ViewerDocument = gql`
    query viewer {
  viewer {
    ...Viewer
  }
}
    ${ViewerFragmentDoc}`;

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQuery(baseOptions?: Apollo.QueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, options);
      }
export function useViewerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, options);
        }
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerQueryResult = Apollo.QueryResult<ViewerQuery, ViewerQueryVariables>;
export const LocationCreatedDocument = gql`
    subscription locationCreated($filter: LocationFilter!) {
  locationCreated(filter: $filter) {
    ...Location
  }
}
    ${LocationFragmentDoc}`;

/**
 * __useLocationCreatedSubscription__
 *
 * To run a query within a React component, call `useLocationCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLocationCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationCreatedSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useLocationCreatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<LocationCreatedSubscription, LocationCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<LocationCreatedSubscription, LocationCreatedSubscriptionVariables>(LocationCreatedDocument, options);
      }
export type LocationCreatedSubscriptionHookResult = ReturnType<typeof useLocationCreatedSubscription>;
export type LocationCreatedSubscriptionResult = Apollo.SubscriptionResult<LocationCreatedSubscription>;
export const LogServerChangedDocument = gql`
    subscription logServerChanged {
  logServerChanged {
    ...LogServer
  }
}
    ${LogServerFragmentDoc}`;

/**
 * __useLogServerChangedSubscription__
 *
 * To run a query within a React component, call `useLogServerChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLogServerChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogServerChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useLogServerChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<LogServerChangedSubscription, LogServerChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<LogServerChangedSubscription, LogServerChangedSubscriptionVariables>(LogServerChangedDocument, options);
      }
export type LogServerChangedSubscriptionHookResult = ReturnType<typeof useLogServerChangedSubscription>;
export type LogServerChangedSubscriptionResult = Apollo.SubscriptionResult<LogServerChangedSubscription>;
export const LogServerCreatedDocument = gql`
    subscription logServerCreated {
  logServerCreated {
    ...LogServer
  }
}
    ${LogServerFragmentDoc}`;

/**
 * __useLogServerCreatedSubscription__
 *
 * To run a query within a React component, call `useLogServerCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLogServerCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogServerCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useLogServerCreatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<LogServerCreatedSubscription, LogServerCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<LogServerCreatedSubscription, LogServerCreatedSubscriptionVariables>(LogServerCreatedDocument, options);
      }
export type LogServerCreatedSubscriptionHookResult = ReturnType<typeof useLogServerCreatedSubscription>;
export type LogServerCreatedSubscriptionResult = Apollo.SubscriptionResult<LogServerCreatedSubscription>;
export const NodeStatusChangedDocument = gql`
    subscription nodeStatusChanged {
  nodeStatusChanged {
    ...NodeStatus
  }
}
    ${NodeStatusFragmentDoc}`;

/**
 * __useNodeStatusChangedSubscription__
 *
 * To run a query within a React component, call `useNodeStatusChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNodeStatusChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeStatusChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNodeStatusChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NodeStatusChangedSubscription, NodeStatusChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NodeStatusChangedSubscription, NodeStatusChangedSubscriptionVariables>(NodeStatusChangedDocument, options);
      }
export type NodeStatusChangedSubscriptionHookResult = ReturnType<typeof useNodeStatusChangedSubscription>;
export type NodeStatusChangedSubscriptionResult = Apollo.SubscriptionResult<NodeStatusChangedSubscription>;
export const NodeStatusCreatedDocument = gql`
    subscription nodeStatusCreated {
  nodeStatusCreated {
    ...NodeStatus
  }
}
    ${NodeStatusFragmentDoc}`;

/**
 * __useNodeStatusCreatedSubscription__
 *
 * To run a query within a React component, call `useNodeStatusCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNodeStatusCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeStatusCreatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNodeStatusCreatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NodeStatusCreatedSubscription, NodeStatusCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NodeStatusCreatedSubscription, NodeStatusCreatedSubscriptionVariables>(NodeStatusCreatedDocument, options);
      }
export type NodeStatusCreatedSubscriptionHookResult = ReturnType<typeof useNodeStatusCreatedSubscription>;
export type NodeStatusCreatedSubscriptionResult = Apollo.SubscriptionResult<NodeStatusCreatedSubscription>;
export const NotificationChangedDocument = gql`
    subscription notificationChanged($userId: ID) {
  notificationChanged(userId: $userId) {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useNotificationChangedSubscription__
 *
 * To run a query within a React component, call `useNotificationChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationChangedSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useNotificationChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NotificationChangedSubscription, NotificationChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NotificationChangedSubscription, NotificationChangedSubscriptionVariables>(NotificationChangedDocument, options);
      }
export type NotificationChangedSubscriptionHookResult = ReturnType<typeof useNotificationChangedSubscription>;
export type NotificationChangedSubscriptionResult = Apollo.SubscriptionResult<NotificationChangedSubscription>;
export const NotificationCreatedDocument = gql`
    subscription notificationCreated($userId: ID) {
  notificationCreated(userId: $userId) {
    ...Notification
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useNotificationCreatedSubscription__
 *
 * To run a query within a React component, call `useNotificationCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationCreatedSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useNotificationCreatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NotificationCreatedSubscription, NotificationCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NotificationCreatedSubscription, NotificationCreatedSubscriptionVariables>(NotificationCreatedDocument, options);
      }
export type NotificationCreatedSubscriptionHookResult = ReturnType<typeof useNotificationCreatedSubscription>;
export type NotificationCreatedSubscriptionResult = Apollo.SubscriptionResult<NotificationCreatedSubscription>;
export const UserProfileChangedDocument = gql`
    subscription userProfileChanged {
  userProfileChanged {
    ...UserProfile
  }
}
    ${UserProfileFragmentDoc}`;

/**
 * __useUserProfileChangedSubscription__
 *
 * To run a query within a React component, call `useUserProfileChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUserProfileChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UserProfileChangedSubscription, UserProfileChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UserProfileChangedSubscription, UserProfileChangedSubscriptionVariables>(UserProfileChangedDocument, options);
      }
export type UserProfileChangedSubscriptionHookResult = ReturnType<typeof useUserProfileChangedSubscription>;
export type UserProfileChangedSubscriptionResult = Apollo.SubscriptionResult<UserProfileChangedSubscription>;