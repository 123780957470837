import React, { FC, memo, useContext, useEffect, useMemo } from 'react';
import { ControlPosition } from 'leaflet';
import { LEAFLET_CONTROL_POSITION_CLASSES } from 'src/components/ui-kit/leaflet-map/constants';

interface LegendProps {
  position: ControlPosition;
}

const Legend: FC<LegendProps> = ({ position, children }) => {
  const positionClass =
    (position && LEAFLET_CONTROL_POSITION_CLASSES[position]) || LEAFLET_CONTROL_POSITION_CLASSES.topright;
  return (
    <div className={positionClass}>
      <div
        className="leaflet-control leaflet-bar"
        style={{
          backgroundColor: '#fff',
          padding: '0.5rem',
          border: '1px solid #999',
        }}>
        {children}
      </div>
    </div>
  );
};

export default memo(Legend) as typeof Legend;
