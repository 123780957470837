import React, { useContext, memo } from 'react';
import styled from 'styled-components';
import { ViewerContext } from '@fjedi/react-router-helpers';
import { formatDate } from 'src/helpers/time';
//
import { useTranslation } from 'react-i18next';
// GraphQL Queries
import { getDataFromResponse } from '@fjedi/graphql-react-components';
import getAuthSessionsQuery from 'src/graphql/queries/get-auth-sessions.graphql';
import removeAuthSessionMutation from 'src/graphql/mutations/user-session/remove.graphql';
//
import { Divider } from 'antd';
import FilterDropdown from 'src/components/ui-kit/table/filter-dropdown';
import Table from 'src/components/ui-kit/table';
import { SearchOutlined } from 'src/components/ui-kit/icons';

const Container = styled.div``;

const ProfileSettingsTab = () => {
  const viewer = useContext(ViewerContext);
  const { t } = useTranslation();
  if (!viewer) {
    return null;
  }

  return (
    <Container>
      <Divider>{t('Active sessions')}</Divider>
      <Table
        pagination
        pageSize={5}
        dataType="AuthSession"
        query={getAuthSessionsQuery}
        getDataFromResponse={getDataFromResponse('AuthSession')}
        removeRowMutation={removeAuthSessionMutation}
        removalConfirmationMessage={`${t('Remove')}?`}
        variables={{ userId: viewer.id }}
        rowKey="token"
        columns={[
          {
            title: t('Creation Date'),
            dataIndex: 'createdAt',
            sorterKey: 'createdAt',
            width: 180,
            sorter: (a, b) => a - b,
            render(createdAt) {
              return formatDate(createdAt, 'DD.MM.YYYY HH:mm');
            },
          },
          {
            title: t('IP'),
            dataIndex: 'ip',
            width: 180,
            filterDropdown: props => <FilterDropdown {...props} />,
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            sorterKey: 'lastName',
            sorter: (a, b) => a - b,
            render(ip) {
              return ip || 'Не указано';
            },
          },
          {
            title: t('User-Agent'),
            dataIndex: 'userAgent',
            filterDropdown: props => <FilterDropdown {...props} />,
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            render(userAgent) {
              return userAgent || 'Не указано';
            },
          },
        ]}
      />
    </Container>
  );
};

ProfileSettingsTab.propTypes = {};
ProfileSettingsTab.defaultProps = {};

export default memo(ProfileSettingsTab);
