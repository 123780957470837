import './styles.global.less';
//
import * as Sentry from '@sentry/browser';
// @ts-ignore
import initSentry from 'kit/lib/sentry';

// React parts
import React from 'react';
import ReactDOM from 'react-dom/client';

// React Router, for controlling browser routes.  We'll feed in our custom
// `history` instance that's imported below, so that we have a single store of
// truth for routing
import { BrowserRouter } from '@fjedi/react-router-helpers';

// Apollo Provider. This HOC will 'wrap' our React component chain
// and handle injecting data down to any listening component
import { ApolloProvider, browserClient, mergePaginatedList, mergeList } from '@fjedi/graphql-react-components';

// Multi-lang support
import languageDetector from 'i18next-browser-languagedetector';
// @ts-ignore
import initTranslations, { I18nextProvider } from 'kit/lib/intl';

// Cookies
import { CookiesProvider } from 'react-cookie';

// Main component -- i.e. the 'root' React component in our app
import Main from './components/root';
//
import { API_URL, SUBSCRIPTIONS_URL, SENTRY_PUBLIC_DSN } from './constants';

// @ts-ignore
window.SERVER = false;

//
const i18n = initTranslations({
  languageDetector,
  detectionProps: {
    order: ['cookie', 'session', 'navigator', 'querystring'],
    // lookupLocalStorage: 'lang',
    lookupCookie: 'lang',
    lookupQuerystring: 'lang',
    lookupSession: 'lang',
    // lookupPath: 'lang',
    // lookupFromPathIndex: 0,
  },
});

//
initSentry(Sentry, {
  debug: true,
  dsn: SENTRY_PUBLIC_DSN,
});

const cacheOptions = {
  typePolicies: {
    Alias: {
      keyFields: ['subscriberId'],
    },
    Query: {
      fields: {
        aliases: mergePaginatedList,
        authSessions: mergePaginatedList,
        companies: mergePaginatedList,
        locations: mergeList,
        logServers: mergePaginatedList,
        nodes: mergePaginatedList,
        nodeStatuses: mergePaginatedList,
        notifications: mergePaginatedList,
        subscribers: mergePaginatedList,
        userRoles: mergePaginatedList,
        users: mergePaginatedList,
      },
    },
  },
};

// Create a new browser Apollo client
const client = browserClient({
  url: API_URL as string,
  wsURL: SUBSCRIPTIONS_URL as string,
  cacheOptions,
});

// @ts-ignore
const { initialI18nStore, initialLanguage } = window;

const appContainer = document.getElementById('root');

// Create a root.
const root = ReactDOM.createRoot(appContainer!);

// During an update, there is no need to pass the container again
root.render(
  <ApolloProvider client={client}>
    <CookiesProvider>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <Main initialI18nStore={initialI18nStore} initialLanguage={initialLanguage} />
        </I18nextProvider>
      </BrowserRouter>
    </CookiesProvider>
  </ApolloProvider>,
);
