import { elementColors } from 'src/components/ui-kit/leaflet-map';
import { TimeInstance } from 'src/helpers/time';

import type { MapFilters, QueryVariables } from './map.d';
import { LocationsQueryVariables } from 'src/graphql/generated';

export function convertTimeRangeIntoCreatedAtFilter(startTime?: TimeInstance, endTime?: TimeInstance) {
  let from = null;
  let to = null;

  if (startTime) {
    from = startTime.toISOString();
  }

  if (endTime) {
    to = endTime.toISOString();
  }

  return { from, to };
}

export function compareQueryVariables(
  prevQueryVariables: LocationsQueryVariables,
  nextQueryVariables: LocationsQueryVariables,
): boolean {
  const {
    createdAt: prevCreatedAt,
    remoteCompanyId: prevRemoteCompanyId,
    callingSsiId: prevCallingSsiId,
    rssi: prevRssi,
    roadsBinding: prevRoadsBinding,
    trackingMethod: prevTrackingMethod,
    locationUncertainty: prevLocationUncertainty,
  } = prevQueryVariables.filter;
  const {
    createdAt: nextCreatedAt,
    remoteCompanyId: nextRemoteCompanyId,
    callingSsiId: nextCallingSsiId,
    rssi: nextRssi,
    roadsBinding: nextRoadsBinding,
    trackingMethod: nextTrackingMethod,
    locationUncertainty: nextLocationUncertainty,
  } = nextQueryVariables.filter;
  const isFromEqual = prevCreatedAt?.from === nextCreatedAt?.from;
  const isToEqual = prevCreatedAt?.to === nextCreatedAt?.to;
  const isCreatedAtEqual = isFromEqual && isToEqual;
  const isRemoteCompanyIdEqual = prevRemoteCompanyId.join('') === nextRemoteCompanyId.join('');
  const isCallingSsiIdEqual = (prevCallingSsiId?.join('') ?? '') === (nextCallingSsiId?.join('') ?? '');
  const isRssiEqual = prevRssi === nextRssi;
  const isRoadsBindingEqual = prevRoadsBinding === nextRoadsBinding;
  const isTrackingMethodEqual = prevTrackingMethod === nextTrackingMethod;
  const isLocationUncertaintyEqual = prevLocationUncertainty === nextLocationUncertainty;

  return (
    !isCreatedAtEqual ||
    !isRemoteCompanyIdEqual ||
    !isCallingSsiIdEqual ||
    !isRssiEqual ||
    !isRoadsBindingEqual ||
    !isTrackingMethodEqual ||
    !isLocationUncertaintyEqual
  );
}

export function compareFilters(prevFilters: MapFilters, nextFilters: MapFilters): boolean {
  const {
    rssi: prevRssi,
    roadsBinding: prevRoadsBinding,
    positioning: prevPositioning,
    trackingMethod: prevTrackingMethod,
    locationUncertainty: prevLocationUncertainty,
  } = prevFilters;
  const {
    rssi: nextRssi,
    roadsBinding: nextRoadsBinding,
    positioning: nextPositioning,
    trackingMethod: nextTrackingMethod,
    locationUncertainty: nextLocationUncertainty,
  } = nextFilters;
  const isRssiEqual = prevRssi === nextRssi;
  const isRoadsBindingEqual = prevRoadsBinding === nextRoadsBinding;
  const isPositioningEqual = prevPositioning === nextPositioning;
  const isTrackingMethodEqual = prevTrackingMethod === nextTrackingMethod;
  const isLocationUncertaintyEqual = prevLocationUncertainty === nextLocationUncertainty;

  return (
    !isRssiEqual || !isRoadsBindingEqual || !isPositioningEqual || !isTrackingMethodEqual || !isLocationUncertaintyEqual
  );
}

interface Option {
  label: string;
  value: string;
}
export function sortOptions(a: Option, b: Option): number {
  return parseInt(a.value, 10) - parseInt(b.value, 10);
}

interface SessionDefaults {
  remoteCompanyId: string | null;
  callingSsiId: string | null;
}
export function getSessionDefaults(): SessionDefaults {
  let remoteCompanyId: string | null | undefined = sessionStorage.getItem('remoteCompanyId');
  let callingSsiId: string | null | undefined = sessionStorage.getItem('callingSsiId');

  if (!remoteCompanyId) {
    sessionStorage.setItem('remoteCompanyId', '');
  } else if (!remoteCompanyId.length) {
    remoteCompanyId = null;
  }

  if (!callingSsiId) {
    sessionStorage.setItem('callingSsiId', '');
  } else if (!callingSsiId.length) {
    callingSsiId = null;
  }

  return { remoteCompanyId, callingSsiId };
}

export function getColorIndex(i: number) {
  const colorsCount = elementColors.length;
  const colorIndex = i - colorsCount * Math.floor(i / colorsCount);

  return colorIndex;
}
