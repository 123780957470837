/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@fjedi/react-router-helpers';
import capitalize from 'lodash/capitalize';
import { Tag } from 'antd';
import getUsersQuery from 'src/graphql/queries/get-users.graphql';
import { useCompaniesQuery } from 'src/graphql/generated';
import { formatDate } from 'src/helpers/time';
import { SearchOutlined } from 'src/components/ui-kit/icons';
import Table from 'src/components/ui-kit/table';
import FilterDropdown from 'src/components/ui-kit/table/filter-dropdown';

const UsersTable: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onUserRowClick = useCallback(
    ({ id }: { id: string }) => ({
      onClick: () => {
        navigate(`/admin/users/${id}`);
      },
    }),
    [navigate],
  );

  const { data: companiesRes } = useCompaniesQuery();
  const companies = useMemo(() => companiesRes?.companies?.rows ?? [], [companiesRes?.companies?.rows]);

  return (
    <Table
      pagination
      pageSize={10}
      query={getUsersQuery}
      dataType="User"
      rowKey="id"
      onRow={onUserRowClick}
      columns={[
        {
          title: t('Name'),
          dataIndex: 'fullName',
          // eslint-disable-next-line react/no-unstable-nested-components
          filterDropdown: (
            props: JSX.IntrinsicAttributes &
              JSX.IntrinsicClassAttributes<React.Component<any, any, any>> &
              Readonly<any> &
              Readonly<{ children?: React.ReactNode }>,
          ) => <FilterDropdown {...props} />,
          // eslint-disable-next-line react/no-unstable-nested-components
          filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
          sorterKey: 'fullName',
          sorter: (a: number, b: number) => a - b,
          render(fullName: string) {
            return fullName || 'Не указано';
          },
        },
        {
          title: t('Email'),
          dataIndex: 'email',
          // eslint-disable-next-line react/no-unstable-nested-components
          filterDropdown: (
            props: JSX.IntrinsicAttributes &
              JSX.IntrinsicClassAttributes<React.Component<any, any, any>> &
              Readonly<any> &
              Readonly<{ children?: React.ReactNode }>,
          ) => <FilterDropdown {...props} />,
          // eslint-disable-next-line react/no-unstable-nested-components
          filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        },
        {
          title: t('Company'),
          dataIndex: 'primaryCompanyId',
          // eslint-disable-next-line react/no-unstable-nested-components
          filterDropdown: (
            props: JSX.IntrinsicAttributes &
              JSX.IntrinsicClassAttributes<React.Component<any, any, any>> &
              Readonly<any> &
              Readonly<{ children?: React.ReactNode }>,
          ) => <FilterDropdown {...props} />,
          // eslint-disable-next-line react/no-unstable-nested-components
          filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
          render(primaryCompanyId: string) {
            return companies.find(company => company.id === primaryCompanyId)?.name ?? primaryCompanyId;
          },
        },
        {
          title: t('Role'),
          dataIndex: 'role',
          sorterKey: 'role',
          sorter: (a: number, b: number) => a - b,
          render(role: string) {
            return <Tag color={role === 'ADMIN' ? 'red' : 'green'}>{t(capitalize(role))}</Tag>;
          },
        },
        {
          title: t('Creation Date'),
          dataIndex: 'createdAt',
          sorterKey: 'createdAt',
          sorter: (a: number, b: number) => a - b,
          render(createdAt: string) {
            return formatDate(createdAt, 'DD.MM.YYYY');
          },
        },
      ]}
    />
  );
};

UsersTable.displayName = 'UsersTable';

export default memo(UsersTable);
