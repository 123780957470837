import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'src/helpers/time';
import first from 'lodash/first';
import getQueryLogsQuery from 'src/graphql/queries/get-query-logs.graphql';
import { QueryLog, UserProfile } from 'src/graphql/generated';
//
import Table from 'src/components/ui-kit/table';
import { Row, Col } from 'src/components/ui-kit/grid';
import { Text } from 'src/components/ui-kit/typography';

function convertOperationNameLabelToUserAction(label: string) {
  switch (label) {
    case 'Logged in':
      return 'Login';
    case 'Logged out':
      return 'Logout';
    default: {
      // const action = first(label.split(' ')) ?? '';
      // const instanceName = label.replace(action, '').trim();
      // const capitalized = instanceName.charAt(0).toUpperCase() + instanceName.slice(1);
      // return `${capitalized} ${action.toLowerCase()}`;
      return label;
    }
  }
}

const OPERATION_NAMES = new Map([
  ['login', 'Авторизовался на сайте'],
  ['logOut', 'Деавторизовался'],
  ['removeAuthSession', 'Удалил авторизационный токен у пользователя'],

  ['createUser', 'Зарегистрировал нового пользователя'],
  ['updateUser', 'Обновил данные пользователя'],
  ['removeUser', 'Удалил пользователя'],
  ['updateProfile', 'Обновил профиль пользователя'],
  ['enableGoogleAuthenticator', 'Включил двухфакторную авторизацию'],
  ['disableGoogleAuthenticator', 'Отключил двухфакторную авторизацию'],
  ['verifyTotp', 'Запросил проверку кода 2FA'],
  ['changePassword', 'Изменил пароль'],
  ['requestPasswordReset', 'Запросил сброс пароля'],
  ['resetPassword', 'Сбросил пароль'],

  ['createCompany', 'Зарегистрировал компанию'],
  ['updateCompany', 'Обновил данные компании'],
  ['removeCompany', 'Удалил компанию'],

  ['createUserRole', 'Добавил пользователя в компанию'],
  ['updateUserRole', 'Обновил роль пользователя в компании'],
  ['removeUserRole', 'Удалил пользователя из компании'],

  ['updateAlias', 'Обновил псевдоним'],

  ['createNotification', 'Отправил уведомление'],
  ['viewNotifications', 'Просмотрел свои уведомления'],
]);

const QueryLogsPage = () => {
  const { t } = useTranslation();
  //
  return (
    <Row>
      <Col md={{ span: 24 }}>
        <Table
          pagination
          pageSize={12}
          dataType="QueryLog"
          query={getQueryLogsQuery}
          subscriptionQueries={[]}
          rowKey="id"
          columns={[
            {
              title: t('User'),
              dataIndex: 'user',
              render(user: UserProfile, row: QueryLog) {
                return user?.fullName || user?.email || row?.data?.credentials?.email || 'Кто-то';
              },
            },
            {
              title: t('Операция'),
              dataIndex: 'operationName',
              filters: [...OPERATION_NAMES.entries()].map(([value, text]) => ({
                text: t(convertOperationNameLabelToUserAction(text)),
                value,
              })),
              filterSearch: true,
              render(operationName: string) {
                return OPERATION_NAMES.get(operationName)?.toLowerCase() ?? operationName;
              },
            },
            {
              title: t('Параметры'),
              dataIndex: 'data',
              render(data: QueryLog['data']) {
                return data ? (
                  <Text code copyable ellipsis={{ tooltip: true }} style={{ width: '400px' }}>
                    {JSON.stringify(data)}
                  </Text>
                ) : (
                  'No data'
                );
              },
            },
            {
              title: t('IP'),
              dataIndex: 'ip',
            },

            {
              title: t('Дата'),
              dataIndex: 'createdAt',
              sorterKey: 'createdAt',
              width: 180,
              sorter: (a: number, b: number) => a - b,
              render(createdAt: Date) {
                return formatDate(createdAt, 'DD.MM.YYYY HH:mm');
              },
            },
          ]}
        />
      </Col>
    </Row>
  );
};

QueryLogsPage.propTypes = {};
QueryLogsPage.defaultProps = {};

export default memo(QueryLogsPage);
