import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, Navigate, NotFound } from '@fjedi/react-router-helpers';
import ContentCard from 'src/components/ui-kit/card/main-content-card';
//
import UserProfileMainPage from './main';
import UserProfileSecurityPage from './security';
import UserProfileSessionsPage from './settings';

const ProfileRoot = () => {
  const { t } = useTranslation();
  const tabList = useMemo(
    () => [
      {
        key: 'general',
        tab: t('General'),
      },
      {
        key: 'security',
        tab: t('Security'),
      },
      {
        key: 'sessions',
        tab: t('Sessions'),
      },
    ],
    [t],
  );

  return (
    <ContentCard tabList={tabList} tabURLNavigation>
      <Routes>
        <Route path="general" element={<UserProfileMainPage />} />
        <Route path="security" element={<UserProfileSecurityPage />} />
        <Route path="sessions" element={<UserProfileSessionsPage />} />
        <Route path="/" element={<Navigate replace to="general" />} />
        <Route element={<NotFound />} />
      </Routes>
    </ContentCard>
  );
};

ProfileRoot.propTypes = {};

export default memo(ProfileRoot);
