import React, { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ViewerContext } from '@fjedi/react-router-helpers';

import { Divider } from 'antd';

import { FormItem } from 'src/components/ui-kit/form';
import { Input } from 'src/components/ui-kit/input';
import { ViewerRole } from 'src/graphql/generated';

const ProfileDivider = styled(Divider)`
  margin-top: 1.2rem;
  margin-bottom: 0.75rem;
`;

const OrganizationProfileMain = () => {
  const { t } = useTranslation();
  const viewer = useContext(ViewerContext);
  const viewerRole = viewer?.role || 'ANONYMOUS';
  const isAdmin = viewerRole === ViewerRole.Admin;

  return (
    <>
      <FormItem
        label={t('Name')}
        name="name"
        rules={[
          {
            required: true,
            message: t('Please fill this field'),
          },
        ]}>
        <Input disabled={!isAdmin} />
      </FormItem>
      <FormItem
        label={t('Company ID in TETRA database')}
        name="remoteId"
        rules={[{ required: true, message: t('Please fill this field') }]}>
        <Input disabled={!isAdmin} />
      </FormItem>
    </>
  );
};

OrganizationProfileMain.propTypes = {};
OrganizationProfileMain.defaultProps = {};
OrganizationProfileMain.displayName = 'OrganizationProfileMain';

export default memo(OrganizationProfileMain);
