import React from 'react';
import Button from 'src/components/ui-kit/buttons/index';
import styled from 'styled-components';

const StyledSubmitButton = styled(Button).attrs(props => ({
  size: props.size ?? 'medium',
}))`
  &.ant-btn {
    display: ${props => (props.isHidden ? 'none' : 'block')};
  }
`;

export default function SubmitButton(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <StyledSubmitButton htmlType="submit" type="primary" {...props} />;
}
