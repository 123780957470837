/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, memo, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Switch } from 'src/components/ui-kit/switch';
import { colorGrays } from 'src/components/ui-kit/theme';

const SwitchContainer = styled.div`
  border-radius: 0.625rem;
  border: 1px solid ${colorGrays.gray400};
  min-height: 36px;
  width: 100%;
  padding: 0 0.625rem;
  display: flex;
  align-items: center;

  & > span {
    font-size: 0.9375rem;
    color: ${colorGrays.gray400};
  }

  & > .ant-switch {
    margin-left: auto;
    height: 1.3725rem;
  }
`;

const SwitchInput: FC<Parameters<typeof Switch>[0]> = ({ onChange, value: initialValue = false, ...rest }) => {
  const [isEnabled, setIsEnabled] = useState(initialValue);
  const { t } = useTranslation();

  const handleChange = useCallback(
    (value, event) => {
      setIsEnabled(value);

      if (typeof onChange === 'function') {
        onChange(value, event);
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (initialValue !== isEnabled) {
      setIsEnabled(initialValue);
    }
  }, [initialValue, isEnabled]);

  return (
    <SwitchContainer>
      <span>{isEnabled ? t('Enabled') : t('Disabled')}</span>
      <Switch {...rest} checked={isEnabled} onChange={handleChange} initialValue={initialValue} />
    </SwitchContainer>
  );
};

export default memo(SwitchInput) as typeof SwitchInput;
