/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, memo, useCallback, useMemo } from 'react';

// import Marker from 'react-google-maps/lib/components/Marker';
// import Polyline from 'react-google-maps/lib/components/Polyline';
import { Marker, Polyline } from 'react-leaflet';
import type { LatLngLiteral } from 'leaflet';

import { formatDate } from 'src/helpers/time';

import Rssi from './rssi';
import { composeMarkerProps, composePolylineProps, composeRssiProps } from './helpers';
import { MARKER_POSITIONS } from './constants';

import type { RouteType } from '.';
import type { RssiRouteData } from './rssi';
import useInfoWindow from './use-info-window';

interface RouteProps extends RouteType {
  // bindToRoads: boolean;
}

export const Route: FC<RouteProps> = ({ name, path, routeData, timestamp, color, showRssi }) => {
  const startPosition = useMemo(() => path && (path[0] as LatLngLiteral), [path]);
  const endPosition = useMemo(() => path && (path?.at(-1) as LatLngLiteral), [path]);
  const startMarkerProps = useMemo(() => composeMarkerProps(color, MARKER_POSITIONS.START), [color]);
  const endMarkerProps = useMemo(() => composeMarkerProps(color, MARKER_POSITIONS.END), [color]);
  const polylineOptions = useMemo(() => composePolylineProps(color), [color]);

  // console.info('routeData: ', routeData);

  const rssiData = useMemo(
    () => (showRssi && routeData ? composeRssiProps(path, routeData as Required<typeof routeData>) : []),
    [path, routeData, showRssi],
  );

  // console.info('rssiData: ', rssiData);

  const locationTimestamp = useMemo(() => {
    const dateFormat = 'DD-MM-YYYY HH:mm:ss';
    const start = formatDate(timestamp.start, dateFormat);
    const end = formatDate(timestamp.end, dateFormat);

    return { start, end };
  }, [timestamp.end, timestamp.start]);

  const {
    Info: StartInfo,
    isOpen: isStartInfoOpen,
    open: openStartInfo,
    toggle: toggleStartInfo,
  } = useInfoWindow({
    title: name,
    content: locationTimestamp.start,
    color,
  });
  const {
    Info: EndInfo,
    isOpen: isEndInfoOpen,
    open: openEndInfo,
    toggle: toggleEndInfo,
  } = useInfoWindow({
    title: name,
    content: locationTimestamp.end,
    color,
  });

  const toggleAllInfoWindows = useCallback(() => {
    if (isStartInfoOpen === isEndInfoOpen) {
      toggleStartInfo();
      toggleEndInfo();
    } else if (isStartInfoOpen) {
      openEndInfo();
    } else if (isEndInfoOpen) {
      openStartInfo();
    }
  }, [isEndInfoOpen, isStartInfoOpen, openEndInfo, openStartInfo, toggleEndInfo, toggleStartInfo]);

  return (
    <>
      <Marker position={startPosition} {...startMarkerProps} onClick={toggleStartInfo}>
        <StartInfo />
      </Marker>
      <Polyline positions={path} {...polylineOptions} onClick={toggleAllInfoWindows} />
      <Marker position={endPosition} {...endMarkerProps} onClick={toggleEndInfo}>
        <EndInfo />
      </Marker>
      {showRssi && <Rssi data={rssiData as RssiRouteData[]} />}
    </>
  );
};

export default memo(Route) as typeof Route;
