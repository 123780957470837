import React, { memo, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import logger from 'src/helpers/logger';
import { User, UsersQueryVariables, useUsersQuery } from 'src/graphql/generated';
import Select, { SelectOption } from 'src/components/ui-kit/select';

export type UserSelectorProps = {
  value?: string;
  className?: string;
  style?: { [k: string]: string | number };
  disabled?: boolean;
  queryVariables?: UsersQueryVariables;
  onChange?: (_v: string, _d: User) => void;
};

const UserSelector: React.FC<UserSelectorProps> = props => {
  const { t } = useTranslation();
  const {
    // Get value provided by parent component (if any)
    value,
    // Handle default react input props to be able to disable selectbox and/or set custom styles
    style,
    className,
    disabled,
    // Allow to pass custom query-vars for filtering or pagination of the data returned from API
    queryVariables,
  } = props;
  const [selectedUser, setUserId] = useState(value);
  //
  const { data: queryResult } = useUsersQuery({ variables: queryVariables, fetchPolicy: 'cache-and-network' });
  const users = queryResult?.users?.rows || [];

  // if 'value' provided by parent component has been changed
  // we should update value stored inside inner state of the UserSelector
  useEffect(() => {
    if (value !== selectedUser) {
      setUserId(value);
    }
  }, [value]);

  // Handle 'onChange' event and bypass it to 'onChange' handler from props (if any)
  // if not parent 'onChange' handler provided, just save new value to the inner state of the component
  const onChange = useCallback(
    v => {
      logger('UserSelector.onChange', v);
      //
      if (typeof props.onChange === 'function') {
        const user = users.find(d => d.id === v) as User;
        props.onChange(v, user);
      } else {
        setUserId(v);
      }
    },
    [selectedUser, props.onChange, users],
  );

  return (
    <Select
      dropdownStyle={{ borderRadius: '0.625rem' }}
      disabled={disabled}
      style={style}
      className={className}
      value={selectedUser}
      onChange={onChange}
      placeholder={t('Select a user')}
      showSearch
      filterOption={(input: string, option) =>
        (option!.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
      }>
      {users.map(user => (
        <SelectOption key={user.id} value={user.id}>
          {user.fullName || user.email}
        </SelectOption>
      ))}
    </Select>
  );
};

UserSelector.displayName = 'UserSelector';

export default memo(UserSelector);
