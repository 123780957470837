import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { pure, compose, withHandlers, withStateHandlers, setDisplayName } from 'recompose';
import styled from 'styled-components';
// Redux
import { formType } from 'src/store/prop-types';
import { isEmail } from 'src/helpers/validators';
import { useTranslation } from 'react-i18next';
// GraphQL
import { Mutation } from '@fjedi/graphql-react-components';
// GraphQL Mutations
import requestPasswordResetMutation from 'src/graphql/mutations/request-password-reset.graphql';
// GraphQL Queries
import viewerQuery from 'src/graphql/queries/viewer.graphql';
//
import AuthBox from 'src/components/ui-kit/auth-box';
import { UserOutlined, LockOutlined } from 'src/components/ui-kit/icons';
import { Form, FormItem } from 'src/components/ui-kit/form';
import { Input, InputPrefixLabel } from 'src/components/ui-kit/input';
import Link from 'src/components/ui-kit/buttons/link';
import Button from 'src/components/ui-kit/buttons';
import { colorTheme } from 'src/components/ui-kit/theme';

//
const StyledForm = styled(Form)`
  max-width: 300px;
`;

const RequestSentContainer = styled.div`
  text-align: center;
`;

const BackButton = styled(Button).attrs({ type: 'text', block: true })`
  font-weight: 300;
  width: 100%;
  border: 0.5px solid ${colorTheme.secondary};
  color: ${colorTheme.primary} !important;
  padding: 0.4rem 0;

  &:hover {
    border-color: ${colorTheme.primary};
  }
`;
//
const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { resetFields, validateFields } = form;
  const [requestStatus, setRequestStatus] = useState(false);
  const [validateEmail, enableEmailValidation] = useState(false);
  const onCompleted = useCallback(() => {
    resetFields();
    setRequestStatus(true);
  });
  const onSubmit = useCallback(mutation => formData => {
    mutation({
      variables: formData,
    });
  });

  return (
    <Mutation mutation={requestPasswordResetMutation} onCompleted={onCompleted}>
      {(mutation, { loading }) => (
        <AuthBox title={t('Password reset')}>
          {requestStatus && <RequestSentContainer>Запрос отправлен Вам на почту</RequestSentContainer>}
          {!requestStatus && (
            <StyledForm form={form} onFinish={onSubmit(mutation)}>
              <FormItem name="email" rules={[{ required: true, message: t('Please fill this field') }]}>
                <Input
                  addonBefore={
                    <InputPrefixLabel>
                      <UserOutlined />
                    </InputPrefixLabel>
                  }
                  type="email"
                  onBlur={enableEmailValidation}
                  placeholder="Email"
                />
              </FormItem>
              <FormItem>
                <Button block loading={loading} type="primary" size="large" htmlType="submit">
                  {t('Reset password')}
                </Button>
              </FormItem>
              <BackButton loading={loading} href="/login">
                {t('Back to login')}
              </BackButton>
            </StyledForm>
          )}
        </AuthBox>
      )}
    </Mutation>
  );
};

ForgotPasswordPage.propTypes = {};

ForgotPasswordPage.defaultProps = {};

export default compose(setDisplayName('ForgotPasswordPage'), pure)(ForgotPasswordPage);
