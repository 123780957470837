import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers, setDisplayName } from 'recompose';
import { Link } from '@fjedi/react-router-helpers';
import styled from 'styled-components';
// GraphQL
import { Mutation } from '@fjedi/graphql-react-components';
// GraphQL Mutations
import resetPasswordMutation from 'src/graphql/mutations/reset-password.graphql';
// GraphQL Queries
import viewerQuery from 'src/graphql/queries/viewer.graphql';
//
import { Button } from 'antd';
import { Form, FormItem } from 'src/components/ui-kit/form';
import { Input } from 'src/components/ui-kit/input';
import AuthBox from 'src/components/ui-kit/auth-box';
// localisation
import { useTranslation } from 'react-i18next';

//
const StyledForm = styled(Form)`
  max-width: 300px;
`;
const StyledButton = styled(Button)`
  width: 100%;
`;
//
const ResetPasswordPage = props => {
  const { onSubmit, onCompleted } = props;
  const { t } = useTranslation();

  return (
    <Mutation
      mutation={resetPasswordMutation}
      onCompleted={onCompleted}
      update={(cache, { data: { resetPassword } }) => {
        cache.writeQuery({
          query: viewerQuery,
          data: { viewer: resetPassword },
        });
      }}>
      {(mutation, { loading }) => (
        <AuthBox title={t('Change password')} footerChildren={<Link to="/">{t('Main page')}</Link>}>
          <StyledForm onSubmit={onSubmit(mutation)}>
            <FormItem name="login" rules={[{ required: true, message: t('Type new password') }]}>
              <Input placeholder={t('Password')} />
            </FormItem>
            <FormItem name="passwordConfirmation" rules={[{ required: true, message: t('Confirm entered password') }]}>
              <Input placeholder={t('Confirm entered password')} />
            </FormItem>
            <FormItem>
              <StyledButton loading={loading} size="large" type="primary" htmlType="submit">
                {t('Set new password')}
              </StyledButton>
            </FormItem>
          </StyledForm>
        </AuthBox>
      )}
    </Mutation>
  );
};

ResetPasswordPage.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCompleted: PropTypes.func.isRequired,
};

ResetPasswordPage.defaultProps = {};

export default compose(
  setDisplayName('ResetPasswordPage'),
  withHandlers({
    onCompleted:
      ({ form }) =>
      () => {
        form.resetFields();
      },
    onSubmit:
      ({ form, onError, match }) =>
      mutation =>
      event => {
        event.preventDefault();
        // Проверяем правильно ли заполнены поля формы
        form.validateFields((err, formData) => {
          if (err) {
            return onError(err);
          }
          return mutation({
            variables: {
              ...formData,
              token: match.params.token,
            },
          });
        });
      },
  }),
  memo,
)(ResetPasswordPage);
