import omit from 'lodash/omit';
import zipWith from 'lodash/zipWith';
import compact from 'lodash/compact';
import { SVGProps } from 'react';

import { DivIcon, LatLng, LatLngLiteral, LatLngTuple } from 'leaflet';
import type { MarkerProps, PolylineProps } from 'react-leaflet';
// @ts-ignore
import PolylineCoder from 'polyline-encoded';

import { setOpacity } from 'src/components/ui-kit/utilities';

import type { RssiRouteData } from './rssi';
import { circlePath, elementColors, MARKER_POSITIONS, pinPath, RSSI_COLORS, SIGNAL_STRENGTH } from './constants';
// import defaultMarkerIcon from './default-marker-icon.svg';

export function modulo(dividend: number, divider: number): number {
  const remainder: number = dividend % divider;
  const roundedDividend: number = remainder + divider;

  return roundedDividend % divider;
}

export function getElementColor(elIndex: number) {
  const colorIndex = elIndex >= elementColors.length ? modulo(elIndex, elementColors.length) : elIndex;

  return elementColors[colorIndex as number];
}

export type SignalStrength = typeof SIGNAL_STRENGTH[keyof typeof SIGNAL_STRENGTH];
export function getSignalStrengthGradient(signalStrength: SignalStrength) {
  const color = RSSI_COLORS[signalStrength as keyof typeof RSSI_COLORS];
  const gradient: { [k: string]: string } = {};
  // [-0.25, -0.15, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5].forEach(alpha => {
  [0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 1].forEach(alpha => {
    gradient[`${alpha}`] = setOpacity(color, alpha + 0.25);
  });
  return gradient;
}

export type WeightedData = Pick<RssiRouteData, 'location' | 'weight'>;
export function getHeatmapData(data: RssiRouteData[]): WeightedData[] {
  return data.map(layer => omit(layer, ['dBm', 'signalStrength']));
}

export type MarkerPosition = typeof MARKER_POSITIONS[keyof typeof MARKER_POSITIONS];
export function composeMarkerProps(color: string, position?: MarkerPosition): MarkerProps {
  const svgProps: SVGProps<any> & { anchor?: [number, number] } = {
    // fillColor: color,
    fill: color,
    fillOpacity: 1,
    scale: 2,
    strokeWidth: 0,
    strokeOpacity: 1,
    // strokeColor: color,
  };
  switch (position) {
    case MARKER_POSITIONS.START:
      svgProps.path = circlePath;
      svgProps.strokeWidth = 4;
      svgProps.scale = 1.5;
      // svgProps.anchor = [12, 36];
      break;
    case MARKER_POSITIONS.END:
      svgProps.path = pinPath;
      // svgProps.anchor = [12, 24];
      break;
    default:
    // svgProps.url = defaultMarkerIcon;
  }
  const iconDiv = new DivIcon({
    html: `<svg fill="${svgProps.fill}" fill-opacity="${svgProps.fillOpacity}" viewBox="0 0 18 18" >
    <path d="${svgProps.path}" stroke-width="${svgProps.strokeWidth}" stroke-opacity="${svgProps.strokeOpacity}" clip-rule="evenodd"/>
</svg> `,
    className: 'map-marker',
    iconSize: [24, 24],
    iconAnchor: svgProps.anchor,
  });

  return {
    // clickable: true,
    // cursor: 'pointer',
    icon: iconDiv,
  } as MarkerProps;
}

export function composePolylineProps(rawColor: string): PolylineProps {
  const color = setOpacity(rawColor, 0.75);

  return {
    color,
    weight: 5,
    // clickable: true,
    // geodesic: true,
  } as PolylineProps;
}

interface RouteData {
  dBm: RssiRouteData['dBm'][] | null;
  signalStrength: SignalStrength[] | null;
  weight: RssiRouteData['weight'][] | null;
}
export function composeRssiProps(
  path: LatLngLiteral[],
  routeData: RouteData,
  // bindToRoads: boolean = false,
): RssiRouteData[] {
  const { weight: weights, dBm: dBms, signalStrength: signalStrengths } = routeData ?? {};

  return compact(
    zipWith(path ?? [], dBms ?? [], weights ?? [], signalStrengths ?? [], (location, dBm, weight, signalStrength) => {
      if (location && signalStrength !== SIGNAL_STRENGTH.NO_SIGNAL) {
        return {
          // location: new LatLng(location.lat, location.lng),
          location,
          dBm,
          weight,
          signalStrength,
        };
      }

      return null;
    }),
  );
}

const fixedFloat = (n: number) => parseFloat(n.toFixed(5));
export function standartizeLocation(location: LatLngLiteral): LatLngLiteral {
  // const { lat: rawLat, lng: rawLng } = location;
  let rawLat: number = 0;
  let rawLng: number = 0;
  if (Array.isArray(location)) {
    const [lat, lng] = location;
    rawLat = lat;
    rawLng = lng;
  } else if (typeof location === 'object') {
    const { lat, lng } = location;
    rawLat = lat;
    rawLng = lng;
  }

  const lat = fixedFloat(rawLat);
  const lng = fixedFloat(rawLng);

  return { lat, lng };
}

export class PolylineDecoder {
  precision: number;
  factor: number;
  dimension: number;

  constructor(options?: { precision?: number; factor?: number; dimension?: number }) {
    this.precision = options?.precision || 5;
    this.factor = options?.factor || (options?.precision ?? this.precision) ** 10;
    this.dimension = options?.dimension || 2;

    this.decode = this.decode.bind(this);
    this.decodeDeltas = this.decodeDeltas.bind(this);
    this.decodeFloats = this.decodeFloats.bind(this);
    this.decodeSignedIntegers = this.decodeSignedIntegers.bind(this);
    this.decodeUnsignedIntegers = this.decodeUnsignedIntegers.bind(this);
  }

  decode(encoded: string): LatLngTuple[] {
    console.log('Encoded location string', encoded);
    const flatPoints = this.decodeDeltas(encoded);
    const { dimension } = this;

    const points: LatLngTuple[] = [];
    for (let i = 0, len = flatPoints.length; i + (dimension - 1) < len; ) {
      const point: LatLngTuple = [] as unknown as LatLngTuple;

      // eslint-disable-next-line no-plusplus
      for (let dim = 0; dim < dimension; ++dim) {
        // eslint-disable-next-line no-plusplus
        point.push(flatPoints[i++]);
      }

      points.push(point);
    }
    return points;
  }

  decodeDeltas(encoded: string) {
    const lastNumbers: number[] = [];
    const { factor, dimension } = this;

    const numbers = this.decodeFloats(encoded);
    for (let i = 0, len = numbers.length; i < len; ) {
      // eslint-disable-next-line no-plusplus
      for (let d = 0; d < dimension; ++d, ++i) {
        // eslint-disable-next-line security/detect-object-injection
        numbers[i] = Math.round((lastNumbers[d] = numbers[i] + (lastNumbers[d] || 0)) * factor) / factor;
      }
    }

    return numbers;
  }

  // eslint-disable-next-line class-methods-use-this
  decodeFloats(encoded: string) {
    // const numbers = this.decodeSignedIntegers(encoded);
    // const { factor } = this;
    // // eslint-disable-next-line no-plusplus
    // for (let i = 0, len = numbers.length; i < len; ++i) {
    //   console.log(i, numbers[i], factor);
    //
    //   // eslint-disable-next-line security/detect-object-injection
    //   numbers[i] /= factor;
    // }

    // return numbers;
    return PolylineCoder.decodeFloats(encoded);
  }

  decodeSignedIntegers(encoded: string) {
    const numbers = this.decodeUnsignedIntegers(encoded);

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = numbers.length; i < len; ++i) {
      // eslint-disable-next-line security/detect-object-injection
      const num = numbers[i];
      // eslint-disable-next-line no-bitwise, security/detect-object-injection
      numbers[i] = num & 1 ? ~(num >> 1) : num >> 1;
    }

    return numbers;
  }

  // eslint-disable-next-line class-methods-use-this
  decodeUnsignedIntegers(encoded: string) {
    const numbers = [];

    let current = 0;
    let shift = 0;

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = encoded.length; i < len; ++i) {
      const b = encoded.charCodeAt(i) - 63;

      // eslint-disable-next-line no-bitwise
      current |= (b & 0x1f) << shift;

      if (b < 0x20) {
        numbers.push(current);
        current = 0;
        shift = 0;
      } else {
        shift += 5;
      }
    }

    return numbers;
  }
}
