import React, { memo } from 'react';
import styled from 'styled-components';
//
import { Upload } from 'antd';
//
const Dragger = styled(Upload.Dragger)`
  display: inline-block;
  max-width: 100%;

  & + & {
    margin-left: 1.8rem;
  }

  > .ant-upload.ant-upload-drag {
    width: 400px;
    max-width: 100%;
    height: 220px;
    border-radius: 3px;
    border: dashed 1px #edd92e;
    background-color: #fefdf4;
    display: inline-block;
  }
`;

const FileUploadDragger = ({ children, ...props }) => <Dragger {...props}>{children}</Dragger>;
FileUploadDragger.propTypes = {};
FileUploadDragger.defaultProps = {};

export default memo(FileUploadDragger);
