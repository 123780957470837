/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, memo, useCallback, useMemo } from 'react';

import Marker from 'react-google-maps/lib/components/Marker';
import Polyline from 'react-google-maps/lib/components/Polyline';

import { formatDate } from 'src/helpers/time';

import { composeMarkerProps, composePolylineProps, composeRssiProps } from './helpers';
import { MARKER_POSITIONS } from './constants';
import Rssi from './rssi';

import type { RssiRouteData } from './rssi';
import type { LiveRouteType } from '.';
import useInfoWindow from './use-info-window';

interface LiveRouteProps extends LiveRouteType {
  positioning: boolean;
  bindToRoads: boolean;
}

// TODO: implement positioning

const LiveRoute: FC<LiveRouteProps> = ({
  position,
  path,
  name,
  color,
  timestamp,
  showRssi,
  routeData,
  bindToRoads,
  positioning,
}) => {
  const startPosition = useMemo(() => path && (path[0] as google.maps.LatLngLiteral), [path]);
  const startMarkerProps = useMemo(() => composeMarkerProps(color, MARKER_POSITIONS.START), [color]);
  const currentMarkerProps = useMemo(() => composeMarkerProps(color, MARKER_POSITIONS.END), [color]);
  const polylineProps = useMemo(() => composePolylineProps(color), [color]);
  const rssiProps = useMemo(
    () => (showRssi && routeData ? composeRssiProps(path, routeData as Required<typeof routeData>, bindToRoads) : []),
    [bindToRoads, path, routeData, showRssi],
  );
  const locationTimestamp = useMemo(() => {
    const dateFormat = 'DD-MM-YYYY HH:mm:ss';
    const start = formatDate(timestamp.start, dateFormat);
    const end = formatDate(timestamp.end ?? timestamp.start, dateFormat);

    return { start, end };
  }, [timestamp.end, timestamp.start]);

  const {
    Info: StartInfo,
    isOpen: isStartInfoOpen,
    open: openStartInfo,
    toggle: toggleStartInfo,
  } = useInfoWindow({
    title: name,
    content: locationTimestamp.start,
    color,
  });
  const {
    Info: CurrentInfo,
    isOpen: isEndInfoOpen,
    open: openEndInfo,
    toggle: toggleCurrentInfo,
  } = useInfoWindow({
    title: name,
    content: locationTimestamp.end,
    color,
  });

  const toggleAllInfoWindows = useCallback(() => {
    if (isStartInfoOpen === isEndInfoOpen) {
      toggleStartInfo();
      toggleCurrentInfo();
    } else if (isStartInfoOpen) {
      openEndInfo();
    } else if (isEndInfoOpen) {
      openStartInfo();
    }
  }, [isEndInfoOpen, isStartInfoOpen, openEndInfo, openStartInfo, toggleCurrentInfo, toggleStartInfo]);

  return (
    <>
      <Marker position={startPosition} {...startMarkerProps} onClick={toggleStartInfo}>
        <StartInfo />
      </Marker>
      <Polyline path={path} options={polylineProps} onClick={toggleAllInfoWindows} />
      <Marker position={position} {...currentMarkerProps} onClick={toggleCurrentInfo}>
        <CurrentInfo />
      </Marker>
      {showRssi && <Rssi data={rssiProps as RssiRouteData[]} />}
    </>
  );
};

export default memo(LiveRoute) as typeof LiveRoute;
