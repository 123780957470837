/* eslint-disable no-confusing-arrow */
// React
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
//
import { CloseOutlined } from '@ant-design/icons';
import ModalPortal from './modal-portal';

const Overlay = styled.div`
  background-color: #ccc;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const Layout = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContentBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
`;
const Content = styled.div`
  width: ${props => (typeof props.width === 'number' ? `${props.width}px` : props.width)};
  max-width: 100vw;
  min-height: 200px;
  max-height: 100vh;
  background-color: #fff;
  position: relative;
  z-index: 10000;
  overflow: auto;

  //border-radius: 10px;
  //border: 1px solid ${props => props.theme['info-color']};
  padding: 1.5rem;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  //flex-direction: column;
  //justify-content: center;
  padding-bottom: 1rem;
  justify-content: flex-end;

  > div:first-child {
    flex-grow: 1;
  }
`;
const CloseButton = styled.button`
  //display: block;
  //width: 53px;
  //height: 13px;
  // font-family: ${props => props.theme.fontFamily};
  //font-size: 17px;
  //font-weight: bold;
  //font-style: italic;
  //font-stretch: normal;
  //line-height: normal;
  //letter-spacing: 0.4px;
  //text-align: right;
  //color: ${props => props.theme['error-color']};
  //align-self: flex-end;
  align-self: flex-start;
`;
const ModalPopup = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${props => (props.visible ? 500 : -1)};

  ${Content} {
    opacity: ${props => (props.visible ? 1 : 0)};
    transform: ${props => (props.visible ? 'translateY(0)' : 'translateY(-10%)')};
    transition: all 0.6s;
  }
`;
//
const ModalPopupComponent = ({
  onCancel,
  title,
  footer,
  closable,
  className,
  width,
  centered,
  isVisible,
  children,
  closeOnBgClick,
}) => (
  <ModalPortal>
    <ModalPopup
      className={className}
      visible={isVisible}
      footer={footer}
      closable={closable}
      onCancel={onCancel}
      centered={centered}>
      <Overlay className="modal-overlay" />
      <Layout className="modal-layout">
        <ContentBackground onClick={closeOnBgClick ? onCancel : undefined} />
        <Content width={width} className="modal-content">
          <Header>
            {title}
            {typeof onCancel === 'function' && (
              <CloseButton onClick={onCancel}>
                <CloseOutlined />
              </CloseButton>
            )}
          </Header>
          {children}
        </Content>
      </Layout>
    </ModalPopup>
  </ModalPortal>
);

ModalPopupComponent.propTypes = {
  onCancel: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  footer: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  closable: PropTypes.bool,
  isVisible: PropTypes.bool,
  centered: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  closeOnBgClick: PropTypes.bool,
};
ModalPopupComponent.defaultProps = {
  onCancel: undefined,
  title: null,
  footer: null,
  closable: false,
  isVisible: false,
  centered: false,
  className: '',
  width: '485px',
  closeOnBgClick: true,
  children: undefined,
};

export default memo(ModalPopupComponent);
