export const colorGrays = {
  gray100: '#f1f3fa',
  gray200: '#eef2f7',
  gray300: '#dee2e6',
  gray400: '#ced4da',
  gray500: '#bdbdbd',
  gray600: '#98a6ad',
  gray700: '#6c757d',
  gray800: '#343a40',
  gray900: '#313a46',
} as const;

export const colorBase = {
  blue: '#1d3893',
  indigo: '#727cf5',
  purple: '#6b5eae',
  pink: '#ff679b',
  red: '#fa5c7c',
  orange: '#fd7e14',
  yellow: '#ffbc00',
  green: '#0acf97',
  teal: '#02a8b5',
  cyan: '#39afd1',
} as const;

export const colorTheme = {
  primary: colorBase.blue,
  secondary: colorGrays.gray700,
  success: colorBase.green,
  info: colorBase.cyan,
  warning: colorBase.yellow,
  danger: colorBase.red,
  light: colorGrays.gray200,
  dark: colorGrays.gray900,
} as { [k: string]: string | undefined };

export const linkColor = colorBase.indigo;
export const bodyColor = colorGrays.gray700;
