import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link as ReactLink } from '@fjedi/react-router-helpers';
import omit from 'lodash/omit';
import styled from 'styled-components';
//
const StyledA = styled.a``;
// const StaticPages = new Set([
//   '/',
//   '/about',
//   '/play',
//   '/create',
//   '/business',
//   '/contacts',
//   '/news',
//   '/help',
// ]);

const Link = ({ widgetMode = false, to, children, forceSSR = false, ...props }) => {
  const ssr = props.target === '_blank' || forceSSR || widgetMode;
  const LinkComponent = ssr ? StyledA : ReactLink;

  return (
    <LinkComponent {...omit(props, !ssr ? ['target', 'title'] : [])} {...{ [ssr ? 'href' : 'to']: to }}>
      {children}
    </LinkComponent>
  );
};

export default memo(Link);
