/* eslint-disable react/no-danger */
// React
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { pure, compose } from 'recompose';
import { Popconfirm as AntPopconfirm } from 'antd';

const Container = styled(AntPopconfirm)``;

//
const PopconfirmComponent = ({ children, ...props }) => <Container {...props}>{children}</Container>;

PopconfirmComponent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
  placement: PropTypes.string,
};
PopconfirmComponent.defaultProps = {
  className: '',
  placement: 'bottom',
};

export default compose(pure)(PopconfirmComponent);
