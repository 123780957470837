import React, { memo, useCallback, useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
// GraphQL
import { ApolloCache, useApolloError } from '@fjedi/graphql-react-components';
import { LoginResponse, useLoginMutation, ViewerCredentialsInput } from 'src/graphql/generated';
//
import { Form, FormItem } from 'src/components/ui-kit/form';
import Link from 'src/components/ui-kit/buttons/link';
import Button from 'src/components/ui-kit/buttons';
import { LockOutlined, UserOutlined } from 'src/components/ui-kit/icons';
import { Input, InputPrefixLabel } from 'src/components/ui-kit/input';
//
import logger from 'src/helpers/logger';
//
const StyledForm = styled(Form)`
  padding-bottom: 4rem;
`;
const PooledForm = styled.div`
  position: relative;
`;
const ForgotPassword = styled(Link)`
  color: #bdbdbd;
`;
//
export type OnAuthSuccessProps<D = unknown> = {
  cache: ApolloCache<any>;
  data: D;
};
export type AuthComponentProps<DataType = unknown> = {
  onSuccess<D = DataType>(_opts: OnAuthSuccessProps<D & DataType>): void;
};
export type LoginData = {
  login: LoginResponse;
};
//
const FirstFactor: FC<AuthComponentProps<LoginData>> = props => {
  const { onSuccess } = props;
  const { t } = useTranslation();
  //
  const [firstFactorForm] = Form.useForm<ViewerCredentialsInput>();
  //
  const update = useCallback(
    (cache, { data }) => {
      if (typeof onSuccess === 'function') {
        onSuccess({ cache, data });
      }
    },
    [onSuccess],
  );
  const onError = useApolloError();
  const [login, { loading: loggingIn }] = useLoginMutation({ update, onError });
  //
  const onFirstFactorSubmit = useCallback(
    (values: unknown) => {
      const { email, password } = values as ViewerCredentialsInput;

      logger('First factor form submitted with ', { values });

      login({
        variables: {
          credentials: { email, password },
        },
      }).then(res => {
        if (res.data) {
          const { data } = res;
          logger('login response', { data });
          firstFactorForm.resetFields();
        }
      }, logger);
    },
    [firstFactorForm, login],
  );
  //
  const firstFactorFormInitialValues = useMemo(
    () => ({
      email: '',
      password: '',
    }),
    [],
  );
  //
  const addonBefore = useCallback((field?: keyof ViewerCredentialsInput) => {
    let Icon;
    switch (field) {
      case 'password':
        Icon = LockOutlined;
        break;
      case 'email':
        Icon = UserOutlined;
        break;
      default:
        Icon = () => null;
    }

    return (
      <InputPrefixLabel>
        <Icon />
      </InputPrefixLabel>
    );
  }, []);

  return (
    <StyledForm
      form={firstFactorForm}
      onFinish={onFirstFactorSubmit}
      layout="vertical"
      initialValues={firstFactorFormInitialValues}>
      <FormItem name="email" rules={[{ required: true, message: t('Please fill this field') }]}>
        <Input addonBefore={addonBefore('email')} type="email" placeholder="Email" />
      </FormItem>
      <PooledForm>
        <FormItem name="password" rules={[{ required: true, message: t('Please fill this field') }]}>
          <Input addonBefore={addonBefore('password')} type="password" placeholder={t('Password')} />
        </FormItem>
        <ForgotPassword to="/forgot-password">{t('Forgot password?')}</ForgotPassword>
      </PooledForm>
      <FormItem>
        <Button block loading={loggingIn} type="primary" size="large" htmlType="submit">
          {t('Sign In')}
        </Button>
      </FormItem>
    </StyledForm>
  );
};

FirstFactor.displayName = 'LoginPageFirstFactor';

export default memo(FirstFactor) as typeof FirstFactor;
