import React, { memo, FC, useMemo } from 'react';
import groupBy from 'lodash/groupBy';

import HeatmapLayer from 'react-google-maps/lib/components/visualization/HeatmapLayer';

import { getSignalStrengthGradient } from './helpers';

import type { SignalStrength } from './helpers';

export interface RssiRouteData {
  location: google.maps.LatLng;
  dBm: number;
  weight: number;
  signalStrength: SignalStrength;
}

interface RssiProps {
  data: RssiRouteData[];
}

interface HeatmapLayerData extends Omit<google.maps.visualization.HeatmapLayerOptions, 'data'> {
  data: {
    location: google.maps.LatLng;
    weight: number;
  }[];
}

const Rssi: FC<RssiProps> = ({ data: rssiData }) => {
  const heatmapOptions = useMemo(
    () => ({
      dissipating: false,
      opacity: 0.75,
      radius: 0.0125,
    }),
    [],
  );

  const heatmapLayers = useMemo(() => {
    const layers = groupBy(rssiData, 'signalStrength');

    return Object.keys(layers).map(signalStrength => {
      const data = layers[signalStrength as SignalStrength].map(({ weight, location }) => ({
        weight,
        location,
      }));

      const gradient = getSignalStrengthGradient(signalStrength as SignalStrength);

      return {
        data,
        gradient,
        ...heatmapOptions,
      } as HeatmapLayerData;
    });
  }, [rssiData, heatmapOptions]);

  return (
    <>
      {heatmapLayers.map(({ data, ...options }) => {
        const start = data[0].location.toJSON();
        const end = data?.at(-1)?.location.toJSON() as google.maps.LatLngLiteral;
        const key = `hm-${start.lat}_${end.lat}_${start.lng}_${end.lng}`;

        return <HeatmapLayer key={key} data={data} options={options} />;
      })}
    </>
  );
};

export default memo(Rssi) as typeof Rssi;
