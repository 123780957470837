/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, useMemo, memo } from 'react';
import { createGlobalStyle } from 'styled-components';
import { useTranslation } from 'react-i18next';
import groupBy from 'lodash/groupBy';
import { MapContainer, TileLayer } from 'react-leaflet';
import type { LatLngLiteral } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import omit from 'lodash/omit';
import cn from 'classnames';
import logger from 'src/helpers/logger';
import { TrackingMethod } from 'src/graphql/generated';
//
import Spinner from 'src/components/ui-kit/spinner';
import { colorGrays, colorTheme } from 'src/components/ui-kit/theme';

import { SignalStrength, standartizeLocation, PolylineDecoder } from './helpers';
import {
  defaultCenter,
  defaultZoom,
  LEAFLET_CONTROL_POSITION_CLASSES,
  // elementColors,
  RSSI_COLORS,
  SIGNAL_STRENGTH,
  SIGNAL_STRENGTH_LIMITS,
} from './constants';
import Route from './route';
import LivePoint from './live-point';
import LiveRoute from './live-route';
import Legend from './legend';

export const MAX_CONTAINER_HEIGHT = 600;

const MapStyles = createGlobalStyle`
  .spinner-container {
    width: 100%;
  }
  .map-container {
    position: relative;
    max-height: ${MAX_CONTAINER_HEIGHT}px;
    overflow-y: hidden;


    min-width: 500px;
    min-height: 500px;

    & > .map {
      min-width: 500px;
      min-height: 500px;
      box-sizing: initial;

      img {
        max-width: none;
      }

      .legend {
        height: auto;
        background: hsl(0deg 0% 100%);
        border-radius: 0.625rem;
        padding: 0.625rem;
        margin: 0.625rem;
        box-shadow: hsl(0deg 0% 0% / .3) 0 1px 4px -1px;

        & > ul > li {
          display: flex;
          align-items: baseline;
          flex-wrap: wrap;
          font-size: 0.8rem;

          & > i {
            display: inline-block;
            width: 1.5rem;
            height: 1rem;
            align-self: flex-start;
          }

          & > strong {
            margin: 0 0.25rem;
          }

          & > span {
            white-space: nowrap;

            & > strong {
              word-spacing: -0.125rem;
              margin: 0 0.125rem;
            }
          }

          &:not(:last-of-type) {
            margin-bottom: 0.3125rem;
          }
        }
      }

      .info-block {
        & > p {
          margin: 0
        }
      }

      & + div > .ant-spin-nested-loading.spinner-container {
        display: none;
      }

      & + div > .ant-spin-nested-loading.spinner-container.spinning {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;

        & > div > .ant-spin.spinner {
          max-height: none;
          width: 100%;
          height: 100%;
          background: ${colorGrays.gray300};
          mix-blend-mode: hard-light;

          & > .ant-spin-dot {
            color: ${colorTheme.primary};

            & > i {
              background-color: ${colorTheme.primary};
              opacity: 1;
            }
          }
        }
      }

    }
  }
`;

export interface Location {
  location: string;
  dBm: number;
  weight: number;
  signalStrength: SignalStrength;
}

export interface Coords {
  lat: number;
  lng: number;
}

export interface MapData {
  id: string;
  groupId: string;
  name: string;
  location: string;
  color?: string;
  dBm?: Location['dBm'][] | null;
  weight?: Location['weight'][] | null;
  signalStrength?: Location['signalStrength'][] | null;
  timestamp: {
    start: string;
    end?: string;
  };
}
interface DecodedMapData extends Omit<MapData, 'location'> {
  location: LatLngLiteral[];
}

export type MarkerProps = any;

export interface MapDataPoint extends MarkerProps, Partial<Omit<Location, 'location'>> {
  location: LatLngLiteral;
}

export type GoogleMapProps = any;
export type GoogleMap = any;

interface MapBypassProps extends GoogleMapProps {
  defaultZoom: number;
  defaultCenter: LatLngLiteral;
  googleMapURL: string;
  coords: Location;
  loading: boolean;
  onMount(): (_ref: GoogleMap) => void;
  ref?: GoogleMap;
}

export interface MapProps extends Partial<MapBypassProps> {
  data: MapData[];
  loading: boolean;
  showRssi: boolean;
  trackingMethod: string;
  bindToRoads: boolean;
  positioning: boolean;
}

interface MapElement {
  name: string;
  timestamp: {
    start: string;
    end: string;
  };
  color: string;
  showRssi: boolean;
}
export interface RouteType extends MapElement {
  path: DecodedMapData['location'];
  color: string;
  routeData?: Omit<DecodedMapData, 'location'>;
}
export interface LivePointType extends MapElement {
  position: LatLngLiteral;
  pointData?: Omit<Location, 'location'>;
}
export interface LiveRouteType extends MapElement {
  position: LatLngLiteral;
  path: DecodedMapData['location'];
  routeData?: Omit<DecodedMapData, 'location'>;
}
export type MapElementType = { id: string } & RouteType & LivePointType & LiveRouteType;

const polylineDecoder = new PolylineDecoder();

const Map: FC<MapProps> = props => {
  const { data, loading, showRssi, trackingMethod, bindToRoads, positioning } = props;
  const { t } = useTranslation();
  const decodedData: DecodedMapData[] = useMemo(() => {
    const rows =
      data?.map(mapData => {
        const decodedLocationData: Omit<MapData, 'location'> & { location: LatLngLiteral[] } = {
          ...omit(mapData, ['location']),
          location: [] as LatLngLiteral[],
        };

        const encodedLocation = mapData?.location;

        if (encodedLocation?.startsWith('SLP')) {
          const [decodedLat, decodedLng] = encodedLocation
            .substring(3)
            .split('|')
            .map(l => parseFloat(l));
          const decodedLocation = standartizeLocation({ lat: decodedLat, lng: decodedLng });

          decodedLocationData.location.push(decodedLocation);
        } else {
          try {
            decodedLocationData.location = polylineDecoder
              .decode(mapData?.location ?? '')
              .map(([decodedLat, decodedLng]) => standartizeLocation({ lat: decodedLat, lng: decodedLng }));
          } catch (err) {
            logger(err);
          }
        }

        return decodedLocationData;
      }) ?? [];

    if (trackingMethod === TrackingMethod.LiveRoute) {
      const mapPointsGroups = groupBy(rows, 'groupId');

      return Object.values(mapPointsGroups).map(mapPoints => {
        if (mapPoints.length === 1) {
          return mapPoints[0];
        }
        return mapPoints.reduce((prevPoint, nextPoint) => {
          const prevEntries = Object.entries(prevPoint);
          const mergedEntries = prevEntries.map(entry => {
            const [key, prevValue] = entry;
            const nextValue = nextPoint[key as keyof typeof nextPoint] as typeof prevValue;
            // If no next-value exists, just return previous one
            if (!nextValue) {
              return entry;
            }
            const mergedEntry = [key, prevValue];
            // If merged value is an array
            if (Array.isArray(prevValue) || Array.isArray(nextValue)) {
              // @ts-ignore
              mergedEntry[1] = [...(prevValue || []), ...(nextValue || [])];
            }
            // If merged value is a timestamp object with { start, end } schema
            // and we've received data from ws-event that doesn't have timestamp.end value
            if (
              key === 'timestamp' &&
              // prev value has 'start' date
              prevValue instanceof Object &&
              'start' in prevValue &&
              // next value has 'start' date field
              nextValue instanceof Object &&
              'start' in nextValue &&
              // ...but it doesn't contain valid 'end' value
              !nextValue.end
            ) {
              mergedEntry[1] = { start: prevValue.start, end: nextValue.start };
            }

            return mergedEntry;
          });

          return Object.fromEntries(mergedEntries);
        });
      });
    }

    if (trackingMethod === TrackingMethod.LivePoint) {
      const subs = groupBy(rows, 'groupId');

      return Object.values(subs).map(sub => sub[sub.length - 1]);
    }

    return rows;
  }, [data, trackingMethod]);

  const elements: MapElementType[] = useMemo(
    () =>
      decodedData.map(element => {
        const { id, groupId, name, timestamp, location: path, color } = element;

        if (showRssi) {
          const routeData = omit(element, ['location', 'id', 'timestamp', 'color']);

          return { id, name: name ?? groupId, color, path, routeData, timestamp } as MapElementType;
        }

        return { id, name: name ?? groupId, color, path, timestamp } as MapElementType;
      }),
    [decodedData, showRssi],
  );

  return (
    <>
      <MapStyles />
      <Spinner spinning={loading} className="spinner" wrapperClassName={cn('spinner-container', { spinning: loading })}>
        <div className="map-container">
          <MapContainer
            style={{ height: `${MAX_CONTAINER_HEIGHT + 18}px` }}
            className="map"
            center={defaultCenter}
            zoom={defaultZoom}
            scrollWheelZoom>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {elements.map(el => {
              const { id: key, name, color, timestamp, path, routeData } = el;

              let position;
              let pointData = {};

              switch (trackingMethod) {
                case TrackingMethod.LivePoint:
                  [position] = path;

                  if (routeData) {
                    Object.entries(routeData as Omit<DecodedMapData, 'location'>).forEach(
                      ([dataKey, pointDataEntries]) => {
                        if (pointDataEntries && (pointDataEntries as any[]).length) {
                          pointData = {
                            ...pointData,
                            [dataKey as keyof typeof pointData]: (
                              pointDataEntries as LivePointType['pointData'][keyof LivePointType['pointData']][]
                            )[0],
                          };
                        }
                      },
                    );
                  }

                  return (
                    <LivePoint
                      key={key}
                      position={position}
                      name={name}
                      pointData={pointData as LivePointType['pointData']}
                      timestamp={timestamp}
                      color={color}
                      showRssi={showRssi}
                      positioning={positioning}
                    />
                  );

                case TrackingMethod.LiveRoute:
                  position = path[path.length - 1];

                  return (
                    <LiveRoute
                      key={key}
                      name={name}
                      path={path}
                      position={position}
                      routeData={routeData}
                      timestamp={timestamp}
                      color={color}
                      showRssi={showRssi}
                      positioning={positioning}
                    />
                  );

                case TrackingMethod.Route:
                default:
                  return (
                    <Route
                      key={key}
                      name={name}
                      path={path}
                      routeData={routeData}
                      timestamp={timestamp}
                      color={color}
                      showRssi={showRssi}
                    />
                  );
              }
            })}
            {showRssi && (
              <Legend position={LEAFLET_CONTROL_POSITION_CLASSES.topright}>
                <h3>{t('Signal strength:')}</h3>
                <hr />
                <ul>
                  {Object.keys(RSSI_COLORS).map(key => {
                    const strength = key as Exclude<SignalStrength, typeof SIGNAL_STRENGTH['NO_SIGNAL']>;
                    const color = RSSI_COLORS[strength as typeof strength];
                    const iStyle = {
                      background: color,
                      width: '1rem',
                      height: '1rem',
                      display: 'inline-block',
                      verticalAlign: 'middle',
                      marginRight: '0.5rem',
                    };

                    return (
                      <li key={key}>
                        <i style={iStyle} />
                        <strong>{t(strength) + t('signal')}</strong>
                        <span>
                          {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                          {'('}
                          {t('from')}
                          <strong>
                            {` -${SIGNAL_STRENGTH_LIMITS[strength as typeof strength][1]} `}
                            <small>dBm </small>
                          </strong>
                          <span>{t('to')}</span>
                          <strong>
                            {` -${SIGNAL_STRENGTH_LIMITS[strength as typeof strength][0]} `}
                            <small>dBm </small>
                          </strong>
                          )
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </Legend>
            )}
          </MapContainer>
        </div>
      </Spinner>
    </>
  );
};

export default memo(Map);

export { elementColors } from '../map/constants';
