import React, { FC, memo, useContext, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { MAP } from 'react-google-maps/lib/constants';

import MapLocalContext from './local-context';

interface LegendProps {
  position: google.maps.ControlPosition;
}

const legendContainer = document.createElement('div');

const Legend: FC<LegendProps> = ({ position, children }) => {
  const localMapContext = useContext(MapLocalContext);
  const map = useMemo(() => localMapContext[MAP as keyof typeof localMapContext], [localMapContext]);

  useEffect(() => {
    if (map) {
      const controls = map.controls[position as keyof typeof map.controls];
      const legendIndex = controls.length;
      controls.push(legendContainer);

      return () => {
        controls.removeAt(legendIndex);
      };
    }

    return () => {};
  });

  return createPortal(<aside className="legend">{children}</aside>, legendContainer);
};

export default memo(Legend) as typeof Legend;
