import React, { FC, memo } from 'react';
import styled from 'styled-components';
//
import Drawer, { DrawerProps } from 'src/components/ui-kit/drawer';
//
const Container = styled(Drawer)<DrawerProps>`
  .ant-drawer-content-wrapper {
    width: 800px !important;
    max-width: 50vw;
  }
`;

//
const DetailsDrawer: FC<DrawerProps> = props => {
  const { children, onClose, open,...bypassProps } = props;
  return (
    <Container placement="right" closable={false} onClose={onClose} open={open} {...bypassProps}>
      {children}
    </Container>
  );
};

export default memo(DetailsDrawer);
