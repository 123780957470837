import React, { FC, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Divider } from 'antd';
import { ViewerContext, ViewerRoleContext } from '@fjedi/react-router-helpers';
import { FormItem } from 'src/components/ui-kit/form';
import { PasswordInput, PhoneNumberMaskedInput, TextArea } from 'src/components/ui-kit/input';
import { ViewerRole } from 'src/graphql/generated';

const ProfileDivider = styled(Divider)`
  margin-top: 1.2rem;
  margin-bottom: 0.75rem;
`;

const UserProfileAdditional: FC<{ userId: string }> = ({ userId }) => {
  const { t } = useTranslation();
  const viewerRole = useContext(ViewerRoleContext);
  const isAdmin = viewerRole === ViewerRole.Admin;

  return (
    <>
      <FormItem
        label={t('Phone')}
        name="phoneNumber"
        rules={[
          {
            required: false,
            message: 'Input phone number',
          },
        ]}>
        <PhoneNumberMaskedInput disabled={!isAdmin} />
      </FormItem>
      <FormItem
        label={t('Password')}
        name="password"
        rules={[
          {
            required: !userId,
            message: 'Input your password!',
          },
        ]}>
        <PasswordInput autoComplete="new-password" disabled={!isAdmin} />
      </FormItem>
      <FormItem
        label={t('Repeat Password')}
        name="repeatPassword"
        dependencies={['password']}
        rules={[
          {
            required: !userId,
            message: 'Confirm password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('The two passwords that you entered do not match!')));
            },
          }),
        ]}>
        <PasswordInput autoComplete="new-password" disabled={!isAdmin} />
      </FormItem>
      <FormItem label={t('Comments')} name="comments">
        <TextArea disabled={!isAdmin} />
      </FormItem>
    </>
  );
};

UserProfileAdditional.displayName = 'UserProfileAdditional';

export default memo(UserProfileAdditional);
