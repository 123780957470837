import React, { FC, ReactElement, useCallback, useMemo, useState } from 'react';

import { Popup } from 'react-leaflet';
import { colorTheme } from '../theme';

interface InfoWindowParams {
  title: string;
  content: string | ReactElement | JSX.Element;
  color?: string;
  defaultOpen?: boolean;
}
interface UseInfoWindowInstance {
  Info: FC;
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
}
export default function useInfoWindow({
  title,
  content,
  color = colorTheme.primary,
  defaultOpen = false,
}: InfoWindowParams): UseInfoWindowInstance {
  const [isInfoOpen, setIsInfoOpen] = useState(defaultOpen);
  const open = useCallback(() => setIsInfoOpen(true), []);
  const close = useCallback(() => setIsInfoOpen(false), []);
  const toggle = useCallback(() => setIsInfoOpen(isOpen => !isOpen), []);

  const Info = useMemo(
    () => () =>
      (
        <>
          <Popup onCloseClick={close} offset={[0, -10]}>
            <div className="info-block">
              <h3 style={{ color }}>{title}</h3>
              <p>{content}</p>
            </div>
          </Popup>
        </>
      ),
    [close, color, content, isInfoOpen, title],
  );

  return {
    Info,
    isOpen: isInfoOpen,
    open,
    close,
    toggle,
  };
}
