/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-danger */
// React
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { pure, compose } from 'recompose';
import { Tooltip as AntTooltip } from 'antd';

const Container = styled(AntTooltip)``;

//
const TooltipComponent = ({ children, ...props }) => <Container {...props}>{children}</Container>;

TooltipComponent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
};
TooltipComponent.defaultProps = {
  className: '',
};

export default compose(pure)(TooltipComponent);
