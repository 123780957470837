import React, { FC, memo, useCallback, useContext } from 'react';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
import getUserRolesQuery from 'src/graphql/queries/get-user-roles.graphql';
import removeUserRoleMutation from 'src/graphql/mutations/user-role/remove.graphql';
import Table from 'src/components/ui-kit/table';
import RoleSelector from 'src/components/common/role-selector';
import logger from 'src/helpers/logger';
import { updateAfterMutation, useApolloError, useMutation } from '@fjedi/graphql-react-components';
import updateUserRoleMutation from 'src/graphql/mutations/user-role/update.graphql';
import { UpdateUserRoleInput, UserRole, UserRoleRole } from 'src/graphql/generated';
import { ColumnsType } from 'antd/lib/table';
import { ViewerRoleContext } from '@fjedi/react-router-helpers';

const OrganizationProfileUserRoles: FC<{ companyId?: string; userId?: string; columns: ColumnsType }> = ({
  companyId,
  userId,
  columns,
}) => {
  const { t } = useTranslation();
  const viewerRole = useContext(ViewerRoleContext);
  const onError = useApolloError();
  const [updateUserRole] = useMutation(updateUserRoleMutation, {
    onError,
    update: updateAfterMutation('UserRole', 'userRoles'),
  });
  const onChangeField = useCallback(
    (id: UserRole['id'], input: UpdateUserRoleInput) => {
      //
      logger('UserRoles.onChangeField', {
        input,
      });
      if (Object.keys(input).length > 0) {
        updateUserRole({
          variables: { id, input },
          optimisticResponse: {
            updateUserRole: {
              id,
              __typename: 'UserRole',
              ...input,
            },
          },
        }).catch(logger);
      }
    },
    [updateUserRole],
  );

  return (
    <Table
      pagination
      pageSize={5}
      dataType="UserRole"
      query={getUserRolesQuery}
      removeRowMutation={viewerRole === UserRoleRole.Admin ? removeUserRoleMutation : undefined}
      removalConfirmationMessage={`${t('Remove')}?`}
      variables={{ filter: { companyId, userId } }}
      rowKey="id"
      columns={[
        ...columns,
        {
          title: t('Role'),
          dataIndex: 'role',
          width: 200,
          onChangeField,
          editable() {
            return viewerRole === UserRoleRole.Admin;
          },
          inputAlwaysVisible: true,
          input: RoleSelector,
          inputProps(record: UserRole) {
            return {
              initialValue: record?.role,
            };
          },
          render(role: UserRoleRole) {
            return t(capitalize(role));
          },
        },
      ]}
    />
  );
};

export default memo(OrganizationProfileUserRoles);
