import React, { FC, memo, ReactNode } from 'react';
import styled from 'styled-components';
//
import { Drawer as AntDrawer, DrawerProps as AntDrawerProps } from 'antd';

const StyledDrawer = styled(AntDrawer)`
  .ant-drawer-body {
    padding: 1rem;
  }
`;

const Drawer: FC<DrawerProps> = props => <StyledDrawer {...props} />;

Drawer.displayName = 'Drawer';

export default memo(Drawer);

export type DrawerProps = AntDrawerProps & { children: ReactNode };
