import React, { FC, memo, useMemo, useCallback, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Modal from 'antd/lib/modal';
import { useTranslation } from 'react-i18next';
import type { ModalProps, FormInstance } from 'antd';
import { RiFileCopyFill } from 'react-icons/ri';
import logger from 'src/helpers/logger';

import { Input, InputPrefixLabel } from 'src/components/ui-kit/input';
import { FormItem } from 'src/components/ui-kit/form';
import { colorTheme, colorBase } from 'src/components/ui-kit/theme';
import { useSetupGoogleAuthenticatorQuery } from 'src/graphql/generated';

const Container = styled(Modal)`
  &.ant-modal > .ant-modal-content {
    border-radius: 0.5rem;
    overflow: hidden;

    & > .ant-modal-header {
      border: 0;
    }

    & > .ant-modal-body {
      //padding: 0.25rem 2rem;

      display: flex;
      flex-direction: column;

      ${FormItem} {
        margin-bottom: 1rem;
      }

      & > ol {
        padding: 0 1rem;

        & > li:nth-of-type(3) {
          #qr-code {
            width: 10rem;
            height: 10rem;
            margin: 0.75rem auto 0;
            display: block;
          }

          ${FormItem} {
            margin-top: 0.25rem;
          }

          #secret {
            font-size: 0.875rem;
            cursor: pointer;
          }
        }
      }
    }

    & > .ant-modal-footer {
      border: 0;
      margin-top: auto;

      & > .ant-btn.ant-btn-primary {
        display: none;
      }
    }
  }
`;

const InputSuffixLabel = styled(InputPrefixLabel)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const blink = keyframes`
  from {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const TinyNotif = styled.div.attrs({ className: 'tiny-notif' })`
  position: fixed;
  bottom: 10rem;

  background: #fff;
  border: 0.5px solid ${colorBase.cyan} inset;
  border-radius: 0.5rem;

  transform-origin: center center;
  transform: translateX(7.65rem);

  font-size: 0.75rem;
  color: ${colorTheme.primary};
  text-align: center;
  padding: 0.25rem 0.5rem;

  animation-name: ${blink};
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
`;

export type ConnectAuthenticatorFormValues = {
  enabled: boolean;
  secret: string;
  code?: string;
};

interface ConnectAuthenticatiorModalProps extends ModalProps {
  formInstance: FormInstance<ConnectAuthenticatorFormValues>;
  isGaEnabled: boolean;
  isLoading?: boolean;
}

const notifDuration = 5000;

const ConnectAuthenticatorModal: FC<ConnectAuthenticatiorModalProps> = props => {
  const { open, onCancel, formInstance, isLoading, isGaEnabled } = props;
  const { t } = useTranslation();

  const [recentlyCopied, setRecentlyCopied] = useState(false);
  const toggleNotif = useCallback(() => {
    if (!recentlyCopied) {
      setRecentlyCopied(true);

      setTimeout(() => setRecentlyCopied(false), notifDuration);
    }
  }, [recentlyCopied]);

  const { data: setupGoogleAuthenticatorRes, loading: isGoogleAuthenticatorDataLoading } =
    useSetupGoogleAuthenticatorQuery({
      fetchPolicy: 'network-only',
    });
  const { qr, secret } = useMemo(
    () => setupGoogleAuthenticatorRes?.setupGoogleAuthenticator ?? { qr: undefined, secret: undefined },
    [setupGoogleAuthenticatorRes],
  );

  const isReady = useMemo(() => {
    const dataFetched = !isGoogleAuthenticatorDataLoading && !!qr && !!secret;

    if (dataFetched) {
      formInstance.setFieldsValue({ secret });
    }

    return dataFetched;
  }, [isGoogleAuthenticatorDataLoading, qr, secret, formInstance]);

  const handleSecretCopy = useCallback(() => {
    const id = 'secret';
    const inputElement = document.getElementById(id) as HTMLInputElement;

    if (inputElement) {
      inputElement.focus();
      inputElement.select();

      try {
        document.execCommand('copy');
        inputElement.blur();
        toggleNotif();
      } catch (copyError) {
        logger(copyError);
      }
    }
  }, [toggleNotif]);

  const inputAddonCopy = useMemo(
    () => (
      <InputSuffixLabel onClick={handleSecretCopy}>
        <RiFileCopyFill />
      </InputSuffixLabel>
    ),
    [handleSecretCopy],
  );

  return (
    <Container
      open={open}
      onCancel={onCancel}
      title={t('Connect Google Authenticator')}
      cancelButtonProps={{ type: 'link' }}
      cancelText={t('Закрыть')}
      okButtonProps={{
        type: 'link',
        disabled: !isReady,
        htmlType: 'submit',
        loading: isLoading,
        onClick() {
          formInstance.submit();
        },
      }}
      okText={t('Confirm')}
      closable={false}
      width={420}
      maskClosable
      keyboard
      centered
      destroyOnClose>
      <ol>
        <li>{t('Download Google Authenticator')}</li>
        <li>{t('Press "Add" button')}</li>
        <li>
          {t('Scan the QR code')}
          <br />
          {isReady && <img id="qr-code" src={qr} alt={secret} />}
          <br />
          {t('Or copy the key')}
          {isReady && (
            <FormItem name="secret">
              <Input size="small" name="secret" readOnly addonBefore={inputAddonCopy} onClick={handleSecretCopy} />
            </FormItem>
          )}
        </li>
        <li>
          {t('Enter confirmation code')}
          {isReady && (
            <FormItem
              name="code"
              labelCol={{ span: 24, offset: 0 }}
              label={t('Code')}
              rules={[
                { validateTrigger: ['onBlur', 'onChange'] },
                { pattern: /^\d{6}$/, message: t('Please enter 6-digit numeric code') as string },
                { required: true },
              ]}>
              <Input
                size="small"
                name="code"
                placeholder={t('Enter code') as string}
                style={{ margin: '-1rem 0 0 0' }}
              />
            </FormItem>
          )}
        </li>
      </ol>
      {recentlyCopied && <TinyNotif>{t('Code copied')}</TinyNotif>}
    </Container>
  );
};

export default memo(ConnectAuthenticatorModal) as typeof ConnectAuthenticatorModal;
