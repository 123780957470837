// React
import React, { memo, useContext, useCallback } from 'react';
import { ViewerContext, NotFound, Redirect, Route, Link } from '@fjedi/react-router-helpers';
import PropTypes from 'prop-types';
import { compose, getContext } from 'recompose';
import { getServerURL } from 'kit/lib/env';
import styled from 'styled-components';
//
import Helmet from 'react-helmet';
//
import Card from 'src/components/ui-kit/card';

export function setHTMLTitle(title) {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
}

//
const ReportButton = styled.button`
  color: #fff;
  font-size: 1.25rem;
  border: 1px solid #fff;
  padding: 0 1.5rem;
  line-height: 2;
`;

// Create a route that will be displayed when the code isn't found
const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;
const H1 = styled.h1`
  margin-top: 1rem;
  color: #555;
  font-family: ${props => props.theme.fontFamily};
`;
const LINK = styled(Link)`
  display: inline-block;
  margin: 1rem auto;
  text-decoration: underline;
`;
export const WhenNotFound = () => (
  <NotFound>
    <Card>
      <NotFoundContainer>
        <H1>Ooops! No such page found :(</H1>
        <LINK to="/">To main page</LINK>
      </NotFoundContainer>
    </Card>
  </NotFound>
);

export const PrivateRoute = ({ component: Component, ...props }) => {
  const viewer = useContext(ViewerContext);
  const render = useCallback(p => {
    if (viewer) {
      return <Component {...p} />;
    }
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: p.location },
        }}
      />
    );
  }, []);

  return <Route {...props} render={render} />;
};
PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.element]).isRequired,
  path: PropTypes.string.isRequired,
  location: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
PrivateRoute.defaultProps = {
  location: '',
};

export const SEOTags = compose(
  getContext({
    baseURL: PropTypes.string.isRequired,
  }),
  memo,
)(({ baseURL, title, description, poster }) => {
  const canonicalURL = baseURL.includes('http') ? baseURL : `${getServerURL()}${baseURL}`;
  const sanitizedDescription =
    typeof description === 'string' && description ? description.replace(/<[^>]*>?/gm, '').trim() : '';
  return (
    <Helmet>
      {!!title && <title>{title}</title>}
      {!!title && <meta name="title" content={title} />}
      {!!sanitizedDescription && <meta name="description" content={sanitizedDescription} />}
      <link rel="canonical" href={canonicalURL} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={baseURL} />
      {!!title && <meta property="og:title" content={title} />}
      {!!sanitizedDescription && <meta property="og:description" content={sanitizedDescription} />}
      <meta property="og:image" content={poster} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={baseURL} />
      {!!title && <meta property="twitter:title" content={title} />}
      {!!sanitizedDescription && <meta property="twitter:description" content={sanitizedDescription} />}
      <meta property="twitter:image" content={poster} />
    </Helmet>
  );
});

export class SentryBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
    this.sendReport = this.sendReport.bind(this);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Sentry.componentDidCatch', {
      error,
      errorInfo,
    });
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  sendReport() {
    Sentry.showReportDialog({ eventId: this.state.eventId });
  }

  render() {
    if (this.state.hasError) {
      const title = 'Something has gone wrong';
      const description = "Sorry, we're expiriencing some issues but we're working on that";
      const reportButtonText = 'Report feedback';
      // render fallback UI
      return (
        <NotFound>
          <NotFoundContainer>
            <div>
              <h1>{title}</h1>
              <p>{description}</p>
              <ReportButton onClick={this.sendReport}>{reportButtonText}</ReportButton>
            </div>
          </NotFoundContainer>
        </NotFound>
      );
    }

    // when there's not an error, render children untouched
    return this.props.children;
  }
}
