/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, memo, useMemo } from 'react';

import Marker from 'react-google-maps/lib/components/Marker';

import { formatDate } from 'src/helpers/time';

import Rssi from './rssi';
import { composeMarkerProps } from './helpers';
import { MARKER_POSITIONS } from './constants';

import type { RssiRouteData } from './rssi';
import type { LivePointType } from '.';
import useInfoWindow from './use-info-window';

interface LivePointProps extends LivePointType {
  positioning: boolean;
}

// TODO: implement positioning

const LivePoint: FC<LivePointProps> = ({ position, name, color, timestamp, showRssi, pointData, positioning }) => {
  const markerProps = useMemo(() => composeMarkerProps(color, MARKER_POSITIONS.END), [color]);
  const rssiData: RssiRouteData[] = useMemo(
    () => (pointData ? [{ location: new window.google.maps.LatLng(position.lat, position.lng), ...pointData }] : []),
    [pointData, position],
  );
  const positionTimestamp = useMemo(() => {
    const dateFormat = 'DD-MM-YYYY HH:mm:ss';
    return formatDate(timestamp.end ?? timestamp.start, dateFormat);
  }, [timestamp.end, timestamp.start]);

  const { Info, toggle: toggleInfo } = useInfoWindow({
    title: name,
    content: positionTimestamp,
    color,
    defaultOpen: true,
  });

  return (
    <>
      <Marker position={position} {...markerProps} onClick={toggleInfo}>
        <Info />
      </Marker>
      {showRssi && <Rssi data={rssiData} />}
    </>
  );
};

export default memo(LivePoint) as typeof LivePoint;
