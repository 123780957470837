/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  ChangeEvent,
  ComponentType,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import uniqBy from 'lodash/uniqBy';
import compact from 'lodash/compact';
import AntSimpleDatePicker from 'antd/lib/date-picker';
import type { PickerProps as DatePickerProps, RangePickerProps } from 'antd/lib/date-picker/generatePicker';
import { useAliasesQuery, useSubscribersQuery, Alias, Subscriber, Viewer } from 'src/graphql/generated';
import time, { TimeInstance } from 'src/helpers/time';
import logger from 'src/helpers/logger';
import { RestrictedArea, ViewerContext } from '@fjedi/react-router-helpers';
import { Input } from 'src/components/ui-kit/input';
import CustomButton from 'src/components/ui-kit/buttons';
import { Form, FormItem as CustomFormItem } from 'src/components/ui-kit/form';
import Select from 'src/components/ui-kit/select';
import type { TagRenderProps } from 'src/components/ui-kit/select';
import Tag from 'src/components/ui-kit/tag';
import { colorGrays, colorTheme } from 'src/components/ui-kit/theme';
import SubscriberSelector from 'src/components/common/subscribers-selector';
import HelpIndicator from './help-indicator';
import SwitchInput from './switch-input';
import { DatePickerFormats, TrackingMethod, LocationUncertainty } from './constants';
import { getSessionDefaults } from './helpers';

const { RangePicker } = AntSimpleDatePicker;
const SimpleDatePicker = AntSimpleDatePicker as ComponentType<DatePickerProps<TimeInstance>>;
const RangeDatePicker = RangePicker as ComponentType<RangePickerProps<TimeInstance>>;
const Button = CustomButton as FC<Partial<Parameters<typeof CustomButton>[0]>>;

interface FormContentProps {
  isRoadsBindingAllowed: boolean;
  isPositioningAllowed: boolean;
  isTimeRangeAllowed: boolean;
}

const FormContent = styled.div<FormContentProps>`
  width: 500px;
  min-width: 500px;
  max-width: 500px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: repeat(9, auto);
  gap: 0.625rem 0;
  align-items: stretch;
  justify-items: stretch;

  .rssi {
    grid-column: 1 / span 2;
    grid-row: 1;
  }

  .remoteCompanyId {
    grid-column: 1 / span 2;
    grid-row: 2;
  }

  .callingSsiId {
    grid-column: 1 / span 2;
    grid-row: 3;
  }

  .trackingMethod {
    grid-column: 1 / span 2;
    grid-row: 4;
  }

  .locationUncertainty {
    grid-column: 1 / span 2;
    grid-row: 5;
  }

  .timeRange {
    grid-column: 1 / span 2;
    grid-row: 6;
  }

  .roadsBinding {
    grid-column: 1 / span 2;
    grid-row: ${({ isTimeRangeAllowed }) => (isTimeRangeAllowed ? 7 : 6)};
  }

  .positioning {
    grid-column: 1 / span 2;
    grid-row: ${({ isTimeRangeAllowed, isRoadsBindingAllowed }) => {
      if (isTimeRangeAllowed) {
        return isRoadsBindingAllowed ? 8 : 7;
      }

      return isRoadsBindingAllowed ? 7 : 6;
    }};
  }

  .ant-btn {
    border-radius: 0.625rem;
    grid-column: 1 / span 2;
    grid-row: ${({ isTimeRangeAllowed, isRoadsBindingAllowed, isPositioningAllowed }) => {
      let row = 9;
      if (!isTimeRangeAllowed) {
        row -= 1;
      }
      if (!isRoadsBindingAllowed) {
        row -= 1;
      }
      if (!isPositioningAllowed) {
        row -= 1;
      }

      return row;
    }};
  }
`;

const FormItem = styled(CustomFormItem)`
  position: relative;
  //min-height: 4.375rem;
  max-width: 100%;
  display: block;

  &.ant-form-item {
    margin-bottom: 0;
  }

  & > .ant-form-item-control {
    height: auto;

    & > .ant-form-item-explain {
      margin-top: 0.25rem;
    }
  }

  .ant-input-affix-wrapper {
    /* height: 2.5rem; */
    padding-top: 0;
    padding-bottom: 0;
    align-items: center;

    & > input {
      font-size: 0.9375rem;
      color: ${colorTheme.secondary};
      min-height: auto;

      &::placeholder {
        color: ${colorGrays.gray400};
      }
    }
  }

  & > .ant-form-item-label {
    text-align: left;
  }

  .ant-picker {
    width: 100%;
    height: 2.625rem;
  }

  ${Input}, .ant-picker {
    border-radius: 0.625rem;

    &::placeholder {
      color: ${colorGrays.gray500};
    }
  }

  .help-indicator {
    position: absolute;
    bottom: calc(100% + 0.5rem);
    right: 0;
    height: 1rem;
  }

  .ant-select {
    & > .ant-select-selector {
      border-radius: 0.625rem;
      padding-inline-end: 0;
      //height: auto;
      //padding-left: 0.375rem;

      .ant-select-selection-overflow-item-rest,
      .ant-tag {
        margin-top: 0.15rem;
        margin-bottom: 0.15rem;
      }

      .ant-tag {
        font-weight: 600;
        //font-size: 1em;
        //line-height: 0.95;
        //padding: 0.35rem;
        color: ${colorTheme.light};
        //display: inline-flex;
        //align-items: center;
        //justify-content: space-between;
        width: calc(100% - 0.5rem);
      }

      & > .ant-select-selection-overflow {
        max-width: calc(100% - 2.125rem);

        & > .ant-select-selection-overflow-item {
          //flex-grow: 1;
          //max-width: 25%;
          //min-width: fit-content;

          &.ant-select-selection-overflow-item-suffix {
            max-width: initial;
            min-width: 25%;
            //margin-bottom: 0.375rem;
          }

          & + .ant-select-selection-overflow-item-suffix {
            border-radius: 0.3rem;
            outline: 0.5px solid ${colorTheme.light};

            transition: outline 0.2s linear;

            &:hover {
              outline-color: ${colorTheme.primary};
            }
          }

          &.ant-select-selection-overflow-item-rest {
            & > .ant-select-selection-item {
              height: fit-content;
              bottom: 0.175rem;
              font-weight: 600;
              font-size: 1em;
              line-height: 1em;
              padding: 0.35rem 1rem;
              color: ${colorTheme.light};
              background: ${colorTheme.primary};
              border-radius: 0.3rem;
            }
          }
        }
      }
    }
  }
`;

export interface Filters {
  rssi?: boolean;
  remoteCompanyId?: string[];
  callingSsiId?: string[];
  trackingMethod?: TrackingMethod;
  locationUncertainty?: LocationUncertainty;
  timeRange?: [TimeInstance, TimeInstance] | TimeInstance;
  roadsBinding?: boolean;
  positioning?: boolean;
}

interface FiltersFormProps {
  onSubmit: (_values: Filters) => void;
  isLoading: boolean;
}

const FiltersForm: FC<FiltersFormProps> = ({ onSubmit, isLoading: mapLoading }) => {
  const { t } = useTranslation();
  const viewer = useContext(ViewerContext) as Viewer;
  const { data: aliasesRes, loading: aliasesLoading } = useAliasesQuery({
    fetchPolicy: 'cache-and-network',
  });
  const aliases: Alias[] = useMemo(() => aliasesRes?.aliases?.rows ?? [], [aliasesRes?.aliases?.rows]);
  const { data: subscribersRes, loading: subscribersLoading } = useSubscribersQuery({});
  const subscribers: Subscriber[] = useMemo(
    () =>
      subscribersRes?.subscribers?.rows?.map(({ id, companyId, companyName }: Omit<Subscriber, 'alias'>) => {
        const alias = aliases.find(({ subscriberId }) => subscriberId === id)?.subscriberAlias;

        return { id, companyId, companyName, alias };
      }) ?? [],
    [aliases, subscribersRes?.subscribers?.rows],
  );
  const companies = useMemo(
    () =>
      uniqBy(subscribers, 'companyId')?.map(
        ({ companyId, companyName }) => !!companyId && { companyId, companyName },
      ) ?? [],
    [subscribers],
  );
  const isLoading = useMemo(
    () => aliasesLoading || subscribersLoading || mapLoading,
    [aliasesLoading, mapLoading, subscribersLoading],
  );
  // parsing data required by filters' inputs
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const companiesOptions = useMemo(
    () =>
      compact(companies)
        .map(({ companyId, companyName }) => ({ label: companyName ?? companyId, value: companyId }))
        .sort((cA, cB) => parseInt(cA.value, 10) - parseInt(cB.value, 10)) ?? [],
    [companies],
  );
  console.info('companiesOptions', companiesOptions);
  //
  const trackingMethodOptions = useMemo(
    () => [
      { label: t('Route'), value: TrackingMethod.Route },
      { label: t('Live Point'), value: TrackingMethod.LivePoint },
      { label: t('Live Route'), value: TrackingMethod.LiveRoute },
    ],
    [t],
  );
  //
  const locationUncertaintyOptions = useMemo(
    () =>
      compact(
        Object.keys(LocationUncertainty).map(key =>
          key.startsWith('Level')
            ? {
                label: `${key.replace('Level', '')}${t('m')}`,
                value: LocationUncertainty[key as keyof typeof LocationUncertainty],
              }
            : null,
        ),
      ),
    [t],
  );
  //
  const fieldsHelp = useMemo(() => {
    const helpText = {
      rssi: "Display network coverage areas along subscribers' paths/routes on the map",
      trackingMethod: [
        "Define how to display subscribers' location and/or path on the map.",
        '\n',
        '1) Route.',
        "Display subscribers' path during selected time range.",
        '\r',
        '2) Live Point.',
        "Display subscribers' realtime location.",
        '\r',
        '3) Live Route.',
        'Route and Live Point method combined;',
        "Display subscribers' path since selected date until present moment, and update it live.",
      ],
      roadsBinding: 'Force snapping routes to the most likely roads subscriber was traveling along',
      positioning: 'Force map to center on the location/route',
    };

    return Object.fromEntries(
      Object.entries(helpText).map(([key, text]) => {
        const i18text = typeof text === 'string' ? t(text) : text.map(chunk => t(chunk));
        return [key, i18text];
      }),
    );
  }, [t]);

  const [datePickerFormat, setDatePickerFormat] = useState<(typeof DatePickerFormats)[keyof typeof DatePickerFormats]>(
    DatePickerFormats.Range,
  );

  const [allowPositioning, setAllowPositioning] = useState(false);
  const [allowRoadsBinding, _setAllowRoadsBinding] = useState(false);

  // form initialization
  const [form] = Form.useForm<Filters>();
  const { setFieldsValue, getFieldsValue } = form;

  const defaultTrackingMethod = useMemo(() => TrackingMethod.Route, []);
  const defaultStart = useMemo(() => time().add(-1, 'day'), []);
  const defaultEnd = useMemo(() => time(), []);

  const initialValues: Filters = useMemo(() => {
    let timeRange: undefined | TimeInstance | [TimeInstance, TimeInstance] = [defaultStart, defaultEnd];

    if (datePickerFormat === DatePickerFormats.Start) {
      timeRange = defaultStart;
    } else if (datePickerFormat === DatePickerFormats.None) {
      timeRange = undefined;
    }

    let companyIds: string[] = [];
    let ids: string[] = [];
    const { remoteCompanyId, callingSsiId } = getSessionDefaults();
    if (remoteCompanyId?.length) {
      companyIds = remoteCompanyId.split(',');
      setSelectedCompanies(companyIds);
    }
    if (callingSsiId?.length) {
      ids = callingSsiId.split(',');
    }

    return {
      rssi: false,
      remoteCompanyId: companyIds,
      callingSsiId: !ids.length
        ? companyIds.length
          ? compact(subscribers.map(({ companyId, id }) => (companyIds.includes(companyId as string) ? id : null)))
          : ids
        : ids,
      trackingMethod: defaultTrackingMethod,
      locationUncertainty: undefined,
      roadsBinding: false,
      positioning: false,
      timeRange,
    };
  }, [subscribers, datePickerFormat, defaultEnd, defaultStart, defaultTrackingMethod]);

  const handleCompaniesClear = useCallback(() => {
    setFieldsValue({ remoteCompanyId: [], callingSsiId: [] });
    setSelectedCompanies([]);
    sessionStorage.setItem('remoteCompanyId', '');
    sessionStorage.setItem('callingSsiId', '');
  }, [setFieldsValue]);

  const handleCompaniesFilter = useCallback((searchString, option) => {
    const search = searchString.toLowerCase();
    const opts = [`${option.label}`.toLowerCase(), `${option.value}`.toLowerCase()];

    return opts.some(opt => opt.includes(search));
  }, []);

  /* const isAllSubscribersSelected = useMemo(() => {
    const { remoteCompanyId, callingSsiId } = getFieldsValue();

    if (remoteCompanyId instanceof Array && callingSsiId instanceof Array) {
      const companySubs = subscribers.filter(({ companyId }) => remoteCompanyId.includes(companyId as string));

      return callingSsiId.length === companySubs.length;
    }

    return false;
  }, [subscribers, getFieldsValue]); */
  // non-default input handlers
  const handleSelectChange = useCallback(
    (field: string) => (value: unknown) => {
      const loggerGroup = `Select.onChange('${field}'):`;
      let preventDefault = false;

      if (field === 'trackingMethod') {
        const { callingSsiId } = getFieldsValue();
        const isPositioningAllowed = !!callingSsiId && callingSsiId.length === 1;

        switch (value) {
          case TrackingMethod.LivePoint:
            setDatePickerFormat(DatePickerFormats.None);
            setFieldsValue({ timeRange: undefined });
            setAllowPositioning(isPositioningAllowed);
            // setAllowRoadsBinding(false);
            break;
          case TrackingMethod.LiveRoute:
            setDatePickerFormat(DatePickerFormats.Start);
            setFieldsValue({ timeRange: defaultStart });
            setAllowPositioning(isPositioningAllowed);
            // setAllowRoadsBinding(true);
            break;
          case TrackingMethod.Route:
          default:
            setDatePickerFormat(DatePickerFormats.Range);
            setFieldsValue({ timeRange: [defaultStart, defaultEnd] });
            setAllowPositioning(false);
          // setAllowRoadsBinding(true);
        }
      }

      if (field === 'callingSsiId') {
        const { trackingMethod, remoteCompanyId } = getFieldsValue();
        let isPositioningAllowed = false;
        let selectedSubscribers: string[] = [];

        if (value instanceof Array<string>) {
          isPositioningAllowed = trackingMethod !== TrackingMethod.Route && value.length === 1;

          if (value.length === 1 && value[0] === 'all' && remoteCompanyId instanceof Array<string>) {
            preventDefault = true;
            selectedSubscribers = compact(
              subscribers.map(({ id, companyId }) => (remoteCompanyId.includes(companyId as string) ? id : null)),
            );
          } else {
            selectedSubscribers = value;
          }
        }

        setAllowPositioning(isPositioningAllowed);
        setFieldsValue({ callingSsiId: selectedSubscribers });
      }

      if (field === 'remoteCompanyId') {
        const { trackingMethod, callingSsiId } = getFieldsValue();
        const isPositioningAllowed = trackingMethod !== TrackingMethod.Route && callingSsiId?.length === 1;

        if (value instanceof Array<string>) {
          setSelectedCompanies(value);
          setAllowPositioning(isPositioningAllowed);

          // if ((!callingSsiId || !callingSsiId?.length) && !!value.length) {
          //   handleSelectChange('callingSsiId')(['all']);
          // }
        } else {
          handleCompaniesClear();
          setAllowPositioning(trackingMethod !== TrackingMethod.Route);
        }
      }

      if (!preventDefault) {
        setFieldsValue({ [field]: value });
      }

      logger(loggerGroup, { value, preventDefault });
    },
    [subscribers, defaultEnd, defaultStart, getFieldsValue, handleCompaniesClear, setFieldsValue],
  );

  useEffect(() => {
    if (selectedCompanies.length) {
      sessionStorage.setItem('remoteCompanyId', selectedCompanies.join(','));
    }
  }, [selectedCompanies]);

  const handleDatePickerChange: DatePickerProps<TimeInstance>['onChange'] = useCallback(
    value => {
      setFieldsValue({ timeRange: value });
      logger('FiltersForm.startDate changed', { value });
    },
    [setFieldsValue],
  );

  const handleRangePickerChange: RangePickerProps<TimeInstance>['onChange'] = useCallback(
    value => {
      setFieldsValue({ timeRange: value });
      logger('FiltersForm.timeRange changed', { value });
    },
    [setFieldsValue],
  );

  const handleSwitchChange = useCallback(
    (checked: boolean, event: ChangeEvent<HTMLInputElement>) => {
      const { name: field } = event.currentTarget;

      setFieldsValue({ [field]: checked });
    },
    [setFieldsValue],
  );

  const handleFinish = useCallback(
    (values: unknown) => {
      const { callingSsiId: selectedSubs, remoteCompanyId, ...filters } = values as Filters;
      let callingSsiId = selectedSubs ?? [];

      if (!callingSsiId.length && !!remoteCompanyId!.length) {
        callingSsiId = compact(
          subscribers.map(({ id, companyId }) => (remoteCompanyId!.includes(companyId as string) ? id : null)),
        );

        handleSelectChange('callingSsiId')(['all']);
      }

      if (remoteCompanyId instanceof Array) {
        onSubmit({ ...filters, remoteCompanyId, callingSsiId });
      }
    },
    [subscribers, handleSelectChange, onSubmit],
  );

  const disabledDate = useCallback(date => time(date).isAfter(defaultEnd), [defaultEnd]);

  const onPreventMouseDown = useCallback(event => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const companyTagRender = useCallback(
    (props: TagRenderProps) => {
      const { label, ...tagProps } = props;

      return (
        <Tag color={colorTheme.primary} onMouseDown={onPreventMouseDown} {...tagProps}>
          {label}
        </Tag>
      );
    },
    [onPreventMouseDown],
  );

  const maxTagPlaceholder = useMemo(() => `...${t('others')}`, [t]);

  return (
    <Form className="filters-form" form={form} initialValues={initialValues} onFinish={handleFinish} layout="vertical">
      <FormContent
        isPositioningAllowed={allowPositioning}
        isRoadsBindingAllowed={allowRoadsBinding}
        isTimeRangeAllowed={datePickerFormat !== DatePickerFormats.None}>
        <RestrictedArea areaType="block" currentRole={viewer.role} allowedRoles={['ADMIN', 'OPERATOR']}>
          <FormItem label={t('RSSI')} name="rssi" className="rssi" rules={[{ required: true }]}>
            {/* <HelpIndicator text={fieldsHelp.rssi} /> */}
            <SwitchInput name="rssi" onChange={handleSwitchChange} />
          </FormItem>
        </RestrictedArea>
        <RestrictedArea areaType="block" currentRole={viewer.role} allowedRoles={['ADMIN', 'OPERATOR']}>
          <FormItem
            label={t('Company ID / Name')}
            name="remoteCompanyId"
            className="remoteCompanyId"
            rules={[{ required: true }]}>
            <Select
              options={companiesOptions}
              mode="multiple"
              placeholder={t('Enter ID')}
              onChange={handleSelectChange('remoteCompanyId')}
              onClear={handleCompaniesClear}
              tagRender={companyTagRender}
              filterOption={handleCompaniesFilter}
              allowClear
              showArrow
              showSearch
            />
          </FormItem>
        </RestrictedArea>

        <FormItem
          label={t("Abonent's ID / Alias")}
          name="callingSsiId"
          className="callingSsiId"
          dependencies={['remoteCompanyId']}>
          <SubscriberSelector
            onChange={handleSelectChange('callingSsiId')}
            queryVariables={{ filter: { remoteCompanyId: selectedCompanies ?? undefined } }}
            maxTagCount={/* isAllSubscribersSelected ? 'responsive' : */ 43}
            maxTagPlaceholder={maxTagPlaceholder}
          />
        </FormItem>

        <FormItem
          label={t('Tracking method')}
          name="trackingMethod"
          className="trackingMethod"
          dependencies={['timeRange']}
          rules={[{ required: true }]}>
          <HelpIndicator text={fieldsHelp.trackingMethod} />
          <Select
            options={trackingMethodOptions}
            defaultValue={trackingMethodOptions[0].label}
            onChange={handleSelectChange('trackingMethod')}
          />
        </FormItem>

        <RestrictedArea areaType="block" currentRole={viewer.role} allowedRoles={['ADMIN', 'OPERATOR']}>
          <FormItem
            label={t('Location accuracy')}
            name="locationUncertainty"
            className="locationUncertainty"
            rules={[{ required: true }]}>
            <Select
              options={locationUncertaintyOptions}
              placeholder={t('Select location accuracy')}
              onChange={handleSelectChange('locationUncertainty')}
              allowClear
              showArrow
            />
          </FormItem>
        </RestrictedArea>

        <FormItem
          label={datePickerFormat === DatePickerFormats.Start ? t('Start time') : t('Time range')}
          name="timeRange"
          className="timeRange"
          rules={[{ required: datePickerFormat !== DatePickerFormats.None }]}
          dependencies={['trackingMethod']}
          hidden={datePickerFormat === DatePickerFormats.None}>
          {datePickerFormat === DatePickerFormats.Start && (
            <SimpleDatePicker
              defaultValue={initialValues.timeRange as TimeInstance}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: 'HH:mm' }}
              onChange={handleDatePickerChange}
              disabledDate={disabledDate}
            />
          )}
          {datePickerFormat === DatePickerFormats.Range && (
            <RangeDatePicker
              defaultValue={initialValues.timeRange as [TimeInstance, TimeInstance]}
              format="DD-MM-YYYY HH:mm"
              showTime={{ format: 'HH:mm' }}
              onChange={handleRangePickerChange}
            />
          )}
        </FormItem>

        <FormItem
          hidden={!allowRoadsBinding}
          label={t('Roads binding')}
          name="roadsBinding"
          className="roadsBinding"
          dependencies={['trackingMethod']}>
          <HelpIndicator text={fieldsHelp.roadsBinding} />
          {allowRoadsBinding && <SwitchInput name="roadsBinding" onChange={handleSwitchChange} />}
        </FormItem>

        <FormItem
          hidden={!allowPositioning}
          label={t('Positioning')}
          name="positioning"
          className="positioning"
          dependencies={['trackingMethod', 'callingSsiId']}>
          <HelpIndicator text={fieldsHelp.positioning} />
          {allowPositioning && <SwitchInput name="positioning" onChange={handleSwitchChange} />}
        </FormItem>

        <Button htmlType="submit" type="primary" size="large" disabled={isLoading}>
          {t('Apply')}
        </Button>
      </FormContent>
    </Form>
  );
};

export default memo(FiltersForm);
