import omit from 'lodash/omit';
import zipWith from 'lodash/zipWith';
import compact from 'lodash/compact';

import type { MarkerProps } from 'react-google-maps/lib/components/Marker';
import type { PolylineProps } from 'react-google-maps/lib/components/Polyline';

import { setOpacity } from 'src/components/ui-kit/utilities';

import type { RssiRouteData } from './rssi';
import { circlePath, elementColors, MARKER_POSITIONS, pinPath, RSSI_COLORS, SIGNAL_STRENGTH } from './constants';
import defaultMarkerIcon from './default-marker-icon.svg';

export function modulo(dividend: number, divider: number): number {
  const remainder: number = dividend % divider;
  const roundedDividend: number = remainder + divider;

  return roundedDividend % divider;
}

export function getElementColor(elIndex: number) {
  const colorIndex = elIndex >= elementColors.length ? modulo(elIndex, elementColors.length) : elIndex;

  return elementColors[colorIndex as number];
}

export type SignalStrength = typeof SIGNAL_STRENGTH[keyof typeof SIGNAL_STRENGTH];
export function getSignalStrengthGradient(signalStrength: SignalStrength): string[] {
  const color = RSSI_COLORS[signalStrength as keyof typeof RSSI_COLORS];
  const gradient = [-0.25, -0.15, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5].map(alpha =>
    setOpacity(color, alpha + 0.25),
  );
  return gradient;
}

export type WeightedData = Pick<RssiRouteData, 'location' | 'weight'>;
export function getHeatmapData(data: RssiRouteData[]): WeightedData[] {
  return data.map(layer => omit(layer, ['dBm', 'signalStrength']));
}

export type MarkerPosition = typeof MARKER_POSITIONS[keyof typeof MARKER_POSITIONS];
export function composeMarkerProps(color: string, position?: MarkerPosition): MarkerProps {
  const icon = {
    fillColor: color,
    fillOpacity: 1,
    scale: 2,
    strokeWeight: 0,
    strokeOpacity: 1,
    strokeColor: color,
    anchor: { x: 6, y: 18 },
  } as Partial<Omit<google.maps.Icon & google.maps.Symbol, 'anchor'>> & { anchor: { x: number; y: number } };

  switch (position) {
    case MARKER_POSITIONS.START:
      icon.path = circlePath;
      icon.strokeWeight = 4;
      icon.scale = 1.5;
      icon.anchor = { x: 9, y: 9 };
      break;
    case MARKER_POSITIONS.END:
      icon.path = pinPath;
      break;
    default:
      icon.url = defaultMarkerIcon;
  }

  return {
    clickable: true,
    cursor: 'pointer',
    icon,
  } as MarkerProps;
}

export function composePolylineProps(color: string): PolylineProps {
  const strokeColor = setOpacity(color, 0.75);

  return {
    strokeColor,
    strokeWeight: 5,
    clickable: true,
    geodesic: true,
  } as PolylineProps;
}

interface RouteData {
  dBm: RssiRouteData['dBm'][] | null;
  signalStrength: SignalStrength[] | null;
  weight: RssiRouteData['weight'][] | null;
}
export function composeRssiProps(
  path: google.maps.LatLngLiteral[],
  routeData: RouteData,
  bindToRoads: boolean = false,
): RssiRouteData[] {
  const { weight: weights, dBm: dBms, signalStrength: signalStrengths } = routeData ?? {};

  // if (bindToRoads) {
  //   let targetIndex = 0;
  //   const originalIndexes = weights?.reduce((indexes, currWeight) => {
  //     targetIndex += currWeight;
  //     return [...indexes, targetIndex];
  //   }, [] as number[]);

  //   return zipWith(originalIndexes ?? [], dBms ?? [], signalStrengths ?? [], (originalIndex, ...data) => {
  //     const { lat, lng } = path[(originalIndex - 1) as number];
  //     const location = new window.google.maps.LatLng(lat, lng);
  //     const [dBm, signalStrength] = data;

  //     return {
  //       location,
  //       dBm,
  //       weight: 1,
  //       signalStrength,
  //     } as RssiRouteData;
  //   });
  // }

  return compact(
    zipWith(path ?? [], dBms ?? [], weights ?? [], signalStrengths ?? [], (location, dBm, weight, signalStrength) => {
      if (location && signalStrength !== SIGNAL_STRENGTH.NO_SIGNAL) {
        return {
          location: new window.google.maps.LatLng(location.lat, location.lng),
          dBm,
          weight,
          signalStrength,
        };
      }

      return null;
    }),
  );
}
