import { memo } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import AntSelect from 'antd/lib/select';
import type { SelectProps } from 'antd/lib/select';

export const Select = styled(AntSelect)`
  &.ant-select {
    display: block;

    .ant-select-selector {
      min-height: 36px;
      padding-top: 2px;
      //padding-right: 2px;
    }

    &.ant-select-multiple {
      .ant-select-selector {
        //padding: 6px 0 0 6px;
      }
    }
  }
`;

export const SelectOption = styled(AntSelect.Option)``;

type TagRenderProps = Parameters<Required<Pick<SelectProps, 'tagRender'>>['tagRender']>[0];

export default memo(Select) as FC<SelectProps>;
export type { SelectProps, TagRenderProps };
