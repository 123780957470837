import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { withStateHandlers, withHandlers, withProps, compose, pure } from 'recompose';
//
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button as AntButton } from 'antd';
import logger from 'src/helpers/logger';
//
import FileUploader from './index';

const Button = styled(AntButton)`
  //margin: 0.5rem 0 0 0;
  margin: 0;
  padding: 0.2rem 0.65rem 0;
  display: inline-block;

  > span {
    font-size: 1rem;
    line-height: 2rem;
    white-space: nowrap;
  }
`;

const SubmitButton = styled(Button)`
  &.ant-btn.ant-btn-primary {
    margin: 1rem auto 0;
    height: auto;
    padding-top: 0;
  }
`;

const ExtendedFileUploader = ({
  beforeUpload,
  onRemove,
  fileList,
  renderSubmitButton,
  multiple,
  children,
  ...bypassProps
}) => (
  <FileUploader
    renderSubmitButton={renderSubmitButton}
    multiple={multiple}
    showPopup
    fileList={fileList}
    beforeUpload={beforeUpload}
    onRemove={onRemove}>
    {props => {
      if (children) {
        if (typeof children === 'function') {
          return children({ ...bypassProps, ...props });
        }
        return cloneElement(children, {
          ...bypassProps,
          ...props,
        });
      }
      return <Button type="primary" icon="paper-clip" {...props} />;
    }}
  </FileUploader>
);

ExtendedFileUploader.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types, react/forbid-prop-types
  fileList: PropTypes.array.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  updateState: PropTypes.func.isRequired,
  renderSubmitButton: PropTypes.func.isRequired,
  beforeUpload: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  multiple: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.element]),
};
ExtendedFileUploader.defaultProps = {
  children: undefined,
};

export default compose(
  withTranslation(),
  withProps(({ multiple }) => ({
    multiple: multiple || false,
  })),
  withStateHandlers(
    () => ({
      fileList: [],
    }),
    {
      updateState:
        state =>
        (data = {}) => ({
          ...state,
          ...data,
        }),
    },
  ),
  withHandlers({
    onSubmit:
      ({ onChange, fileList, multiple }) =>
      uploaderProps =>
      () => {
        const { closeModal } = uploaderProps || {};
        //
        if (typeof onChange === 'function') {
          onChange(multiple ? fileList : fileList[0]);
        }
        if (typeof closeModal === 'function') {
          closeModal();
        }
      },
  }),
  withHandlers({
    renderSubmitButton:
      ({ t, onSubmit, fileList, loading }) =>
      uploaderProps =>
        (
          <SubmitButton
            style={{ display: 'block' }}
            type="primary"
            loading={loading}
            disabled={!Array.isArray(fileList) || fileList.length === 0}
            onClick={onSubmit(uploaderProps)}>
            {t('Upload selected file(s)')}
          </SubmitButton>
        ),
    beforeUpload:
      ({ updateState }) =>
      fileList => {
        updateState({
          fileList,
        });
        logger('GameTaskFileUploader', {
          fileList,
        });
        return false;
      },
    onRemove:
      ({ updateState }) =>
      fileList => {
        updateState({
          fileList,
        });
      },
  }),
  pure,
)(ExtendedFileUploader);
