import React, { memo, useCallback, useState, useEffect, useMemo, MouseEventHandler, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import logger from 'src/helpers/logger';
import { SubscribersQueryVariables, useSubscribersQuery } from 'src/graphql/generated';
import Select, { TagRenderProps } from 'src/components/ui-kit/select';
import Tag from 'src/components/ui-kit/tag';
import { elementColors } from 'src/components/ui-kit/map';
import { getColorIndex, sortOptions } from 'src/components/routes/private/locations/map/helpers';
import { DefaultOptionType, FilterFunc } from 'rc-select/lib/Select';

export type SubscriberSelectorProps = {
  value?: string[];
  className?: string;
  style?: { [k: string]: string | number };
  disabled?: boolean;
  queryVariables?: SubscribersQueryVariables;
  onChange?: (_v: string[]) => void;
  maxTagCount?: number | 'responsive';
  maxTagPlaceholder?: string;
};

const SubscriberSelector: React.FC<SubscriberSelectorProps> = props => {
  const { t } = useTranslation();
  const {
    // Get value provided by parent component (if any)
    value,
    // Handle default react input props to be able to disable selectbox and/or set custom styles
    style,
    className,
    disabled,
    // Allow to pass custom query-vars for filtering or pagination of the data returned from API
    queryVariables,
    maxTagCount = 'responsive',
    maxTagPlaceholder = t('All'),
  } = props;
  const [selectedSubscribers, setSubscriberIds] = useState(value ?? []);
  //
  const { data: queryResult, loading } = useSubscribersQuery({
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
  });
  const subscribers = queryResult?.subscribers?.rows || [];
  //
  useEffect(() => {
    if (loading) {
      return;
    }
    if (!selectedSubscribers?.length) {
      return;
    }
    const selectedAndPresentedInQueryResult = subscribers
      .filter(s => selectedSubscribers.includes(s.id))
      .map(s => s.id);
    if (selectedAndPresentedInQueryResult.length) {
      onChange(selectedAndPresentedInQueryResult);
    } else {
      onChange([]);
    }
  }, [subscribers, loading]);

  useEffect(() => {
    sessionStorage.setItem('callingSsiId', selectedSubscribers.join(','));
  }, [selectedSubscribers]);

  // if 'value' provided by parent component has been changed
  // we should update value stored inside inner state of the SubscriberSelector
  useEffect(() => {
    if (value !== selectedSubscribers) {
      setSubscriberIds(value ?? []);
    }
  }, [value]);

  // Handle 'onChange' event and bypass it to 'onChange' handler from props (if any)
  // if not parent 'onChange' handler provided, just save new value to the inner state of the component
  const onChange = useCallback(
    (v: string[]) => {
      logger('SubscriberSelector.onChange', v);
      //
      if (typeof props.onChange === 'function') {
        props.onChange(v);
      } else {
        setSubscriberIds(v);
      }
    },
    [selectedSubscribers, props.onChange, subscribers],
  );
  useEffect(() => {
    if (!selectedSubscribers?.length) {
      onChange(sessionStorage.getItem('callingSsiId')?.split(',') ?? []);
    }
  }, []);
  const onPreventMouseDown = useCallback((event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);
  const handleSubscribersFilter = useCallback<FilterFunc<DefaultOptionType>>((searchString: string, option) => {
    const search = searchString.toLowerCase();
    const opts = [`${option?.label}`.toLowerCase(), `${option?.value}`.toLowerCase()];

    return opts.some(opt => opt.includes(search));
  }, []);
  const subscriberTagRender = useCallback(
    (props: TagRenderProps) => {
      const { label, value, ...tagProps } = props;
      const colorIndex = selectedSubscribers.indexOf(value) ?? selectedSubscribers.length;
      const color = elementColors[getColorIndex(colorIndex) as number];

      return (
        <Tag color={color} onMouseDown={onPreventMouseDown} {...tagProps}>
          {label}
        </Tag>
      );
    },
    [onPreventMouseDown, selectedSubscribers],
  );
  const options = useMemo(
    () => subscribers.map(({ id, alias }) => ({ label: alias || id, value: id })).sort(sortOptions) ?? [],
    [subscribers],
  );

  return (
    <Select
      mode="multiple"
      style={style}
      className={className}
      placeholder={t('Enter ID')}
      value={selectedSubscribers}
      onChange={onChange}
      disabled={disabled ?? !subscribers.length}
      tagRender={subscriberTagRender}
      maxTagCount={maxTagCount}
      maxTagPlaceholder={maxTagPlaceholder}
      options={options}
      filterOption={handleSubscribersFilter}
      allowClear
      showSearch
      showArrow
    />
  );
};

SubscriberSelector.displayName = 'SubscriberSelector';

export default memo(SubscriberSelector);
