/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, memo, useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import compact from 'lodash/compact';
import type { SubscribeToMoreProps } from '@fjedi/graphql-react-components';
import { useSubscribeToMore } from '@fjedi/graphql-react-components';
import { ViewerContext } from '@fjedi/react-router-helpers';
import { Alias, LocationsQueryVariables, useAliasesQuery, useLocationsQuery, Viewer } from 'src/graphql/generated';
import locationCreatedSubscription from 'src/graphql/subscriptions/location-created.graphql';
import { TimeInstance } from 'src/helpers/time';
import { colorGrays, colorTheme } from 'src/components/ui-kit/theme';
import type { MapData } from 'src/components/ui-kit/leaflet-map';
import Map, { elementColors } from 'src/components/ui-kit/leaflet-map';

import { MapFilters } from './map.d';
import { compareFilters, compareQueryVariables, convertTimeRangeIntoCreatedAtFilter } from './helpers';
import type { Filters } from './filters-form';
import FiltersForm from './filters-form';
import { TrackingMethod } from './constants';

const Container = styled.div`
  display: flex;

  & .map-container {
    flex-grow: 1;
    min-height: 100%;

    border-radius: 0.625rem;
    outline: 1px solid ${colorGrays.gray500};
    min-width: calc(100% - 1rem);
    margin-right: 1rem;
    height: 100%;

    & > .map {
      min-width: 100%;
    }
  }

  & > .filters-form {
    margin-left: auto;

    .ant-form-item {
      width: 100%;
    }
  }
`;

const MapTab: FC = () => {
  const viewer = useContext(ViewerContext) as Viewer;
  const [queryVariables, setQueryVariables] = useState<LocationsQueryVariables>();
  const [skip, setSkip] = useState(true);
  const [filters, setFilters] = useState<MapFilters>({
    rssi: false,
    roadsBinding: false,
    positioning: false,
    trackingMethod: TrackingMethod.Route,
    locationUncertainty: undefined,
  });

  const { data: aliasesRes, loading: aliasesLoading } = useAliasesQuery({
    variables: {},
    skip,
  });
  const aliases: Alias[] = useMemo(
    () =>
      compact(aliasesRes?.aliases?.rows).map(({ subscriberId, subscriberAlias }) => ({
        subscriberId,
        subscriberAlias,
      })) ?? [],
    [aliasesRes?.aliases?.rows],
  );

  const skipQuery = !queryVariables || skip;
  const {
    data: locationsRes,
    loading: locationsLoading,
    subscribeToMore,
  } = useLocationsQuery({
    variables: queryVariables! /* as QueryVariables & { filter: { trackingMethod: TrackingMethod } } */,
    skip: skipQuery,
  });

  // logger('locationsRes', locationsRes);

  const isLoading = useMemo(() => aliasesLoading || locationsLoading, [aliasesLoading, locationsLoading]);

  const subscriptionProps = useMemo(
    () =>
      skipQuery ||
      !queryVariables?.filter.trackingMethod ||
      queryVariables?.filter.trackingMethod === TrackingMethod.Route
        ? // To skip ws-subscription we just need to pass empty options to "useSubscribeToMore" hook
          ({} as SubscribeToMoreProps)
        : ({
            subscriptionId: 'LIVE_LOCATION',
            subscriptionQueries: [locationCreatedSubscription],
            variables: queryVariables ?? {},
            dataType: 'Location',
            subscribeToMore,
          } as SubscribeToMoreProps),
    [skipQuery, queryVariables, subscribeToMore],
  );
  useSubscribeToMore(subscriptionProps /* as unknown as SubscribeToMoreProps */);

  const subscriberColors = useMemo(
    () =>
      Object.fromEntries(
        queryVariables?.filter.callingSsiId?.map((id, index) => [
          id,
          elementColors[(index - elementColors.length * Math.floor(index / elementColors.length)) as number] ??
            colorTheme.primary,
        ]) ?? [],
      ),
    [queryVariables?.filter.callingSsiId],
  );

  const locations: MapData[] = useMemo(
    () =>
      compact(locationsRes?.locations).map(target => {
        const { location, dBm, weight, signalStrength, id, callingSsiId, timestamp } = target;
        const name = aliases.find(({ subscriberId }) => subscriberId === callingSsiId)?.subscriberAlias ?? callingSsiId;
        const color = subscriberColors[callingSsiId as keyof typeof subscriberColors];

        return { id, groupId: callingSsiId, name, location, color, dBm, weight, signalStrength, timestamp };
      }),
    [aliases, locationsRes, subscriberColors],
  );

  // logger('locationsRes --> locations', locations);

  const applyFilters: (_values: Filters) => void = useCallback(
    values => {
      const { callingSsiId, timeRange, remoteCompanyId, ...nextFilters } = values as Required<Filters>;
      const nextQueryVariables: LocationsQueryVariables = {
        filter: {
          remoteCompanyId: remoteCompanyId?.length ? remoteCompanyId : [viewer.primaryCompany!.remoteId],
          callingSsiId,
          rssi: nextFilters.rssi,
          roadsBinding: nextFilters.roadsBinding,
          trackingMethod: nextFilters.trackingMethod as LocationsQueryVariables['filter']['trackingMethod'],
          locationUncertainty: nextFilters.locationUncertainty,
        },
      };

      // implement trackingMethod & time range filters
      let startTime;
      let endTime;

      switch (nextFilters.trackingMethod) {
        case TrackingMethod.LivePoint:
          break;
        case TrackingMethod.LiveRoute:
          startTime = timeRange as TimeInstance;
          break;
        case TrackingMethod.Route:
        default:
          [startTime, endTime] = timeRange as [TimeInstance, TimeInstance];
      }

      const createdAt = convertTimeRangeIntoCreatedAtFilter(startTime, endTime);

      nextQueryVariables.filter = { ...nextQueryVariables.filter, createdAt };

      // skip next query, if no filters had changed
      let isQueryVariablesDirty = !queryVariables;
      let isFiltersDirty = !filters;

      if (queryVariables) {
        isQueryVariablesDirty = compareQueryVariables(queryVariables, nextQueryVariables);
      }
      if (filters) {
        isFiltersDirty = compareFilters(filters, nextFilters);
      }

      // logger('Compare filters and query variables with their previous values', {
      //   isQueryVariablesDirty,
      //   isFiltersDirty,
      // });

      // update query variables
      if (isQueryVariablesDirty) {
        setQueryVariables(nextQueryVariables);
      }
      // update filters affecting map behavior
      if (isFiltersDirty) {
        setFilters(nextFilters);
      }

      setSkip(!isQueryVariablesDirty && !isFiltersDirty && !!locationsRes && !!locationsRes.locations.length);
    },
    [filters, locationsRes, queryVariables, viewer?.primaryCompany],
  );

  return (
    <Container>
      <Map
        data={locations}
        loading={isLoading}
        trackingMethod={filters.trackingMethod}
        bindToRoads={filters.roadsBinding}
        showRssi={filters.rssi}
        positioning={filters.positioning}
      />
      <FiltersForm onSubmit={applyFilters} isLoading={locationsLoading} />
    </Container>
  );
};

export default memo(MapTab);
