import { fallbackLang, supportedLangs, withSSR } from 'kit/lib/intl';
import compact from 'lodash/compact';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
//
import {
  AuthSession,
  useAuthSessionsQuery,
  useLogOutMutation,
  useViewerQuery,
  ViewerQueryHookResult,
} from 'src/graphql/generated';
//
import GlobalStyles from 'src/components/ui-kit/global-styles';
import { theme } from 'config/theme';
import { ThemeProvider } from 'styled-components';
// Routing via React Router
import {
  AuthModalProvider,
  ViewerContext,
  ViewerRole,
  ViewerRoleProvider,
  ViewerType,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from '@fjedi/react-router-helpers';
import Spinner from 'src/components/ui-kit/spinner';
//
import { ConfigProvider } from 'antd';
import ru from 'antd/lib/locale/ru_RU';
import en from 'antd/lib/locale/en_US';
// Routes
import ForgotPassword from 'src/components/routes/auth/forgot-password';
import Login from 'src/components/routes/auth/login';
import ResetPassword from 'src/components/routes/auth/reset-password';
import PrivateRoot from 'src/components/routes/private';
//
import { useApolloError } from '@fjedi/graphql-react-components';
import { useTimeout } from 'kit/lib/hooks';
import { time } from 'src/helpers/time';
//
const locales = { ru, en };
const Root = () => {
  const { i18n } = useTranslation();
  const [lang, setLang] = useState(i18n?.language || fallbackLang);
  //
  const onError = useApolloError();
  const navigate = useNavigate();
  //
  const {
    data: viewerRes,
    loading,
    updateQuery: updateViewerQuery,
  } = useViewerQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
    onError,
  });
  //
  const [logout, { loading: logoutLoading }] = useLogOutMutation({
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    onError,
  });
  //
  const viewer = viewerRes?.viewer;
  const viewerId = viewerRes?.viewer?.id;
  //
  const { data: sessionsRes, loading: isSessionsLoading } = useAuthSessionsQuery({
    variables: {
      userId: viewerId!,
    },
    skip: !viewerId,
    onError,
  });
  //
  const session = useMemo(() => {
    const sessions: AuthSession[] = compact(sessionsRes?.authSessions?.rows) ?? ([] as AuthSession[]);
    return sessions[0];
  }, [sessionsRes?.authSessions?.rows]);
  //
  const isLoading = useMemo(
    () => loading || isSessionsLoading || logoutLoading,
    [loading, isSessionsLoading, logoutLoading],
  );
  //
  const [logoutDelay, setLogoutDelay] = useState<number | null>(1000 * 10 * 60);
  const resetDelay = useCallback(
    () => setLogoutDelay(prevDelay => (prevDelay ? prevDelay + (prevDelay % 2 ? 1 : -1) : 1000 * 10 * 60)),
    [],
  );
  //
  const goHome = useCallback(() => {
    updateViewerQuery(
      prev =>
        ({
          ...prev,
          viewer: null,
        } as ViewerQueryHookResult['data'] & { viewer: ViewerQueryHookResult['data'] | null }),
    );
    setLogoutDelay(null);
    console.groupEnd();
    navigate('/');
  }, [navigate, updateViewerQuery]);
  //
  useTimeout(() => {
    console.group('logout timer');
    console.count('iteration');
    console.info({ logoutDelay });

    if (isLoading) {
      console.info({ isLoading });
      resetDelay();
      console.groupEnd();
      return;
    }

    if (!viewer || !viewerId || !session || !('expiredAt' in session)) {
      console.info({ viewer, viewerId, session });
      goHome();
      return;
    }

    const now = time();
    const expireTime = time(session.expiredAt);
    if (expireTime.isSameOrBefore(now)) {
      console.info({ expireTime, now });
      logout().finally(() => goHome());
      return;
    }

    console.info('dismissed');
    console.groupEnd();
    resetDelay();
  }, logoutDelay);
  // Locale management
  useEffect(() => {
    i18n.changeLanguage(supportedLangs.ru).catch(console.error);

    i18n.on('languageChanged', l => {
      if (l.length > 2) {
        //
        i18n.changeLanguage(l.substring(0, 2)).catch(console.error);
      } else {
        setLang(l);
      }
    });
    return () => {
      i18n.off('languageChanged');
    };
  }, [i18n]);

  const ForgotPasswordPage = useMemo(() => (viewerId ? <Navigate replace to="/" /> : <ForgotPassword />), [viewerId]);
  const ResetPasswordPage = useMemo(() => (viewerId ? <Navigate replace to="/" /> : <ResetPassword />), [viewerId]);
  //
  if (isLoading) {
    return (
      <Spinner
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        spinning
      />
    );
  }
  return (
    <ConfigProvider theme={theme} locale={locales[lang as keyof typeof locales]}>
      <ThemeProvider theme={theme}>
        <ViewerContext.Provider value={viewer as ViewerType}>
          <ViewerRoleProvider value={viewer?.role as ViewerRole}>
            <AuthModalProvider>
              {/* <StrictMode> */}
              <Routes>
                <Route path="forgot-password" element={ForgotPasswordPage} />
                <Route path="reset-password/:token" element={ResetPasswordPage} />
                {/* {!!viewerId && <Redirect exact strict from="login" to="/" />} */}
                <Route path="*" element={viewerId ? <PrivateRoot /> : <Login />} />
              </Routes>
              <GlobalStyles />
              {/* </StrictMode> */}
            </AuthModalProvider>
          </ViewerRoleProvider>
        </ViewerContext.Provider>
      </ThemeProvider>
    </ConfigProvider>
  );
};

export default withSSR()(Root);
