import React from 'react';
import PropTypes from 'prop-types';
import { pure, compose, setDisplayName, withProps } from 'recompose';
import { withTranslation } from 'react-i18next';
//
import styled from 'styled-components';
import { Button } from 'antd';
import Tooltip from 'src/components/ui-kit/tooltip';
import { DeleteOutlined } from 'src/components/ui-kit/icons';

export const StyledButton = styled(Button)`
  &.ant-btn.ant-btn-circle {
    padding: 0 8px;
  }
`;

const DeleteButton = ({ onClick, tooltipProps, t, tReady, ...props }) => {
  if (!tooltipProps) {
    return (
      <StyledButton onClick={onClick} shape="circle" type="dashed" {...props}>
        <DeleteOutlined />
      </StyledButton>
    );
  }
  return (
    <Tooltip {...tooltipProps}>
      <StyledButton onClick={onClick} shape="circle" type="dashed" {...props}>
        <DeleteOutlined />
      </StyledButton>
    </Tooltip>
  );
};

DeleteButton.propTypes = {
  tooltipProps: PropTypes.shape({
    placement: PropTypes.string,
  }),
  onClick: PropTypes.func.isRequired,
};
DeleteButton.defaultProps = {
  tooltipProps: null,
};

export default compose(
  setDisplayName('DeleteButton'),
  withTranslation(),
  withProps(({ t, tooltipProps }) => ({
    tooltipProps: {
      title: t('Remove'),
      placement: 'top',
      ...(tooltipProps || {}),
    },
  })),
  pure,
)(DeleteButton);
